import React, { useEffect, useRef } from 'react';

import { createNode } from 'utils/createNode';

import { PUB_SCOPE } from 'config/constants/ad';

import { useVideoParams } from './hooks/useVideoParams';

interface PropsType {
  embedCode: string;
  puids: PuidsType;
  playerId: string;
  isBroadcast: boolean;
  ruid: string;
}

interface VideoPlayerParamType {
  recordId: number;
  adTemplateId: number | undefined;
  multiplayerFilterId: number | undefined;
  playerTemplateId: number | undefined;
  sspJparams: PuidsType | undefined;
}

/**
 * Компонент RamblerPlayer для видео от EaglePlatform
 * https://vp.rambler.ru/docs/player/index.html
 *
 * @param embedCode - html-код видео плеера
 * @param puids - рекламные пуиды
 * @param playerId - id node-узла, в который вставляется плеер
 * @param isBroadcast - флаг, что это видеотрансляция
 */
export function VideoPlayer({
  embedCode,
  puids,
  playerId,
  ruid,
  isBroadcast = false,
}: PropsType) {
  const {
    recordId,
    adTemplateId,
    multiplayerFilterId,
    playerTemplateId,
    sspJparams,
  } = useVideoParams(embedCode, puids, isBroadcast) as VideoPlayerParamType;

  const player = useRef(null);

  useEffect(() => {
    if (recordId) {
      window.RamblerPlayer = window.RamblerPlayer || [];
      window.RamblerPlayer.push(() => {
        // @ts-expect-error: Cannot find name 'RamblerPlayer'
        player.current = new RamblerPlayer({
          parentId: `#${playerId}`,
          id: recordId,
          adTemplateId,
          multiplayerFilterId,
          playerTemplateId,
          sspJparams,
          live: isBroadcast,
          docking: 'left-down',
          sspUserId: ruid,
          sspUserIdScope: PUB_SCOPE,
        });
      });
    }

    createNode({
      type: 'script',
      src: 'https://vp.rambler.ru/player/sdk.js',
    });
  }, [adTemplateId, recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!recordId) return null;

  return <div id={playerId} />;
}
