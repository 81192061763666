import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';

import { getChineseSign } from 'common/redux/chinese';

import { useDateByDeviceContext } from '../contexts/dateByDeviceContext';

const selectData = createSelector(
  [(state: IAppState) => state.chinese],
  (chinese) => ({
    chinese,
  }),
);

export const useChinese = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { chinese } = useSelector(selectData);
  const { isButtonClicked, setIsButtonClicked } = useDateByDeviceContext();

  const isFormActive = useRef(false);

  // Снимаем блокировку изменения данных
  useEffect(() => {
    if (isButtonClicked) {
      setIsButtonClicked(false);
    }

    if (chinese && chinese.sign && isFormActive.current) {
      isFormActive.current = false;
      history.push(`/chinese/description/${chinese.sign}/`);
    }
  }, [chinese]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    checkChineseDateAction: (form: ICardProps['form'], date: string) => {
      if (date && form === 'chinese_sign_for_date') {
        isFormActive.current = true;
        dispatch(getChineseSign(date));
      }
    },
  };
};
