import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardSwitchBubblesProps {
  bubbles: ICardProps['switch_bubbles'];
  className?: string;
}

function SwitchBubbles({ bubbles, className = '' }: ICardSwitchBubblesProps) {
  if (!bubbles) return null;

  return (
    <div className={cn(s.root, className)}>
      {bubbles.map((item, index) => {
        const { link, name } = item;
        const key = `${link}-${index}`;

        return (
          <Typography
            variant="defaultMedium"
            className={cn(s.defaultBubble, s.link, item.sign && s[item.sign])}
            key={key}
            element={<Link to={item.link} />}
          >
            {name}
          </Typography>
        );
      })}
    </div>
  );
}

SwitchBubbles.defaultProps = {
  className: '',
};

export { SwitchBubbles };
