import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import _debounce from 'lodash.debounce';

import { Link } from 'react-router-dom';

import Tooltip from '@rambler-components/tooltip';

import { Typography } from 'common/components/Typography';
import { Icon } from 'common/components/Icon';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { YandexEvent } from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';

import s from './styles.css';

const DEBOUNCE_WAITING_TIME = 300;

interface IBubbleItem {
  bubble: IBubble;
  currentSign?: IBubble;
  selectSign?: IBubble['sign'];
  showForceTooltip: boolean;
  isChinese?: boolean;
  isAccountSign: boolean;
  signChecked: boolean;
  onCancel: (name: IBubble['name']) => void;
  onSave: (sign: IBubble['sign'], name: IBubble['name']) => void;
}

export const BubbleItem: React.FC<IBubbleItem> = React.memo(
  ({
    bubble,
    currentSign,
    selectSign,
    showForceTooltip,
    isChinese,
    isAccountSign,
    signChecked,
    onCancel,
    onSave,
  }) => {
    const currentPath = useSelector((state: IAppState) => state.runtime.path);
    const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

    const { top100Prefix } = useTop100Context();

    const { link, name, sign } = bubble;

    /** Страница знака */
    const isSignPage = currentSign?.sign;
    /** Бабл всех наков */
    const isAllSignsBubble: boolean = sign === 'all';
    /** Бабл активен */
    const isActive = currentSign?.sign === sign;
    /** Знак сохранен пользователем */
    const isSelected = sign === selectSign;
    /** Знак доступен для выбора */
    const isAvailable =
      link === currentPath && !selectSign && !isAllSignsBubble;
    /** С тултипами - подсказками */
    const withHints =
      isChinese ||
      showForceTooltip ||
      isAllSignsBubble ||
      !isSignPage ||
      isAccountSign
        ? false
        : isSelected || isAvailable;
    /** Показывать кнопки - добавить */
    const withAddButton =
      !isAllSignsBubble &&
      !isChinese &&
      signChecked &&
      currentSign &&
      isAvailable &&
      !isSelected;
    /** Показывать кнопку - отменить выбор */
    const withCancelButton =
      !isAllSignsBubble && !isChinese && signChecked && isSelected;

    /** Страницы на которых не происходит перехода по ссылке бабла,
     * а скроллится до якоря https://jira.rambler-co.ru/browse/HORO-5916
     */
    const isScrollToAnchorPage = [
      '/znaki-zodiaka/kresty/',
      '/znaki-zodiaka/in-i-yan/',
    ].includes(currentPath);

    const bubbleTop100 = `bubble_menu::${name}::${isActive ? 'current' : 'not_current'}`;

    const tooltipText = isSelected ? 'Отменить выбор' : 'Сделать знак основным';

    const handleClickButton = _debounce(() => {
      if (!isAccountSign && !isSelected) {
        onSave(sign, name);
        new YandexEvent(YANDEX_METRICS.COMMON).send({
          type: 'reachGoal',
          data: 'bubble_menu_zodiak_main_add',
        });

        return;
      }

      onCancel(name);
      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: 'bubble_menu_zodiak_main_cancel',
      });
    }, DEBOUNCE_WAITING_TIME);

    const handleClickBubble = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isScrollToAnchorPage) {
        e.preventDefault();

        const anchor = link.split('/#')[1];

        if (anchor) {
          // https://jira.rambler-co.ru/browse/HORO-5916
          // для скролла размечаются только заголовки h3
          const element = document.querySelector(`h3#${anchor}`);

          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }
        }
      }

      new YandexEvent(YANDEX_METRICS.COMMON).send({
        type: 'reachGoal',
        data: 'bubble_menu_clicks',
      });
    };

    if (!sign) {
      return null;
    }

    const bubbleLink = (
      <div
        className={cn(
          s.root,
          s[sign],
          isMobile && s.mobile,
          isActive && s.active,
          isSelected && s.selected,
          (withAddButton || withCancelButton) && s.withButtons,
        )}
      >
        <div className={s.linkWrapper}>
          <Icon
            id={isChinese ? `${sign}` : `bubble-${sign}`}
            className={s.signIcon}
          />
          <Typography
            className={s.link}
            variant="defaultMedium"
            element={
              <Link
                to={link}
                onClick={handleClickBubble}
                {...getTop100Markup(isMobile, top100Prefix, bubbleTop100)}
              />
            }
          >
            {name}
          </Typography>
        </div>
        {withAddButton && (
          <button
            aria-label="Выбрать этот знак"
            type="button"
            className={s.actionButton}
            onClick={handleClickButton}
          >
            <Icon id="plus-15" className={s.actionIcon} />
          </button>
        )}
        {withCancelButton && (
          <button
            aria-label="Отменить выбор"
            type="button"
            className={s.actionButton}
            onClick={handleClickButton}
          >
            <Icon id="close-15" className={s.actionIcon} />
          </button>
        )}
        <span className={s.forceTooltipAnchor} />
        {showForceTooltip && (
          <Tooltip
            label="Чтобы сохранить свой знак позже, нажмите на нём иконку + "
            isAlwaysCentered
            forceVisible={showForceTooltip}
            position={isMobile ? 'top' : 'right'}
          >
            <span className={s.forceTooltipAnchor} />
          </Tooltip>
        )}
      </div>
    );

    return withHints ? (
      <Tooltip
        label={tooltipText}
        isAlwaysCentered
        position={isMobile ? 'top' : 'right'}
      >
        {bubbleLink}
      </Tooltip>
    ) : (
      bubbleLink
    );
  },
);

BubbleItem.displayName = 'BubbleItem';
