/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Calendar from '@rambler-components/calendar';

import { differenceInCalendarDays, isSameDay, addDays, format } from 'date-fns';

import { fetchDashboardDateData } from 'common/redux/pages';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { useTop100Context } from 'common/contexts/top100Context';

import { APP_VERSION } from 'config/constants';
import { updateCounters } from 'utils/counters/updater';

import { getTop100 } from '../../utils';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.account.birthday,
    (state: IAppState) => state.runtime.currentParams,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (birthday, currentParams, isMobile) => ({
    birthday,
    currentParams,
    isMobile,
  }),
);

function DateSwitchForm() {
  const { top100Prefix } = useTop100Context();
  const dispatch = useDispatch();
  const { birthday, currentParams, isMobile } = useSelector(selectData);

  const birthdayDate = birthday ? new Date(birthday) : new Date();

  const initialDateOfBirth = useRef(
    currentParams.personalDateOfBirth
      ? new Date(currentParams.personalDateOfBirth)
      : birthdayDate,
  );
  const isBirthDateChanged = useRef(false);
  const isDateChanged = useRef(false);

  const [birthCalendarOpened, setBirthCalendarOpened] = useState(false);
  const [predictionCalendarOpened, setPredictionCalendarOpened] =
    useState(false);
  const [dateOfBirth, setDateOfBirth] = useState<Date>(
    initialDateOfBirth.current,
  );
  const [dateOfPrediction, setDateOfPrediction] = useState<Date>(
    currentParams.personalDateOfPrediction
      ? new Date(currentParams.personalDateOfPrediction)
      : new Date(),
  );

  const birthRef = useRef<HTMLDivElement>(null);
  const predictionRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(
    birthRef,
    () => setBirthCalendarOpened(false),
    birthCalendarOpened,
  );
  useOnClickOutside(
    predictionRef,
    () => setPredictionCalendarOpened(false),
    predictionCalendarOpened,
  );

  useEffect(() => {
    if (isDateChanged.current && dateOfPrediction) {
      setBirthCalendarOpened(false);
      setPredictionCalendarOpened(false);

      const perdictionString = format(dateOfPrediction, 'yyyy-MM-dd');
      const birthString = format(dateOfBirth, 'yyyy-MM-dd');
      const url = `/personal/${perdictionString}/${birthString}/`;
      const fetchUrl = `/${perdictionString}/birthdate/${birthString}/`;

      dispatch(
        fetchDashboardDateData({
          url: fetchUrl,
          personalDateOfPrediction: perdictionString,
          personalDateOfBirth: birthString,
        }),
      );

      if (isMobile) {
        updateCounters(APP_VERSION.MOBILE);
      } else {
        updateCounters(APP_VERSION.DESKTOP);
      }

      window.history.pushState({ url }, 'title', url);

      isDateChanged.current = false;
    }
  }, [dateOfBirth, dateOfPrediction]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentParams.personalDateOfPrediction) {
    return null;
  }

  const dateOfPredictionFromUrl = new Date(
    currentParams.personalDateOfPrediction,
  );

  const minDate = new Date('1910-01-01');
  const maxBirthDate = new Date(`${new Date().getFullYear() - 5}-01-01`);

  const predictionBeforeBirth =
    differenceInCalendarDays(dateOfPredictionFromUrl, dateOfBirth) < 0;

  return (
    <>
      <div
        className={cn(s.date, isMobile ? s.dateMobile : s.dateDesktop)}
        ref={birthRef}
      >
        <Typography variant="defaultMedium" className={s.title}>
          Дата рождения
        </Typography>
        <div
          className={cn(s.info, isMobile ? s.infoMobile : s.infoDesktop)}
          onClick={() => setBirthCalendarOpened(true)}
          {...getTop100({
            isMobile,
            top100Prefix,
            form: 'date_switch_form',
            tail: `${isBirthDateChanged.current ? 'dashboard_person_forecast' : 'dashboard_personal_forecast'}::birthday_change`,
          })}
        >
          <Typography variant="defaultMedium" className={s.val}>
            {format(dateOfBirth, 'dd.MM.yyyy')}
          </Typography>
          <Icon id="calendar" className={s.icon} />
          {birthCalendarOpened && (
            <Calendar
              className={s.calendar}
              initDate={dateOfBirth}
              minDate={minDate}
              maxDate={maxBirthDate}
              value={dateOfBirth}
              showYearSwitch
              isSelectable
              onChange={(val: Date) => {
                isDateChanged.current = true;
                isBirthDateChanged.current = isSameDay(
                  val,
                  initialDateOfBirth.current,
                );

                setDateOfBirth(val);
              }}
            />
          )}
        </div>
      </div>
      <div
        className={cn(s.date, isMobile ? s.dateMobile : s.dateDesktop)}
        ref={predictionRef}
      >
        <Typography variant="defaultMedium" className={s.title}>
          Дата прогноза
        </Typography>
        <div
          className={cn(s.info, isMobile ? s.infoMobile : s.infoDesktop)}
          onClick={() => setPredictionCalendarOpened(true)}
          {...getTop100({
            isMobile,
            top100Prefix,
            form: 'date_switch_form',
            tail: `${isBirthDateChanged.current ? 'dashboard_person_forecast' : 'dashboard_personal_forecast'}::date_change`,
          })}
        >
          <Typography
            variant="defaultMedium"
            className={cn(s.val, predictionBeforeBirth && s.valError)}
          >
            {format(dateOfPredictionFromUrl, 'dd.MM.yyyy')}
          </Typography>
          <Icon id="calendar" className={s.icon} />
          {predictionCalendarOpened && (
            <Calendar
              className={s.calendar}
              initDate={dateOfPredictionFromUrl}
              minDate={dateOfBirth}
              maxDate={addDays(new Date(), 21)}
              value={dateOfPredictionFromUrl}
              showYearSwitch
              isSelectable
              onChange={(val: Date) => {
                isDateChanged.current = true;
                setDateOfPrediction(val);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export { DateSwitchForm };
