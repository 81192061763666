import React, { useMemo } from 'react';
import cn from 'classnames';

import Button from '@rambler-components/button';

import type { IProps as IButtonProps } from 'packages/button/typings/index';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './index.css';

interface IAlertBaseProps {
  isMobile: boolean;
  mainText: string;
  noSubmit?: boolean;
  submitText?: string;
  submitProps?: IButtonProps;
  abortProps?: IButtonProps;
  onSubmit?: () => void;
  onAbort?: () => void;
  className?: string;
  contentClassName?: string;
  top100ExtPrefix?: string;
}

function AlertBase({
  isMobile,
  mainText,
  noSubmit,
  submitText,
  submitProps,
  abortProps,
  onSubmit,
  onAbort,
  className,
  contentClassName = '',
  top100ExtPrefix,
}: IAlertBaseProps) {
  const { top100Prefix } = useTop100Context();

  const top100ExtPrefixForReadyPrefix = top100ExtPrefix
    ? `::${top100ExtPrefix}`
    : '';

  const readyPrefix = useMemo(
    () => `${top100Prefix}${top100ExtPrefixForReadyPrefix}`,
    [top100ExtPrefixForReadyPrefix, top100Prefix],
  );

  return (
    <div className={cn(s.root, className)}>
      <div className={cn(s.content, contentClassName)}>
        <Typography
          variant="defaultBold"
          className={s.text}
          element={<div dangerouslySetInnerHTML={{ __html: mainText }} />}
        />
        <div className={s.buttons}>
          {!noSubmit && (
            <Button
              className={s.submitButton}
              onClick={onSubmit}
              {...getTop100Markup(isMobile, readyPrefix, 'confirm')}
              {...submitProps}
            >
              {submitText}
            </Button>
          )}

          <Button
            className={cn(s.roundButton, s.abortButton)}
            onClick={onAbort}
            {...getTop100Markup(isMobile, readyPrefix, 'exit')}
            {...abortProps}
          >
            <Icon id="close" className={s.closeIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
}

AlertBase.defaultProps = {
  noSubmit: false,
  submitText: '',
  submitProps: {},
  abortProps: {},
  onSubmit: () => {},
  onAbort: () => {},
  className: '',
  contentClassName: '',
  top100ExtPrefix: undefined,
};

export { AlertBase };
