import React from 'react';

import { Typography } from 'common/components/Typography';

import { htmlParser } from 'common/utils/textParser';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IParagraphProps {
  content: string;
  isMobile: boolean;
}

function Paragraph({ content, isMobile }: IParagraphProps) {
  const { top100Prefix } = useTop100Context();
  if (!content) return null;

  return (
    <Typography variant="defaultRegular" className={s.paragraph} component="p">
      {htmlParser(content, isMobile, top100Prefix)}
    </Typography>
  );
}

export { Paragraph };
