import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import _groupBy from 'lodash.groupby';
import _intersection from 'lodash.intersection';

import { Link } from 'react-router-dom';

import { YANDEX_METRICS } from 'config/constants/counters';
import {
  BUBBLES_GROUPS,
  BUBBLES_TITLES_BY_TYPE,
} from 'config/constants/bubbles';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { YandexEvent } from 'utils/counters/YandexEvent';

import BubblesCalendar from './Calendar';
import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.currentParams.url,
    (state: IAppState) => state.runtime.currentParams.specialKey,
    (state: IAppState) => state.runtime.currentParams.specialCurrent,
    (state: IAppState) => state.runtime.path,
  ],
  (
    isMobile,
    currentParamsUrl,
    currentParamsSpecialKey,
    currentParamsSpecialCurrent,
    path,
    // eslint-disable-next-line max-params
  ) => ({
    isMobile,
    currentParamsUrl,
    currentParamsSpecialKey,
    currentParamsSpecialCurrent,
    path,
  }),
);

interface ICardBubblesProps {
  bubbles?: IBubbles;
  isFirstSeparated?: boolean;
  isMenu?: boolean;
  isCardBubblesMoved: boolean;
  className?: string;
  top100Prefix?: string;
}

const Bubbles: React.FC<ICardBubblesProps> = React.memo(
  ({
    bubbles,
    isFirstSeparated,
    isMenu,
    isCardBubblesMoved,
    className,
    top100Prefix: bubblesTop100Prefix,
  }) => {
    const { top100Prefix } = useTop100Context();
    const {
      isMobile,
      currentParamsUrl,
      currentParamsSpecialKey,
      currentParamsSpecialCurrent,
      path,
    } = useSelector(selectRuntimeData);
    const [expanded, setExpanded] = useState(false);
    const isIPhone = useRef(false);

    useEffect(() => {
      // eslint-disable-next-line sonarjs/slow-regex
      isIPhone.current = /iPhone.+Version\/[\d.]+.*Safari/i.test(
        window.navigator.userAgent,
      );
    }, []);

    const list = bubbles ? bubbles.list : [];
    const shortcut = bubbles ? bubbles.shortcut : undefined;
    const title = bubbles ? bubbles.title : '';
    const calendar = bubbles ? bubbles.calendar : undefined;

    const renderToggle = useMemo(
      () => (
        <Typography
          variant="defaultMedium"
          className={cn(
            s.readMore,
            isMobile ? s.readMoreMobile : s.readMoreDesktop,
          )}
          key="text-toggle-text-expand"
          element={
            <button type="button" onClick={() => setExpanded(!expanded)} />
          }
        >
          {!expanded ? 'Продолжение' : 'Свернуть'}
          <Icon
            id="arrow-down"
            className={cn(s.readMoreIcon, expanded && s.readMoreIconUp)}
          />
        </Typography>
      ),
      [expanded, isMobile],
    );

    const sendReachGoal = useCallback(
      () =>
        new YandexEvent(YANDEX_METRICS.COMMON).send({
          type: 'reachGoal',
          data: 'bubble_menu_clicks',
        }),
      [],
    );

    if (!list || !list.length) return null;

    let groupedList: Record<string, any> = {};
    const bubblesGroup = _intersection(Object.keys(list[0]), BUBBLES_GROUPS);

    if (bubblesGroup.length) {
      bubblesGroup.forEach((group) => {
        const bubbleGroup: keyof IBubble = group as keyof IBubble;

        groupedList = {
          ...groupedList,
          ..._groupBy(list, (x: IBubble) => x[bubbleGroup]),
        };
      });
    } else {
      groupedList = {
        all: list,
      };
    }

    const renderList = (bubblesList: IBubble[], listKey: string) => (
      <div
        className={cn(
          s.bubbles,
          shortcut && !expanded && s.bubblesWithShortcut,
        )}
        style={{
          height:
            shortcut && !expanded
              ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                shortcut * 35
              : undefined,
        }}
        key={listKey}
      >
        {bubblesList.map((item, index) => {
          const {
            is_active: isActiveBubble,
            is_menu: isMenuBubble,
            link,
            name,
            sign,
          } = item;

          const isActiveSpecial =
            currentParamsSpecialKey && currentParamsSpecialCurrent
              ? path.includes(link)
              : false;

          const finalActive =
            isActiveBubble ||
            (isCardBubblesMoved ? path === link : false) ||
            isActiveSpecial;

          const finalActiveInBubbleMenu = finalActive
            ? 'current'
            : 'not_current';

          const key = `${link}-${index}-${finalActive}`;
          const bubbleTop100 = !isMenu
            ? `bubble::${link}`
            : `bubble_menu::${name}::${finalActiveInBubbleMenu}`;

          if (!isMenu && isMenuBubble) {
            return null;
          }

          return (
            <Typography
              key={key}
              variant="defaultMedium"
              className={cn(
                s.link,
                s.defaultBubble,
                finalActive && s.defaultBubbleActive,
                sign && s[sign],
                !isMobile && s.linkDesktop,
                finalActive && s[`${sign}Active`],
                isFirstSeparated && index === 0 && s.linkSeparated,
                isMenuBubble && s.menuBubble,
              )}
              element={
                <Link
                  to={link}
                  onClick={() => {
                    if (isMenu) {
                      sendReachGoal();
                    }
                  }}
                />
              }
              {...getTop100Markup(
                isMobile,
                top100Prefix || bubblesTop100Prefix || '',
                bubbleTop100,
              )}
            >
              {name}
            </Typography>
          );
        })}
        {calendar ? (
          <BubblesCalendar
            isMobile={isMobile}
            currentParamsUrl={currentParamsUrl}
            calendarType={calendar}
          />
        ) : null}
      </div>
    );

    return (
      <div className={cn(s.bubblesWrapper, className)}>
        {!isCardBubblesMoved && title && (
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            component="h2"
            className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}
          >
            {title}
          </Typography>
        )}
        {Object.keys(groupedList).map((group) => {
          if (group === 'all') {
            return renderList(groupedList[group], group);
          }

          const groupTitle = BUBBLES_TITLES_BY_TYPE[group];

          return (
            <div className={s.block} key={group}>
              {groupTitle && (
                <Typography variant="defaultMedium" className={s.blockText}>
                  {groupTitle}
                </Typography>
              )}
              {renderList(groupedList[group], group)}
            </div>
          );
        })}
        {shortcut ? renderToggle : null}
      </div>
    );
  },
);

Bubbles.displayName = 'Bubbles';

Bubbles.defaultProps = {
  bubbles: {
    title: '',
    list: [],
    calendar: undefined,
  },
  className: '',
  top100Prefix: '',
  isMenu: false,
  isFirstSeparated: false,
};

export { Bubbles };
