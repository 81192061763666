import React from 'react';
import cn from 'classnames';

import { DraftText } from 'common/components/DraftText';
import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardTarotTeaserProps {
  data: ICardProps['dashboard_tarot_teaser'];
  isMobile: boolean;
  isMainCard: boolean;
  className?: string;
}

function TarotTeaser({
  className,
  isMobile,
  isMainCard,
  data,
}: ICardTarotTeaserProps) {
  if (!data) return null;

  const { title, number, name, draftParsed, short } = data;

  return (
    <div
      className={cn(
        s.tarot,
        isMobile ? s.tarotMobile : s.tarotDesktop,
        isMainCard && s.tarotDetail,
        className,
      )}
    >
      <Typography
        variant="h2"
        component={isMainCard ? 'h2' : 'span'}
        className={s.title}
      >
        {title}
      </Typography>
      {isMainCard ? (
        <div className={s.detail}>
          <div
            className={cn(
              s.imageWrapper,
              isMobile ? s.imageWrapperMobile : s.imageWrapperDesktop,
            )}
          >
            <div
              className={cn(
                s.tarotCard,
                s['tarotCard-back'],
                s.back,
                s.backLeft,
              )}
            />
            <div
              className={cn(
                s.tarotCard,
                s['tarotCard-back'],
                s.back,
                s.backRight,
              )}
            />
            <div
              className={cn(
                s.image,
                s.imageDetail,
                s.tarotCard,
                s[`tarotCard-${number}`],
              )}
            />
          </div>
          <DraftText
            data={draftParsed}
            title={name}
            className={cn(
              s.draftText,
              isMobile ? s.draftTextMobile : s.draftTextDesktop,
            )}
            isMainCard
          />
        </div>
      ) : (
        <div
          className={cn(s.teaser, isMobile ? s.teaserMobile : s.teaserDesktop)}
        >
          <div className={cn(s.image, s.tarotCard, s[`tarotCard-${number}`])} />

          <div className={s.info}>
            <Typography variant="defaultBold" className={s.subTitle}>
              {name}
            </Typography>
            <Typography variant="defaultMedium" className={s.short}>
              {short}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

TarotTeaser.defaultProps = {
  className: '',
};

export { TarotTeaser };
