import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import {
  TEXT_RANGE_BEFORE_TOGGLE,
  TEXT_COMPATIBILITY_FORM_SYMBOLS_COUNT,
  getOrderedAds,
  getTextBreakPoint,
  getAdditionalAdsBreakPoint,
} from 'config/constants/content';

import { Ad } from 'common/components/Ad';

// TODO(HORO-0): fix this
// eslint-disable-next-line import/no-cycle
import { CompatibilityForm } from '../@Compatibility/Form';
import { Paragraph } from './Paragraph';
import { ParagraphWithLink } from './ParagraphWithLink';
import { Header } from './Header';
import { ToggleText } from './ToggleText';
import { CompatibilityTable } from './CompatibilityTable';

import s from './styles.css';

interface ICardTextProps {
  text: ICardProps['text'];
  isMainCard: ICardProps['isMainCard'];
  wrapperClassName?: string;
  isFormText?: boolean;
}

export interface RenderContentType {
  index: number;
  item: IText;
  isSliced: boolean;
  isMainRender: boolean;
  isLastContentElement: boolean;
}

function Text({
  text,
  wrapperClassName,
  isMainCard,
  isFormText,
}: ICardTextProps) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  if (!text || !text.length) return null;

  const adOrdered = getOrderedAds(isMobile);
  const textBreakPoint = getTextBreakPoint(isMobile);
  const additionalAdsBreakPoint = getAdditionalAdsBreakPoint(isMobile);

  let textLength = 0;
  let adCount = 0;

  const renderContent = ({
    index,
    item,
    isSliced,
    isMainRender,
    isLastContentElement,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }: RenderContentType) => {
    let textComponent = null;
    let adName = null;
    let breakPointFactor = 1;
    let key = '';

    if (isMainCard && isMainRender) {
      if (item.type === 'toggle-text') {
        let subTextLength = 0;

        item.text?.forEach((subText) => {
          if (subText.content) {
            subTextLength += subText.content.length;
          }
        });

        textLength +=
          subTextLength > TEXT_RANGE_BEFORE_TOGGLE
            ? TEXT_RANGE_BEFORE_TOGGLE
            : subTextLength;
      } else if (item.type === 'compatibility') {
        textLength += TEXT_COMPATIBILITY_FORM_SYMBOLS_COUNT;
      } else if (item.type === 'compatibility_table') {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        textLength += 500;
      } else if (item.content) {
        textLength += item.content.length;
      }

      if (isMobile && adCount > 2) {
        breakPointFactor = 2;
      }

      if (
        textLength >= textBreakPoint * breakPointFactor &&
        adCount < adOrdered.length
      ) {
        adName = adOrdered[adCount];
        adCount += 1;
        textLength = 0;
      } else if (
        adCount >= adOrdered.length &&
        textLength >= additionalAdsBreakPoint
      ) {
        adName = isMobile ? 'content4' : 'center';
        textLength = 0;
      }
    }

    switch (item.type) {
      case 'paragraph':
        key = `text-paragraph-${index}-${item.content?.length}`;
        textComponent = (
          <Paragraph content={item.content || ''} isMobile={isMobile} />
        );
        break;
      case 'paragraph-with-link':
        key = `text-paragraph-with-link-${index}-${item.content?.length}`;
        textComponent = (
          <ParagraphWithLink
            item={item}
            isSliced={isSliced}
            isMobile={isMobile}
          />
        );
        break;
      case 'compatibility_table':
        key = `text-compatibility_table-${index}`;
        textComponent = <CompatibilityTable table={item.percentage} />;
        break;
      case 'header-2':
      case 'header-3':
        key = `text-header-${index}-${item.content?.length}`;
        textComponent = (
          <Header
            type={item.type}
            content={item.content || ''}
            isMobile={isMobile}
          />
        );
        break;
      case 'toggle-text':
        key = `text-text-toggle-${index}-${item.text?.length}`;
        textComponent = (
          <ToggleText
            text={item.text as IText[]}
            renderContent={renderContent}
            isMobile={isMobile}
          />
        );
        break;
      case 'compatibility':
        key = `text-compatibility-form-${index}-${item.compatibility?.sign_man}-${item.compatibility?.sign_woman}`;
        textComponent = (
          <CompatibilityForm
            className={s.compatibility}
            defaultCompatibility={item.compatibility as CompatibilityData}
            isMobile={isMobile}
            isMainCard={isMainCard}
          />
        );
        break;
      default:
        return null;
    }

    return (
      <Fragment key={key}>
        {textComponent}
        {isMainCard && adName && !isLastContentElement && (
          <Ad
            name={adName}
            wrapperClassName={cn(s.ad, isMobile ? s.adMobile : s.adDesktop)}
          />
        )}
      </Fragment>
    );
  };

  const textArrLength = text.length;

  return (
    <div
      className={wrapperClassName}
      itemProp={!isFormText && isMainCard ? 'articleBody' : ''}
    >
      {text.map((item, index: number) =>
        renderContent({
          index,
          item,
          isSliced: false,
          isMainRender: true,
          isLastContentElement: index + 1 === textArrLength,
        }),
      )}
    </div>
  );
}

Text.defaultProps = {
  isFormText: false,
  wrapperClassName: '',
};

export { Text };
