import React, { Fragment } from 'react';
import cn from 'classnames';

import { safeGet } from 'utils/safeGet';

import { Title } from 'common/components/Card/Title';
import { Likes } from 'common/components/Card/Likes';

import cardStyles from 'common/components/Card/styles.css';

import { LongreadListRubric } from './components/ListRubric';
import { LongreadListImageLink } from './components/ListImageLink';

interface ICardLongreadListItemProps {
  isMobile: boolean;
  article: ICardProps['article'];
  isLazy: ICardProps['isLazy'];
  likes: ICardProps['likes'];
}

function LongreadListItem({
  isMobile,
  article,
  isLazy,
  likes,
}: ICardLongreadListItemProps) {
  if (!article) return null;

  const rubricAlias = safeGet(() => article.topic.alias, 'longread');
  const link = {
    link: `/${rubricAlias}/${article.id}-${article.normalized_title}/`,
  };

  const cardTitle = (
    <Title
      title={article.title}
      isMobile={isMobile}
      link={link}
      type="longread"
      isLink={false}
      key={`${article.id}-cardTitle`}
    />
  );

  const cardRubric = (
    <LongreadListRubric
      wrapperClassName={cardStyles.tags}
      article={article}
      isMobile={isMobile}
      key={`${article.id}-cardRubric`}
    />
  );

  return (
    <Fragment key={`LongreadListItem-${article.id}`}>
      {isMobile ? [cardTitle, cardRubric] : [cardRubric, cardTitle]}
      <Likes likes={likes} isFullImageCard />
      {!isMobile && (
        <div
          className={cn(
            cardStyles.pseudoFooter,
            cardStyles.pseudoFooterLongreadList,
            isMobile
              ? cardStyles.pseudoFooterMobile
              : cardStyles.pseudoFooterDesktop,
          )}
        />
      )}
      <LongreadListImageLink
        image={article.image}
        title={article.title}
        isLazy={isLazy}
        link={link}
      />
    </Fragment>
  );
}

export { LongreadListItem };
