import React, { useRef, useState, useCallback } from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { isInHorizontalViewport } from 'common/utils/isInViewport';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardOmensLifeHacks {
  lifeHacks: NonNullable<ICardProps['omens_by_tags']>;
  isMobile: boolean;
  className?: string;
}

function OmensLifeHacks({
  lifeHacks,
  isMobile,
  className,
}: ICardOmensLifeHacks) {
  const history = useHistory();
  const { top100Prefix } = useTop100Context();
  const tagsList = useRef(
    lifeHacks.reduce(
      (obj, tag) => [
        ...obj,
        {
          slug: tag.slug,
          title: tag.name_alt,
        },
      ],
      [],
    ),
  );
  const [arrowsState, setArrowsState] = useState({
    left: false,
    right: true,
  });
  const navListRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentNavTag, setCurrentNavTag] = useState(
    tagsList.current[activeIndex],
  );
  const [currentTag, setCurrentTag] = useState(lifeHacks[activeIndex]);
  const isDateSwitcher = /\d{4}-\d{2}-\d{2}/.test(currentTag.slug);

  const getLink = useCallback(
    (slug) => `/primety/${isDateSwitcher ? 'calendar' : 'word'}/${slug}/`,
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const moveNav = useCallback((index = 0, withSmooth = false) => {
    if (navListRef.current) {
      const { needScrollToViewLeft } = isInHorizontalViewport(
        navListRef.current,
        // TODO(HORO-0): fix
        // @ts-expect-error: Argument of type 'ChildNode' is not assignable to parameter of type 'HTMLElement'
        navListRef.current.childNodes[index],
      );

      navListRef.current.scrollTo({
        left: needScrollToViewLeft - navListRef.current.offsetLeft,
        behavior: withSmooth ? 'smooth' : 'auto',
      });
    }
  }, []);

  const handleClick = useCallback(
    (shift: number) => {
      let newIndex = 0;
      const nextIndex = activeIndex + shift;
      const maxIndex = tagsList.current.length - 1;

      if (shift < 0) {
        newIndex = nextIndex <= 0 ? 0 : nextIndex;
      } else {
        newIndex = maxIndex > nextIndex ? nextIndex : maxIndex;
      }

      setActiveIndex(newIndex);
      setArrowsState({
        left: newIndex !== 0,
        right: ![newIndex, newIndex + 1].includes(maxIndex),
      });
      moveNav(newIndex, true);
    },
    [activeIndex, moveNav],
  );

  const onClickButton = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault();

      history.push(getLink(currentTag.slug));
    },
    [currentTag.slug, getLink, history],
  );

  if (!lifeHacks || !lifeHacks.length) return null;

  return (
    <div className={cn(s.root, className)}>
      <div className={s.nav}>
        {!isMobile && (
          <Icon
            className={cn(
              s.icon,
              s.iconLeft,
              !arrowsState.left && s.iconDisabled,
            )}
            id="arrow-next"
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            onClick={() => arrowsState.left && handleClick(-2)}
          />
        )}
        <div
          className={cn(
            s.navList,
            isMobile ? s.navListMobile : s.navListDesktop,
          )}
          ref={navListRef}
        >
          {tagsList.current.map((tag, index) => (
            <Link
              to={getLink(tag.slug)}
              className={cn(
                s.tab,
                currentNavTag.slug === tag.slug && s.tabActive,
              )}
              onClick={(e) => {
                e.preventDefault();

                if (currentNavTag.slug !== tag.slug) {
                  setCurrentTag(lifeHacks[index]);
                  setCurrentNavTag(tagsList.current[index]);
                  handleClick(index - activeIndex);
                }
              }}
              key={`OmensLifeHacks-tag-${tag.slug}`}
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                'lifehack_block::button_click',
              )}
            >
              {tag.title}
            </Link>
          ))}
        </div>
        {!isMobile && (
          <Icon
            className={cn(
              s.icon,
              s.iconRight,
              !arrowsState.right && s.iconDisabled,
            )}
            id="arrow-next"
            onClick={() => arrowsState.right && handleClick(2)}
          />
        )}
      </div>
      <ul className={s.list}>
        {currentTag.omens.map((hack) => {
          const hackKey = `omenHack-${hack.id}`;

          return (
            <Typography
              variant="defaultMedium"
              component="li"
              className={s.omen}
              key={hackKey}
            >
              {hack.text}
            </Typography>
          );
        })}
      </ul>
      <Button
        href={getLink(currentTag.slug)}
        className={cn(s.more, isMobile ? s.moreMobile : s.moreDesktop)}
        onClick={(e) => onClickButton(e)}
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          'lifehack_block::button_click',
        )}
      >
        {isDateSwitcher
          ? `Все приметы на ${currentTag.name_alt}`
          : 'Все приметы'}
      </Button>
    </div>
  );
}

OmensLifeHacks.defaultProps = {
  className: '',
};

export default OmensLifeHacks;
