import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';

import Button from '@rambler-components/button';

import Select from '@rambler-components/select';
import { namesTypes, namesCategoriesExtended } from 'config/constants/names';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100 } from './utils';

import s from './styles.css';

const namesTypesList = {
  noType: 'Без подборки',
  ...namesTypes,
};

const namesCategoriesExtendedList = {
  noCategory: 'Без категории',
  ...namesCategoriesExtended,
};

interface ICardFormProps {
  isMobile: boolean;
}

function NamesCategoriesForm({ isMobile }: ICardFormProps) {
  const { top100Prefix } = useTop100Context();
  const history = useHistory();
  const [typeSelect, setTypeSelect] =
    useState<keyof typeof namesTypesList>('baby');
  const [categorySelect, setCategorySelect] =
    useState<keyof typeof namesCategoriesExtendedList>('modern');

  const onClick = useCallback(() => {
    const type =
      categorySelect === 'girls' ||
      categorySelect === 'boys' ||
      typeSelect === 'noType'
        ? ''
        : typeSelect;
    const typeInUrl = type ? `${type}/` : '';
    const categorySelectInUrl =
      categorySelect !== 'noCategory' ? `${categorySelect}/` : '';

    const url = `/names/${typeInUrl}${categorySelectInUrl}`;

    history.push(url);
  }, [typeSelect, categorySelect]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeType = useCallback((type) => {
    setTypeSelect(type);
  }, []);

  const onChangeCategory = useCallback((category) => {
    setCategorySelect(category);
  }, []);

  return (
    <>
      <Select
        className={getSizeStyle(isMobile, 'normal')}
        value={typeSelect}
        zIndex={150}
        type="border"
        onChange={onChangeType}
        options={Object.keys(namesTypesList).map(
          (typeKey: keyof typeof namesTypesList) => {
            const isDisabled =
              typeKey !== 'baby' &&
              typeKey !== 'noType' &&
              (categorySelect === 'girls' || categorySelect === 'boys');

            return {
              label: namesTypesList[typeKey],
              value: typeKey,
              disabled: isDisabled,
            };
          },
        )}
      />
      <Select
        className={getSizeStyle(isMobile, 'normal')}
        value={categorySelect}
        zIndex={150}
        type="border"
        onChange={onChangeCategory}
        options={Object.keys(namesCategoriesExtendedList).map(
          (categoryKey: keyof typeof namesCategoriesExtendedList) => {
            const isDisabled =
              typeSelect !== 'baby' &&
              typeSelect !== 'noType' &&
              (categoryKey === 'girls' || categoryKey === 'boys');

            return {
              label: namesCategoriesExtendedList[categoryKey],
              value: categoryKey,
              disabled: isDisabled,
            };
          },
        )}
      />
      <Button
        className={cn(s.button, isMobile && s.buttonMobile)}
        onClick={onClick}
        {...getTop100({
          isMobile,
          top100Prefix,
          form: 'names_categories',
          tail: 'result_button',
        })}
      >
        Перейти
      </Button>
    </>
  );
}

export { NamesCategoriesForm };
