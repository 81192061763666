import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography, TypographyType } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

export interface ICardTitleProps {
  title?: ICardProps['title'];
  isMobile: boolean;
  link?: ICardProps['link'];
  anchor?: ICardProps['anchor'];
  isLink: boolean;
  type: ICardProps['type'];
  wrapperClassName?: string;
  textClassName?: string;
  isH2Title?: boolean;
  isH3Title?: boolean;
  isMainCard?: boolean;
  isH1Title?: boolean;
  isHanding?: boolean;
  isSubCard?: boolean;
}

function Title({
  title,
  isMobile,
  link,
  anchor,
  isLink,
  isH2Title,
  isH3Title,
  isMainCard,
  isH1Title,
  type,
  wrapperClassName,
  textClassName,
  isHanding,
  isSubCard,
}: ICardTitleProps) {
  const { top100Prefix } = useTop100Context();
  if (!title) return null;

  const titleTextClass = cn(
    s.title,
    isMobile ? s.titleMobile : s.titleDesktop,
    type === 'longread' && s.titleLongreadList,
    isHanding && s.handing,
    textClassName,
  );

  const titleWrapperClass = cn(
    s.wrapper,
    isMobile ? s.wrapperMobile : s.titleDesktop,
    !isMobile && type === 'longread' && s.wrapperLongreadList,
    isSubCard && s.wrapperSubCard,
    wrapperClassName,
  );

  const titleProps = {
    'data-text': title.toLowerCase(),
    ...(isMainCard || isH1Title ? { itemProp: 'headline' } : {}),
  };

  let TitleTag: keyof JSX.IntrinsicElements = 'div';

  if (isMainCard || isH1Title) {
    TitleTag = 'h1';
  } else if (isH2Title) {
    TitleTag = 'h2';
  } else if (isH3Title) {
    TitleTag = 'h3';
  }

  let variant: TypographyType['variant'] = 'h1';

  if (isHanding) {
    variant = 'defaultMedium';
  }

  if (isSubCard) {
    variant = isMobile ? 'h3' : 'h2';
  }

  if (isLink && link && link.link) {
    return (
      <TitleTag
        className={titleWrapperClass}
        {...(anchor
          ? {
              id: `anchor-${anchor}`,
            }
          : {})}
        {...titleProps}
      >
        <Typography
          variant={variant}
          className={cn(titleTextClass, s.link)}
          element={<Link to={link.link} />}
          {...getTop100Markup(isMobile, top100Prefix, `title::${link.link}`)}
        >
          {title}
        </Typography>
      </TitleTag>
    );
  }

  return (
    <Typography
      variant={variant}
      component={TitleTag}
      className={cn(titleTextClass, titleWrapperClass)}
      {...titleProps}
    >
      {title}
    </Typography>
  );
}

Title.defaultProps = {
  title: '',
  link: { link: '' },
  anchor: undefined,
  isH2Title: false,
  isH3Title: false,
  isMainCard: false,
  isH1Title: false,
  wrapperClassName: '',
  textClassName: '',
  isHanding: false,
  isSubCard: false,
};

export { Title };
