import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [(state: IAppState) => state.runtime.isMobile],
  (isMobile) => ({
    isMobile,
  }),
);

function HairLink() {
  const { top100Prefix } = useTop100Context();
  const { isMobile } = useSelector(selectRuntimeData);

  return (
    <Typography
      variant="defaultMedium"
      className={cn(s.link, isMobile && s.linkMobile)}
      element={
        <Link
          to="/moon-articles/51419488-chto-takoe-lunnyy-kalendar-strizhek/"
          {...getTop100Markup(isMobile, top100Prefix, 'hair_article_link')}
        />
      }
    >
      Что такое лунный календарь стрижек?
    </Typography>
  );
}

export { HairLink };
