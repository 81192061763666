/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Ad } from 'common/components/Ad';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { isInHorizontalViewport } from 'common/utils/isInViewport';

import s from './styles.css';

interface ICardListBubblesProps {
  listBubbles: NonNullable<ICardProps['list_bubbles']>;
  isMobile: boolean;
  type: ICardProps['type'];
  className?: string;
}

const chunksCount = (isMobile = false) => (isMobile ? 2 : 3);

function ListBubbles({
  listBubbles,
  isMobile,
  type,
  className = '',
}: ICardListBubblesProps) {
  const { title, columns, tabs } = listBubbles;

  const getChunks = useCallback(
    (arr: INameBubble[]) =>
      arr.reduce<INameBubble[][]>((resultArray, item, index) => {
        const chunkSize = Math.ceil(arr.length / chunksCount(isMobile));
        const chunkIndex = Math.floor(index / chunkSize);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []),
    [isMobile],
  );

  const chunkedBubbles = useRef(listBubbles);
  const tabsList = useRef<string[]>([]);

  chunkedBubbles.current.tabs = useMemo(
    () =>
      tabs.map((tab) => {
        if (tab.tab_name) {
          tabsList.current.push(tab.tab_name);
        }

        return {
          ...tab,
          chunkedList:
            columns && tab.list.length >= chunksCount(isMobile)
              ? getChunks(tab.list)
              : [tab.list],
        };
      }),
    [columns, getChunks, isMobile, tabs],
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState(chunkedBubbles.current.tabs[0]);
  const [arrowsState, setArrowsState] = useState({
    left: false,
    right: tabs.length > 18,
  });

  const navListRef = useRef<HTMLDivElement>(null);
  const moveNav = useCallback(
    (newArrowsState, index = 0, withSmooth = false) => {
      const scrollWrapper = navListRef.current;

      if (scrollWrapper) {
        const { needScrollToViewLeft } = isInHorizontalViewport(
          scrollWrapper,
          // TODO(HORO-0): fix
          // @ts-expect-error: Argument of type 'ChildNode' is not assignable to parameter of type 'HTMLElement'
          scrollWrapper.childNodes[index],
        );

        const newLeft = needScrollToViewLeft - scrollWrapper.offsetLeft;

        scrollWrapper.scrollTo({
          left: newLeft,
          behavior: withSmooth ? 'smooth' : 'auto',
        });

        const isEndOfScroll =
          scrollWrapper.scrollWidth <= newLeft + scrollWrapper.offsetWidth;

        if (isEndOfScroll) {
          setArrowsState({
            ...newArrowsState,
            right: false,
          });
        } else {
          setArrowsState(newArrowsState);
        }
      }
    },
    [],
  );

  const handleClick = useCallback(
    (shift: number) => {
      let newIndex = 0;
      const nextIndex = activeIndex + shift;
      const maxIndex = tabsList.current.length - 1;

      if (shift < 0) {
        newIndex = nextIndex <= 0 ? 0 : nextIndex;
      } else {
        newIndex = maxIndex > nextIndex ? nextIndex : maxIndex;
      }

      const nextArrowsState =
        (!arrowsState.left && !arrowsState.right) || tabs.length <= 18
          ? {
              left: false,
              right: false,
            }
          : {
              left: newIndex !== 0,
              right: ![newIndex, newIndex + 1].includes(maxIndex),
            };

      setActiveIndex(newIndex);
      moveNav(nextArrowsState, newIndex, true);
    },
    [activeIndex, arrowsState.left, arrowsState.right, moveNav, tabs.length],
  );

  useEffect(() => {
    if (activeIndex) {
      moveNav(arrowsState, activeIndex, false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!listBubbles) return null;

  // TODO(HORO-0): у ListBubbles, NamesByParams и NamesFatherNames много общего. Переработать

  return (
    <>
      <div
        className={cn(
          s.root,
          isMobile ? s.rootMobile : s.rootDesktop,
          className,
        )}
      >
        {title && (
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            component="h2"
            className={s.title}
          >
            {title}
          </Typography>
        )}
        {tabsList.current.length > 0 ? (
          <div className={s.nav}>
            {!isMobile && (
              <Icon
                className={cn(
                  s.icon,
                  s.iconLeft,
                  !arrowsState.left && s.iconDisabled,
                )}
                id="arrow-next"
                onClick={() => arrowsState.left && handleClick(-2)}
              />
            )}
            <div
              className={cn(
                s.navList,
                isMobile ? s.navListMobile : s.navListDesktop,
              )}
              ref={navListRef}
            >
              {tabsList.current.map((tab, index) => (
                <span
                  className={cn(
                    s.tab,
                    currentTab.tab_name === tab && s.tabActive,
                  )}
                  onClick={() => {
                    if (currentTab.tab_name !== tab) {
                      setCurrentTab(chunkedBubbles.current.tabs[index]);
                      setActiveIndex(index);
                      handleClick(index - activeIndex);
                    }
                  }}
                  key={`ListBubbles-tab-${tab}`}
                >
                  {tab}
                </span>
              ))}
            </div>
            {!isMobile && (
              <Icon
                className={cn(
                  s.icon,
                  s.iconRight,
                  !arrowsState.right && s.iconDisabled,
                )}
                id="arrow-next"
                onClick={() => arrowsState.right && handleClick(2)}
              />
            )}
          </div>
        ) : null}
        <div className={cn(s.list, isMobile ? s.listMobile : s.listDesktop)}>
          {currentTab.chunkedList.map((chunk, cIndex) => {
            const chunkIndex = `ListBubbles-${currentTab.tab_name}-${cIndex}`;

            return (
              <div
                className={cn(
                  s.chunk,
                  columns ? s.chunkColumn : s.chunkLine,
                  isMobile ? s.chunkMobile : s.chunkDesktop,
                )}
                key={chunkIndex}
              >
                {chunk.map((item) => {
                  const { link, name } = item;
                  const key = `${chunkIndex}-${link}`;

                  return (
                    <Typography
                      variant="defaultMedium"
                      className={cn(
                        s.defaultBubble,
                        s.link,
                        item.sign && s[item.sign],
                      )}
                      key={key}
                      element={<Link to={item.link} />}
                    >
                      {name}
                    </Typography>
                  );
                })}
              </div>
            );
          })}
          {currentTab.chunkedList.length < chunksCount(isMobile) ? (
            <div className={s.listFake} />
          ) : null}
        </div>
      </div>
      {type === 'list_view_alphabet' && (
        <Ad
          name={isMobile ? 'content1' : 'center'}
          wrapperClassName={isMobile ? s.adMobile : s.adDesktop}
        />
      )}
    </>
  );
}

ListBubbles.defaultProps = {
  className: '',
};

export { ListBubbles };
