import React, {
  CSSProperties,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import qs from 'qs';

import _throttle from 'lodash.throttle';

import isWithinInterval from 'date-fns/isWithinInterval';

import AdComponent, { RenderResultType } from '@rambler-components/ad';

import { AD_BACKGROUNDS_BY_THEMES, AD_PREFIX } from 'config/constants/ad';
import { METRICS_AD_COUNT } from 'config/constants/metrics';

import { safeGet } from 'utils/safeGet';

import { isInVerticalViewport } from 'common/utils/isInViewport';
import { clientMetricsBatch } from 'utils/metrics/clientMetricsBatch';

import { useAdvObserver } from './hooks/useAdvObserver';
import { useViewType } from './hooks/useViewType';
import s from './index.css';

interface AdOptions {
  puid6: string;
  puid15?: string;
  puid18?: string;
  // puid44 - для трекинга "экрана" вызова. "Экран" начинается с каждого нового billboard
  puid44?: string;
  puid48?: string;
  puid31?: string;
}

/**
 * TODO(HORO-0):
 * добавить разметку топ100 (понадобится записывать/получать page из runtime)
 * передавать в рекламный компонент объект options, включающий в себя пуиды
 */

export interface AdProps {
  name?: string;
  className?: string;
  wrapperClassName?: string;
  wrapperOnRenderClassName?: string;
  onRenderStyle?: CSSProperties;
  onRenderWrapperStyle?: CSSProperties;
  children?: React.ReactNode[];
  isLazy?: boolean;
  onRender?: () => void;
  bannerCount?: number;
  puid44?: string;
  isFirstBillboardOrTopBanner?: boolean;
  isExBillboard?: boolean;
}

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.banners,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.reloadKey,
    (state: IAppState) => state.runtime.puid6,
    (state: IAppState) => state.runtime.puid15,
    (state: IAppState) => state.runtime.puid18,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.runtime.PWADisplayMode,
    (state: IAppState) => state.runtime.isRussia,
  ],
  (
    banners,
    isMobile,
    reloadKey,
    puid6,
    puid15,
    puid18,
    isBranding,
    PWADisplayMode,
    isRussia,
    // eslint-disable-next-line max-params
  ) => ({
    banners,
    isMobile,
    reloadKey,
    puid6,
    puid15,
    puid18,
    isBranding,
    PWADisplayMode,
    isRussia,
  }),
);

const selectOtherData = createSelector(
  [
    (state: IAppState) => state.account.theme,
    (state: IAppState) => state.runtime.adminData.settings.themes_disabled,
    (state: IAppState) =>
      state.runtime.adminData.settings.superfooter_placeholder_400,
    (state: IAppState) =>
      state.runtime.adminData.timers.promovidzhet_visibility,
    (state: IAppState) =>
      state.runtime.adminData.settings.promovidzhet_isrussian,
    (state: IAppState) =>
      state.runtime.adminData.settings.promovidzhet_placeholder,
  ],
  (
    theme,
    isThemesDisabled,
    superfooterPlaceholder400,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
    promoWidgetWithPlaceholder,
    // eslint-disable-next-line max-params
  ) => ({
    theme,
    isThemesDisabled,
    superfooterPlaceholder400,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
    promoWidgetWithPlaceholder,
  }),
);

/**
 * Компонент вставки рекламы.
 *
 * @param name - название рекламного блока
 * @param className - класс стилизации рекламного места
 * @param wrapperClassName - класс враппера
 * @param onRenderStyle - стили после рендера для рекламы
 * @param wrapperOnRenderClassName - класс после рендера для враппера
 * @param onRenderWrapperStyle - стили после рендера для враппера
 * @param children - дочерние ноды рекламного места. Fallback отсутствия рекламы
 */
export function Ad({
  name,
  className,
  wrapperClassName,
  onRenderStyle,
  wrapperOnRenderClassName,
  onRenderWrapperStyle,
  children,
  isLazy,
  onRender,
  bannerCount,
  puid44,
  isFirstBillboardOrTopBanner,
  isExBillboard,
}: AdProps) {
  const {
    banners,
    isMobile,
    reloadKey,
    puid6,
    puid15,
    puid18,
    isBranding,
    PWADisplayMode,
    isRussia,
  } = useSelector(selectRuntimeData);
  const {
    theme,
    isThemesDisabled,
    superfooterPlaceholder400,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
    promoWidgetWithPlaceholder,
  } = useSelector(selectOtherData);
  const adRef = useRef<HTMLDivElement>(null);
  const isBannerReallyRendered = useAdvObserver(adRef);

  const slot = banners.slots[name || ''];

  const [dontRenderBanner, setDontRenderBanner] = useState(false);

  const isBillboard = name === 'billboard';
  const isTopBanner = name === 'top_banner';
  const isToplineBanner = name === 'topline_banner';
  const isPromoWidget = name?.includes('Promovidzhet');

  const updateUrl = (count: number, direction?: 'up' | 'down') => {
    if (!window.history || !window.history.replaceState) return;

    const { origin, pathname, search } = document.location;

    const pageCount = count > 1 ? `?page=${count}` : '';

    const newUrl = `${origin}${pathname}${pageCount}`;

    if (search) {
      const query = qs.parse(search, {
        parameterLimit: 10,
        ignoreQueryPrefix: true,
      });

      const pageObj = count <= 1 ? {} : { page: count };

      if (count <= 1) {
        delete query.page;
      }

      const newQuery = qs
        .stringify(
          {
            ...query,
            ...pageObj,
          },
          {
            strictNullHandling: false,
          },
        )
        .replace('updated=', 'updated');
      const newQueryInUrl = newQuery.length > 0 ? `?${newQuery}` : '';

      const urlWithNewQuery = `${origin}${pathname}${newQueryInUrl}`;

      if (query.page) {
        // @ts-expect-error: Argument of type 'string | ParsedQs | string[] | ParsedQs[]' is not assignable to parameter of type 'string'.
        const currentPageInUrl = parseInt(query.page, 10);

        if (
          typeof currentPageInUrl === 'number' &&
          currentPageInUrl !== count &&
          ((direction === 'up' && count < currentPageInUrl) ||
            (direction === 'down' && count > currentPageInUrl))
        ) {
          window.history.replaceState(
            { urlWithNewQuery },
            'title',
            urlWithNewQuery,
          );
        }
      } else {
        window.history.replaceState(
          { urlWithNewQuery },
          'title',
          urlWithNewQuery,
        );
      }
    } else {
      window.history.replaceState({ newUrl }, 'title', newUrl);
    }
  };

  useEffect(() => {
    setDontRenderBanner(
      isMobile && PWADisplayMode === 'twa' && !slot.needInTWA,
    );

    let topPos = 0;

    const checkBillboardVisible = () => {
      if (adRef && adRef.current) {
        const result = isInVerticalViewport(adRef);
        let direction: 'up' | 'down' | undefined;

        if (topPos && topPos < result.needScroll) {
          direction = 'up';
        } else if (topPos && topPos > result.needScroll) {
          direction = 'down';
        }

        topPos = result.needScroll;

        if (result && result.top && bannerCount) {
          updateUrl(
            direction === 'down' ? bannerCount : bannerCount - 1,
            direction,
          );
        }
      }
    };
    const throttledChangeVisibility = _throttle(checkBillboardVisible, 100, {
      trailing: true,
    });

    if (
      (isBillboard || isTopBanner || isExBillboard) &&
      bannerCount &&
      bannerCount > 1 &&
      slot &&
      adRef &&
      adRef.current
    ) {
      checkBillboardVisible();

      window.addEventListener('scroll', throttledChangeVisibility);
    }

    return () => {
      window.removeEventListener('scroll', throttledChangeVisibility);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useViewType(name || '', isFirstBillboardOrTopBanner || false);

  const withPlaceholder = useMemo(() => {
    if (isPromoWidget) {
      return promoWidgetWithPlaceholder;
    }

    return (
      slot?.withPlaceholder && safeGet(() => !window.isAdblockEnabled, true)
    );
  }, [slot?.withPlaceholder, isPromoWidget, promoWidgetWithPlaceholder]);

  const setPlaceholderHeight = useMemo(
    () => !(isBranding && isBillboard) && withPlaceholder,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBranding, withPlaceholder],
  );

  const defaultPlaceholderStyles = useMemo(() => {
    let styles = {};

    if (!(isMobile || isThemesDisabled || isBranding || isToplineBanner)) {
      styles = { backgroundColor: AD_BACKGROUNDS_BY_THEMES[theme] };
    }

    if (
      superfooterPlaceholder400 &&
      setPlaceholderHeight &&
      name === 'superfooter'
    ) {
      styles = {
        ...styles,
        minHeight: 400,
      };
    }

    return styles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranding, theme]);

  const onRenderAd = useCallback(() => {
    clientMetricsBatch.add({
      name: METRICS_AD_COUNT,
      value: {
        name: name || '',
        task: 'mounted',
      },
    });

    if (onRender) {
      onRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onComplete = useCallback((result: RenderResultType) => {
    if (typeof result?.status === 'undefined') {
      return;
    }

    const value: Record<string, string | number> = {
      name: name || '',
      task: result.status ? 'successful' : 'unsuccessful',
    };

    if (!result.status) {
      value.reason = result.reason || 'Reason not stated';
    }

    clientMetricsBatch.add({
      name: METRICS_AD_COUNT,
      value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPromoVisible = useMemo(() => {
    if (!isPromoWidget || !promovidzhetShowTimer?.enabled) return false;
    if (promovidzhetIsRussian && !isRussia) return false;

    const { enabled_from: start, enabled_to: end } = promovidzhetShowTimer;

    const currentDate = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    return isWithinInterval(currentDate, {
      start: startDate,
      end: endDate,
    });
  }, [isRussia, promovidzhetShowTimer, promovidzhetIsRussian, isPromoWidget]);

  if (!slot) return null;

  if (isPromoWidget && !isPromoVisible) return null;

  // We don't need render some banners: in TWA without "needInTWA: true" param
  // All other banners will render as usuall
  if (dontRenderBanner) return null;

  const options: AdOptions = {
    puid6,
    puid31: 'horoscopes.rambler',
  };

  if (puid15) {
    options.puid15 = 'article';
  }

  if (puid18) {
    options.puid18 = puid18;
  }

  if (puid44) {
    options.puid44 = puid44;
  }

  if (isPromoWidget) {
    options.puid6 = 'HOROSCOPESRAMBLERRU_MAIN';
    options.puid18 = 'HOROSCOPESRAMBLERRU_MAIN_MAIN';
  }

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        wrapperClassName,
        isBannerReallyRendered && wrapperOnRenderClassName,
      )}
      style={
        isBannerReallyRendered || withPlaceholder ? onRenderWrapperStyle : {}
      }
    >
      <div
        className={cn(
          s.wrapper,
          isBillboard && s.wrapperBillboard,
          slot.withPlaceholder && s.placeholder,
          withPlaceholder && s.placeholderVisible,
          setPlaceholderHeight && s[`placeholder_${name}`],
        )}
        style={defaultPlaceholderStyles}
      >
        <div ref={adRef}>
          <AdComponent
            id={slot.id}
            padId={banners.padID}
            idPrefix={AD_PREFIX}
            className={className}
            loadedStyle={onRenderStyle}
            onRender={onRenderAd}
            onComplete={onComplete}
            isLazy={isLazy ?? slot.isLazy}
            reloadKey={reloadKey}
            options={options}
          >
            {children}
          </AdComponent>
        </div>
      </div>
    </div>
  );
}

Ad.defaultProps = {
  name: '',
  className: '',
  wrapperClassName: '',
  onRenderStyle: {},
  wrapperOnRenderClassName: '',
  onRenderWrapperStyle: {},
  children: null,
  isLazy: undefined,
  onRender: () => {},
  bannerCount: 0,
  puid44: null,
  isFirstBillboardOrTopBanner: false,
  isExBillboard: false,
};
