import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/** Хук для отслеживания рендера рекламы */
export const useAdvObserver = (adRef: React.MutableRefObject<any>) => {
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  useEffect(() => {
    const ref = adRef?.current;

    if (ref && !isBannerLoaded) {
      const onResize = () => {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        setIsBannerLoaded(ref.getBoundingClientRect().height > 10);
      };

      let resizeObserver: ResizeObserver | null = new ResizeObserver(onResize);

      resizeObserver.observe(ref);
      onResize();

      return () => {
        if (resizeObserver) {
          // eslint-disable-next-line sonarjs/no-gratuitous-expressions
          if (ref) {
            resizeObserver.unobserve(ref);
          } else {
            resizeObserver.disconnect();
          }

          resizeObserver = null;
        }
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isBannerLoaded;
};
