import React, { useRef, useEffect } from 'react';
import cn from 'classnames';

const fakeAdClassnames = [
  'ad',
  'ad-banner',
  'adbanner',
  'nav-ad',
  'pub_300x250',
  'pub_300x250m',
  'pub_728x90',
  'text-ad',
  'textAd',
  'text_ad',
  'text_ads',
  'text-ads',
  'text-ad-links',
];

function AdblockDetect() {
  const fakeAdNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fakeAdNode.current) {
      const styles = window.getComputedStyle(fakeAdNode.current, null);

      window.isAdblockEnabled =
        styles.getPropertyValue('display') === 'none' ||
        styles.getPropertyValue('visibility') === 'hidden';
    }
  }, []);

  return (
    <div
      className={cn(fakeAdClassnames)}
      ref={fakeAdNode}
      style={{
        width: 1,
        height: 1,
        position: 'absolute',
        left: -100000,
      }}
    />
  );
}

export { AdblockDetect };
