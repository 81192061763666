import React, { useCallback } from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';

import Button from '@rambler-components/button';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { DayNumber } from './DayNumber';
import { TarotTeaser } from './TarotTeaser';
import { Biorhythm } from './Biorhythm';
import { BiorhythmText } from './Biorhythm/Text';
import { BiorhythmWithoutAccount } from './BiorhythmWithoutAccount';

import s from './styles.css';

interface ICardDayNumberProps {
  dayNumber: ICardProps['dashboard_day_number'];
  tarotTeaser: ICardProps['dashboard_tarot_teaser'];
  biorhythms: ICardProps['dashboard_biorhythms'];
  celebritySlug: ICardProps['celebrity_slug'];
  date: ICardProps['date'];
  isMobile: boolean;
  isMainCard: boolean;
  className?: string;
}

const PersonalCard: React.FC<ICardDayNumberProps> = React.memo(
  ({
    dayNumber,
    tarotTeaser,
    biorhythms,
    celebritySlug = '',
    date,
    isMobile,
    isMainCard,
    className,
  }) => {
    const { top100Prefix } = useTop100Context();
    const history = useHistory();

    const onClickButton = useCallback(
      (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        history.push('/personal/');

        window.scrollTo(0, 0);
      },
      [history],
    );

    const withoutAccountCard = celebritySlug && !isMainCard;
    const showDayNumberAndTarot = isMainCard || !celebritySlug;
    const showButton =
      !showDayNumberAndTarot || (!isMainCard && !celebritySlug && isMobile);

    return (
      <div className={className}>
        {withoutAccountCard ? (
          <BiorhythmWithoutAccount />
        ) : (
          biorhythms && (
            <>
              <Biorhythm
                isMobile={isMobile}
                celebritySlug={celebritySlug}
                isMainCard={isMainCard}
                title={biorhythms.title}
                date={date}
              />
              <BiorhythmText
                data={biorhythms.data}
                isMainCard={isMainCard}
                isMobile={isMobile}
              />
            </>
          )
        )}
        {showDayNumberAndTarot && (
          <div
            className={cn(
              s.data,
              !isMainCard && s.dataList,
              !isMainCard && isMobile && s.dataListMobile,
              !isMainCard && !isMobile && s.dataListDesktop,
            )}
          >
            <DayNumber
              data={dayNumber}
              isMobile={isMobile}
              isMainCard={isMainCard}
            />
            <TarotTeaser
              data={tarotTeaser}
              isMobile={isMobile}
              isMainCard={isMainCard}
            />
          </div>
        )}
        {!withoutAccountCard && showButton && (
          <Button
            href="/personal/"
            className={cn(s.more, isMobile ? s.moreMobile : s.moreDesktop)}
            onClick={(e) => onClickButton(e)}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              'dashboard::moreButton',
            )}
          >
            Узнать подробнее
          </Button>
        )}
      </div>
    );
  },
);

PersonalCard.displayName = 'PersonalCard';

export { PersonalCard };
