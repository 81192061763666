import React from 'react';
import cn from 'classnames';
import _pick from 'lodash.pick';

import { Link } from 'react-router-dom';

import { signSummary } from 'config/constants/signSummary';
import { tarotList } from 'config/constants/tarot';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardSignSummaryProps {
  summary: ICardProps['summary'];
  isMobile: boolean;
  className?: string;
  highlight?: IHighlight['list'][0];
}

type SummaryItemType = keyof NonNullable<ICardProps['summary']>;

function ZodiacSignSummary({
  summary,
  isMobile,
  className,
  highlight,
}: ICardSignSummaryProps) {
  if (!summary) return null;

  const allSummaryKeys = Object.keys(signSummary);
  const currentSummaryKeys = Object.keys(_pick(summary, allSummaryKeys));

  if (!currentSummaryKeys.length) return null;

  const sign = highlight?.icon;
  const currentTarotCard = tarotList[0].cards.find(({ name, synonyms }) =>
    synonyms
      ? synonyms[0] === summary.tarot[0] || name === summary.tarot[0]
      : name === summary.tarot[0],
  );

  const summaryValueUrl: Record<
    Extract<SummaryItemType, 'trait' | 'tarot' | 'compatibility'>,
    string
  > = {
    trait: '/znaki-zodiaka/kresty/',
    tarot: `/taro/znachenie-kart/${currentTarotCard?.slug}/`,
    compatibility: `/${sign}/sovmestimost-znakov-zodiaka/`,
  };

  return (
    <div className={cn(s.summary, isMobile && s.summaryMobile, className)}>
      {currentSummaryKeys.map((summaryItem: SummaryItemType) => {
        const isStone = summaryItem === 'stone';
        const isTrait = summaryItem === 'trait';
        const isTarot = summaryItem === 'tarot';
        const isCompatibility = summaryItem === 'compatibility';

        return (
          <Typography
            variant="defaultMedium"
            component="div"
            className={cn(s.summaryItem, isMobile && s.summaryItemMobile)}
            key={summaryItem}
          >
            {isStone && sign ? (
              <Link
                className={cn(s.summaryTitle, s.summaryTitleLink)}
                to={`/znaki-zodiaka/birthstone/${sign}/`}
              >
                {signSummary[summaryItem]}
              </Link>
            ) : (
              <div className={s.summaryTitle}>{signSummary[summaryItem]}</div>
            )}

            {isTrait ||
            (isTarot && currentTarotCard) ||
            (isCompatibility && sign) ? (
              <Link
                className={cn(s.summaryText, s.summaryTextLink)}
                to={summaryValueUrl[summaryItem]}
              >
                {summary[summaryItem].join(', ')}
              </Link>
            ) : (
              <div className={s.summaryText}>
                {summary[summaryItem].join(', ')}
              </div>
            )}
          </Typography>
        );
      })}
    </div>
  );
}

ZodiacSignSummary.defaultProps = {
  className: '',
  highlight: undefined,
};

export { ZodiacSignSummary };
