import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { initShareButtons } from 'utils/share/initShareButtons';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { Icon } from 'common/components/Icon';
import { Likes } from 'common/components/Card/Likes';
import { Typography } from 'common/components/Typography';

import { postCardLike, deleteCardLike } from 'common/redux/likes';

import { safeGet } from 'utils/safeGet';
import { getLinkFromUrl } from 'utils/getLinkfromUrl';
import s from './styles.css';

const selectData = () =>
  createSelector(
    [
      (state: IAppState) => state.runtime.rsid,
      (state: IAppState) => state.runtime.origin,
      (state: IAppState, codename: string) => state.likes[codename],
    ],
    (rsid, origin, likesData) => ({
      rsid,
      origin,
      likesData,
    }),
  );

interface ICardFooterProps {
  title: ICardProps['title'];
  link: ICardProps['link'];
  likes?: ICardProps['likes'];
  isMobile: boolean;
  className?: string;
  likesClassName?: string;
}

function Footer({
  title,
  link,
  likes,
  isMobile,
  className,
  likesClassName,
}: ICardFooterProps) {
  const { top100Prefix } = useTop100Context();
  const dispatch = useDispatch();

  const codename = likes ? likes.codename : '';
  const hash = likes ? likes.hash : '';

  /**
   * https://react-redux.js.org/api/hooks => However, when the selector is used
   * in multiple component instances and depends on the component's props,
   * you need to ensure that each component instance gets its own selector instance
   */
  const memoSelectData = useMemo(selectData, []);
  const { rsid, origin, likesData } = useSelector((state) =>
    memoSelectData(state, codename),
  );

  const socialButtonsWrapperRef = useRef<HTMLDivElement>(null);
  const [shareVisible, setShareVisible] = useState(false);
  const [socialButtonsInited, setSocialButtonsInited] = useState(false);
  const isPending = useRef(false);

  const toggleShare = () => {
    if (!socialButtonsInited && socialButtonsWrapperRef.current) {
      initShareButtons({
        node: socialButtonsWrapperRef.current,
        cardUrl: getLinkFromUrl(
          safeGet(() => link?.link, ''),
          origin,
        ),
        cardTitle: title,
        iconSize: 17,
        isMobile,
      });

      setSocialButtonsInited(true);
    }

    setShareVisible(!shareVisible);
  };

  useEffect(() => {
    if (
      socialButtonsInited &&
      socialButtonsWrapperRef.current &&
      link &&
      link.link
    ) {
      initShareButtons({
        node: socialButtonsWrapperRef.current,
        cardUrl: getLinkFromUrl(
          safeGet(() => link?.link, ''),
          origin,
        ),
        cardTitle: title,
        iconSize: 17,
        isMobile,
      });
    }
  }, [link]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleLike = async () => {
    if (!rsid && window.ramblerIdHelper) {
      window.ramblerIdHelper.redirectToAuth({
        rname: 'horoscopes',
        theme: 'horoscopes',
        back: 'https://horoscopes.rambler.ru/',
        path: '/login-20/phone-login',
      });

      return;
    }

    if (isPending.current || !rsid) {
      return;
    }

    isPending.current = true;

    if (likesData && likesData.liked) {
      await dispatch(deleteCardLike(codename));
    } else {
      await dispatch(postCardLike(codename, hash));
    }

    isPending.current = false;
  };

  return (
    <div className={cn(s.footer, isMobile && s.footerMobile, className)}>
      <div
        className={cn(s.buttonContainer, isMobile && s.buttonContainerMobile)}
      >
        {likesData && (
          <button
            type="button"
            className={cn(
              s.like,
              isMobile && s.mobile,
              likesData && likesData.liked && s.likeActive,
            )}
            onClick={toggleLike}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              `footer::${likesData && likesData.liked ? 'unlike' : 'like'}`,
            )}
          >
            <Icon
              id={likesData && likesData.liked ? 'like-active' : 'like'}
              className={cn(
                s.likeIcon,
                likesData && likesData.liked && s.likeIconActive,
              )}
            />
            <Typography variant="defaultMedium" className={s.text}>
              Нравится
            </Typography>
          </button>
        )}
        {link && (
          <>
            <button
              type="button"
              className={cn(
                s.share,
                isMobile && s.mobile,
                shareVisible && s.shareActive,
              )}
              onClick={toggleShare}
              {...getTop100Markup(
                isMobile,
                top100Prefix,
                `footer::share::${shareVisible ? 'hide' : 'show'}`,
              )}
            >
              <Icon id="share" className={s.shareIcon} />
              <Typography variant="defaultMedium" className={s.text}>
                Поделиться
              </Typography>
            </button>
            <div
              className={cn(
                s.social,
                shareVisible && s.socialVisible,
                isMobile && s.socialMobile,
              )}
            >
              <Icon
                id="close"
                className={s.socialClose}
                onClick={() => setShareVisible(false)}
              />
              <div
                className={cn('rambler-share', s.buttons)}
                ref={socialButtonsWrapperRef}
              />
            </div>
          </>
        )}
      </div>
      {likesData && (
        <Likes
          likes={likes}
          className={cn(likesClassName, isMobile && s.likesMobile)}
          isFooter
        />
      )}
    </div>
  );
}

Footer.defaultProps = {
  likes: {
    codename: '',
    hash: '',
  },
  className: '',
  likesClassName: '',
};

export { Footer };
