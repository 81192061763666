import React, { useState, useMemo } from 'react';
import cn from 'classnames';

import _truncate from 'lodash.truncate';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from '../index.css';

const LETTERS_BEFORE_TOGGLE = 170;

interface ITextProps {
  text: React.ReactElement[];
  isMobile: boolean;
  isMainCard: boolean;
  className: string;
  currentPage: string;
  currentSection: string;
  currentParamsUrl: string;
}

function Toggle({
  text,
  isMobile,
  isMainCard,
  className,
  currentPage,
  currentSection,
  currentParamsUrl,
}: ITextProps) {
  const { top100Prefix } = useTop100Context();
  const [expanded, setExpanded] = useState(false);

  const button = useMemo(
    () => (
      <Typography
        variant="defaultRegular"
        className={s.readMore}
        key="toggle-text-expand"
        element={
          <button type="button" onClick={() => setExpanded(!expanded)} />
        }
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          `toggle::${expanded ? 'hide_draft_text' : 'more_draft_text'}`,
        )}
      >
        {!expanded ? 'Читать дальше' : 'Свернуть'}
        <Icon
          id="arrow-down"
          className={cn(s.readMoreIcon, expanded && s.readMoreIconUp)}
        />
      </Typography>
    ),
    [expanded, isMobile, top100Prefix],
  );

  if (!text) return null;

  const slicedText = (currentText: string, length: number) =>
    _truncate(currentText, {
      length,
      omission: '\u00A0…', // использую юникод-символ неразрывного пробела, т.к. &nbsp; выводился текстом
      // eslint-disable-next-line no-useless-escape
      separator: /[\.,!\?…:;\(\-—\s]/,
    });

  let textLength = 0;

  return (
    <div
      className={cn(
        s.draftContent,
        isMobile ? s.draftContentMobile : s.draftContentDesktop,
        className,
      )}
      itemProp={isMainCard ? 'articleBody' : ''}
    >
      {text.map((textItem, index) => {
        const key = `DraftText-${currentPage}-${currentSection}-${currentParamsUrl}-${index};`;
        const currentItemTextLength = textItem.props.children.length;

        textLength += currentItemTextLength;

        if (!expanded && textLength > LETTERS_BEFORE_TOGGLE) {
          const newLength =
            currentItemTextLength - (textLength - LETTERS_BEFORE_TOGGLE);
          const newElement = React.cloneElement(
            textItem,
            {},
            slicedText(textItem.props.children, newLength),
          );

          return <React.Fragment key={key}>{newElement}</React.Fragment>;
        }

        return <React.Fragment key={key}>{textItem}</React.Fragment>;
      })}
      {button}
    </div>
  );
}

export default Toggle;
