import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardLongreadListRubricProps {
  article: ICardProps['article'];
  isMobile: boolean;
  wrapperClassName?: string;
  textClassName?: string;
}

function LongreadListRubric({
  article,
  isMobile,
  wrapperClassName,
  textClassName,
}: ICardLongreadListRubricProps) {
  const { top100Prefix } = useTop100Context();

  if (!article || !article.topic) return null;

  const link = `/${article.topic.alias || 'longread'}/`;

  return (
    <div
      className={cn(
        s.rubrics,
        isMobile ? s.rubricsMobile : s.rubricsDesktop,
        wrapperClassName,
      )}
    >
      <Typography
        variant="defaultMedium"
        className={cn(s.rubric, textClassName)}
        element={
          <Link
            to={link}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              `article_block::card_click::${link}`,
            )}
          />
        }
      >
        {article.topic.name}
      </Typography>
    </div>
  );
}

LongreadListRubric.defaultProps = {
  wrapperClassName: '',
  textClassName: '',
};

export { LongreadListRubric };
