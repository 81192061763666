import React from 'react';
import cn from 'classnames';

import s from './styles.css';

interface IShakespeareLoaderProps {
  isMobile: boolean;
}

/**
 * Компонент анимированного лоадера для брендированного гадания да/нет Окко.
 *
 * @see https://www.figma.com/design/6XVRX3Pg4xCQ6GHrNQuVF9/Rambler-horoscopes?node-id=6639-58010&t=sFsAqOOPHDgOdtQ3-0
 */
export const ShakespeareLoader = React.memo(
  ({ isMobile }: IShakespeareLoaderProps) => (
    <div className={s.root}>
      <div className={cn(s.background, isMobile && s.mobile)} />
      <div className={s.bulb} />
      <div className={s.dotWrapper}>
        <div className={s.dot} />
        <div className={s.dot} />
        <div className={s.dot} />
      </div>

      <div className={cn(s.okkoCircle)} />
    </div>
  ),
);

ShakespeareLoader.displayName = 'ShakespeareLoader';
