import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { VideoObjectSchema } from 'common/components/schemaOrg/VideoObject';

import { checkIsEagleplatform } from 'common/utils/checkIsEagleplatform';
import { checkIframeInText } from 'common/utils/searchIframe';

import { VideoPlayer } from '../VideoPlayer';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.puid6,
    (state: IAppState) => state.runtime.puid15,
    (state: IAppState) => state.runtime.puid18,
    (state: IAppState) => state.runtime.puid48,
    (state: IAppState) => state.runtime.ruid,
  ],
  (
    isMobile,
    puid6,
    puid15,
    puid18,
    puid48,
    ruid,
    // eslint-disable-next-line max-params
  ) => ({
    isMobile,
    puid6,
    puid15,
    puid18,
    puid48,
    ruid,
  }),
);

interface PropsType {
  cluster: ATCluster;
  styles?: StylesType;
}

/**
 * Главное видео кластера с разметкой schema.org
 *
 * @param cluster - объект данных кластера
 */
export const ClusterVideo: React.FC<PropsType> = React.memo(({ cluster }) => {
  const { isMobile, puid6, puid15, puid18, puid48, ruid } =
    useSelector(selectData);
  const { main_video: mainVideo } = cluster;

  if (!mainVideo) return null;

  const embedCode = mainVideo.embed_code;

  const isEagleplatformVideo =
    checkIframeInText(embedCode) && checkIsEagleplatform(embedCode);

  return (
    <div
      className={cn(
        s.videoWrapper,
        isMobile ? s.videoWrapperMobile : s.videoWrapperDesktop,
      )}
    >
      <ClusterImageObject cluster={cluster} />
      <VideoObjectSchema cluster={cluster} />
      {isEagleplatformVideo ? (
        <VideoPlayer
          puids={{
            puid6,
            puid15,
            puid18,
            puid48,
          }}
          embedCode={embedCode}
          playerId={`cluster-${cluster.id}`}
          isBroadcast={!mainVideo.details.record_id}
          ruid={ruid}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
        // TODO(HORO-0): OR ?!
        // <div
        //   className={cn(styles.player, { [styles['player--loading']]: !embedCode })}
        //   suppressHydrationWarning
        //   dangerouslySetInnerHTML={{ __html: embedCode }} // eslint-disable-line react/no-danger
        // />
      )}
    </div>
  );
});

ClusterVideo.displayName = 'ClusterVideo';
