import React, { Fragment, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';
import cn from 'classnames';

import { format } from 'date-fns';
import dateAdd from 'date-fns/add';

import Button from '@rambler-components/button';
import Select from '@rambler-components/select';

import InputDate from 'common/components/InputDate';

import { YANDEX_METRICS } from 'config/constants/counters';
import { GENDER } from 'config/constants/gender';
import { SIGN } from 'config/constants/sign';

import { sendAccountData } from 'common/redux/account';

import { getSignByDate } from 'common/utils/signByDate';

import { YandexEvent } from 'utils/counters/YandexEvent';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100, Size } from './utils';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.account,
  ],
  (isMobile, account) => ({
    isMobile,
    account,
  }),
);

interface ICardFormProps {
  form: Extract<
    ICardProps['form'],
    | 'user_date_of_birth'
    | 'user_date_gender'
    | 'horoscope_date_common'
    | 'sign_for_date'
    | 'moon_by_date'
    | 'moon_day_dropdown'
    | 'sun_by_date'
    | 'personal_taro_today'
  >;
  link?: ICardProps['link'];
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function UserForm({ form, link }: ICardFormProps) {
  const { top100Prefix } = useTop100Context();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAccountSignChanged = useRef(false);
  const isAccountSignAndGenderChanged = useRef(false);
  const { isMobile, account } = useSelector(selectData);

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [dateValue, setDateValue] = useState('');
  const [genderValue, setGenderValue] = useState<GENDER>();
  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    setIsButtonClicked(false);
  }, [dateValue, genderValue, selectValue]);

  useEffect(() => {
    if (
      (form === 'sign_for_date' || form === 'personal_taro_today') &&
      account.birthday
    ) {
      setDateValue(account.birthday);
    }

    if (isAccountSignChanged.current && dateValue && account.sign && link) {
      isAccountSignChanged.current = false;

      const signs = Object.keys(SIGN).join('|');
      // eslint-disable-next-line security/detect-non-literal-regexp
      const re = new RegExp(`(${signs})`, 'g');

      if (re.test(link.link)) {
        history.push(link.link.replace(re, account.sign));
      } else {
        history.push(`/${account.sign}/`);
      }
    }

    if (
      isAccountSignAndGenderChanged.current &&
      dateValue &&
      genderValue &&
      account.sign
    ) {
      isAccountSignAndGenderChanged.current = false;

      history.push(
        `/${account.sign}/${genderValue === GENDER.female ? 'woman' : 'man'}/`,
      );
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateValueForTop100Tail = dateValue ? `::/${dateValue}/` : '';
  const signByDateTop100Tail = getSignByDate(dateValue)
    ? `/${getSignByDate(dateValue)}`
    : '';

  const checkUserSign = () => {
    if (dateValue) {
      dispatch(
        sendAccountData({
          ...account,
          birthday: dateValue,
        }),
      );

      // Отправляем только если ранее не было даты рождения
      if (!account.birthday) {
        new YandexEvent(YANDEX_METRICS.COMMON).send({
          type: 'reachGoal',
          data: 'lenta_birthday_input',
        });
      }

      isAccountSignChanged.current = true;
    }
  };

  const checkUserSignAndGender = () => {
    if (dateValue && genderValue) {
      dispatch(
        sendAccountData({
          ...account,
          birthday: dateValue,
          gender: genderValue,
        }),
      );

      // Отправляем только если ранее не было даты рождения
      if (!account.birthday) {
        new YandexEvent(YANDEX_METRICS.COMMON).send({
          type: 'reachGoal',
          data: 'lenta_birthday_input',
        });
      }

      isAccountSignAndGenderChanged.current = true;
    }
  };

  const formInput = ({
    placeholder,
    top100Tail,
    value,
    status,
    onChange,
    bottomBound,
    topBound,
    calendarInitDate,
  }: {
    placeholder: string;
    top100Tail: string;
    value?: string;
    status?: 'error' | 'warning' | 'success';
    onChange?: any;
    bottomBound?: string;
    topBound?: string;
    calendarInitDate?: string;
  }) => (
    <InputDate
      className={cn(s.input, isMobile && s.inputMobile)}
      placeholder={placeholder}
      isMobile={isMobile}
      type="border"
      value={value}
      status={status}
      onChange={onChange}
      min={bottomBound}
      max={topBound}
      calendarInitDate={calendarInitDate}
      {...getTop100({
        isMobile,
        top100Prefix,
        form,
        tail: top100Tail || placeholder,
      })}
    />
  );

  const formSelect = ({
    values,
    placeholder,
    size = 'normal',
    status,
    value,
    onChange,
  }: {
    values: {
      label: string;
      value: string;
    }[];
    placeholder: string;
    size: Size;
    status?: 'success' | 'warning' | 'error';
    value?: any;
    onChange?: any;
  }) => (
    <Select
      className={cn(s.select, getSizeStyle(isMobile, size))}
      placeholder={placeholder}
      value={value || null}
      zIndex={200}
      type="border"
      status={status}
      onChange={onChange || null}
      options={values}
    />
  );

  const formButton = ({
    text,
    textMobile,
    onClick,
    top100Tail,
  }: {
    text: string;
    textMobile: string;
    onClick?: any;
    top100Tail?: string;
  }) => (
    <Button
      className={cn(s.button, isMobile && s.buttonMobile)}
      onClick={onClick || null}
      {...getTop100({
        isMobile,
        top100Prefix,
        form,
        tail: top100Tail || 'button',
      })}
    >
      {isMobile ? textMobile : text}
    </Button>
  );

  const formTypes: Record<ICardFormProps['form'], JSX.Element[]> = {
    user_date_of_birth: [
      formInput({
        placeholder: 'Дата рождения',
        top100Tail: 'birth_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
      }),
      formButton({
        text: 'Показать для знака',
        textMobile: 'Показать',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            checkUserSign();
          }
        },
      }),
    ],
    user_date_gender: [
      formInput({
        placeholder: 'Дата рождения',
        top100Tail: 'birth_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
      }),
      formSelect({
        values: [
          {
            label: 'Мужской',
            value: GENDER.male,
          },
          {
            label: 'Женский',
            value: GENDER.female,
          },
        ],
        placeholder: 'Ваш пол',
        size: 'normal',
        status: isButtonClicked && !genderValue ? 'error' : undefined,
        value: genderValue,
        onChange: setGenderValue,
      }),
      formButton({
        text: 'Показать',
        textMobile: 'Показать',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue && genderValue) {
            checkUserSignAndGender();
          }
        },
      }),
    ],
    horoscope_date_common: [
      formInput({
        placeholder: 'Дата',
        top100Tail: 'date_pick',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
        bottomBound: '2003-08-01',
        topBound: format(dateAdd(new Date(), { days: 1 }), 'yyyy-MM-dd'),
      }),
      formButton({
        text: 'Показать',
        textMobile: 'Показать',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            history.push(`/${dateValue}/`);
          }

          checkUserSignAndGender();
        },
        top100Tail: `result_button${dateValueForTop100Tail}`,
      }),
    ],
    sign_for_date: [
      formInput({
        placeholder: 'Дата рождения',
        top100Tail: 'birth_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
        topBound: format(new Date(), 'yyyy-MM-dd'),
      }),
      formButton({
        text: 'Показать знак',
        textMobile: 'Показать знак',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            history.push(`/${getSignByDate(dateValue)}/description/`);
          }

          checkUserSignAndGender();
        },
        top100Tail: `${signByDateTop100Tail}/description/`,
      }),
    ],
    moon_by_date: [
      formInput({
        placeholder: 'Дата',
        top100Tail: 'moon_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
        bottomBound: '2007-01-02',
        topBound: `${new Date().getFullYear()}-12-31`,
        calendarInitDate: format(new Date(), 'yyyy-MM-dd'),
      }),
      formButton({
        text: 'Показать прогноз',
        textMobile: 'Показать прогноз',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');

            history.push(`/moon/calendar/${date}/`);
          }
        },
      }),
    ],
    moon_day_dropdown: [
      formSelect({
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        values: [...Array(30)].map((_, index) => {
          const day = index + 1;

          return {
            label: `${day} лунный день`,
            value: `${day}`,
          };
        }),
        placeholder: 'Выберите лунный день',
        size: 'normal',
        status: isButtonClicked && !selectValue ? 'error' : undefined,
        value: selectValue,
        onChange: setSelectValue,
      }),
      formButton({
        text: 'Показать для дня',
        textMobile: 'Показать для дня',
        onClick: () => {
          setIsButtonClicked(true);

          if (selectValue) {
            history.push(`/moon/day/${selectValue}/`);
          }
        },
      }),
    ],
    sun_by_date: [
      formInput({
        placeholder: 'Дата',
        top100Tail: 'sun_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
        bottomBound: '2007-01-02',
        topBound: `${new Date().getFullYear()}-12-31`,
        calendarInitDate: format(new Date(), 'yyyy-MM-dd'),
      }),
      formButton({
        text: 'Показать прогноз',
        textMobile: 'Показать прогноз',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');

            history.push(`/sun/calendar/${date}/`);
          }
        },
      }),
    ],
    personal_taro_today: [
      formInput({
        placeholder: 'Дата рождения',
        top100Tail: 'birth_date',
        value: dateValue,
        status: isButtonClicked && !dateValue ? 'error' : undefined,
        onChange: setDateValue,
      }),
      formButton({
        text: 'Рассчитать',
        textMobile: 'Рассчитать',
        onClick: () => {
          setIsButtonClicked(true);

          if (dateValue) {
            const today = format(new Date(), 'yyyy-MM-dd');
            const date = dateValue || format(new Date(), 'yyyy-MM-dd');

            history.push(`/personal/${today}/${date}/`);
          }
        },
      }),
    ],
  };

  if (!formTypes[form]) return null;

  return (
    <>
      {formTypes[form].map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return <Fragment key={key}>{item}</Fragment>;
      })}
    </>
  );
}

UserForm.defaultProps = {
  link: undefined,
};

export { UserForm };
