/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { isInHorizontalViewport } from 'common/utils/isInViewport';

import s from './styles.css';

interface ICardNamesFatherNamesProps {
  fatherNames: NonNullable<ICardProps['tags_with_fathernames']>;
  isMobile: boolean;
  className?: string;
}

function NamesFatherNames({
  fatherNames,
  isMobile,
  className = '',
}: ICardNamesFatherNamesProps) {
  const history = useHistory();
  const tabsList = useRef<string[]>([]);
  const fatherNamesRef = useRef(
    fatherNames.map((fathername) => {
      if (fathername.title) {
        tabsList.current.push(fathername.title);
      }

      return fathername;
    }),
  );
  const [arrowsState, setArrowsState] = useState({
    left: false,
    right: fatherNames.length > 18,
  });

  const [currentTab, setCurrentTab] = useState(fatherNamesRef.current[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const navListRef = useRef<HTMLDivElement>(null);

  const onClickButton = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault();

      history.push(currentTab.link);
    },
    [currentTab.link, history],
  );

  const moveNav = useCallback(
    (newArrowsState, index = 0, withSmooth = false) => {
      const scrollWrapper = navListRef.current;

      if (scrollWrapper) {
        const { needScrollToViewLeft } = isInHorizontalViewport(
          scrollWrapper,
          // TODO(HORO-0): fix
          // @ts-expect-error: Argument of type 'ChildNode' is not assignable to parameter of type 'HTMLElement'
          scrollWrapper.childNodes[index],
        );

        const newLeft = needScrollToViewLeft - scrollWrapper.offsetLeft;

        scrollWrapper.scrollTo({
          left: newLeft,
          behavior: withSmooth ? 'smooth' : 'auto',
        });

        const isEndOfScroll =
          scrollWrapper.scrollWidth <= newLeft + scrollWrapper.offsetWidth;

        if (isEndOfScroll) {
          setArrowsState({
            ...newArrowsState,
            right: false,
          });
        } else {
          setArrowsState(newArrowsState);
        }
      }
    },
    [],
  );

  const handleClick = useCallback(
    (shift: number) => {
      let newIndex = 0;
      const nextIndex = activeIndex + shift;
      const maxIndex = tabsList.current.length - 1;

      if (shift < 0) {
        newIndex = nextIndex <= 0 ? 0 : nextIndex;
      } else {
        newIndex = maxIndex > nextIndex ? nextIndex : maxIndex;
      }

      const nextArrowsState =
        fatherNames.length > 18
          ? {
              left: newIndex !== 0,
              right: ![newIndex, newIndex + 1].includes(maxIndex),
            }
          : {
              left: false,
              right: false,
            };

      setActiveIndex(newIndex);
      moveNav(nextArrowsState, newIndex, true);
    },
    [activeIndex, fatherNames.length, moveNav],
  );

  if (!fatherNames) return null;

  // TODO(HORO-0): у ListBubbles, NamesByParams и NamesFatherNames много общего. Переработать

  return (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      {tabsList.current.length > 0 ? (
        <div className={s.nav}>
          {!isMobile && (
            <Icon
              className={cn(
                s.icon,
                s.iconLeft,
                !arrowsState.left && s.iconDisabled,
              )}
              id="arrow-next"
              onClick={() => arrowsState.left && handleClick(-2)}
            />
          )}
          <div
            className={cn(
              s.navList,
              isMobile ? s.navListMobile : s.navListDesktop,
            )}
            ref={navListRef}
          >
            {tabsList.current.map((tab, index) => (
              <Typography
                variant="defaultBold"
                className={cn(s.tab, currentTab.title === tab && s.tabActive)}
                element={
                  <span
                    onClick={() => {
                      if (currentTab.title !== tab) {
                        setCurrentTab(fatherNamesRef.current[index]);
                        setActiveIndex(index);
                        handleClick(index - activeIndex);
                      }
                    }}
                  />
                }
                key={`ListBubbles-tag-${tab}`}
              >
                {tab}
              </Typography>
            ))}
          </div>
          {!isMobile && (
            <Icon
              className={cn(
                s.icon,
                s.iconRight,
                !arrowsState.right && s.iconDisabled,
              )}
              id="arrow-next"
              onClick={() => arrowsState.right && handleClick(2)}
            />
          )}
        </div>
      ) : null}
      <div className={cn(s.list, isMobile ? s.listMobile : s.listDesktop)}>
        <div className={s.head}>
          <div className={s.left}>
            <Typography variant="defaultMedium" className={s.headFathername}>
              Отчество
            </Typography>
          </div>
          <div className={s.right}>
            <Typography variant="defaultMedium" className={s.headNames}>
              Имена
            </Typography>
          </div>
        </div>
        {currentTab.fathernames.map((fathername, cIndex) => {
          const chunkIndex = `ListBubbles-${currentTab.title}-${cIndex}`;

          if (!fathername.fathername || !fathername.link || !fathername.names)
            return null;

          return (
            <Link
              className={cn(s.line, isMobile ? s.lineMobile : s.lineDesktop)}
              to={fathername.link}
              key={chunkIndex}
            >
              <div className={s.left}>
                <Typography variant="defaultBold" className={s.fathername}>
                  {fathername.fathername}
                </Typography>
              </div>
              <div className={s.right}>
                <Typography variant="defaultMedium" className={s.names}>
                  {isMobile ? (
                    <span className={s.namesTitle}>Имена</span>
                  ) : null}
                  {fathername.names}
                </Typography>
              </div>
            </Link>
          );
        })}
      </div>
      {currentTab.link && (
        <Button
          href={currentTab.link}
          className={cn(s.more, isMobile ? s.moreMobile : s.moreDesktop)}
          onClick={(e) => onClickButton(e)}
        >
          Смотреть всю подборку
        </Button>
      )}
    </div>
  );
}

NamesFatherNames.defaultProps = {
  className: '',
};

export { NamesFatherNames };
