import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import { Icon } from 'common/components/Icon';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { Typography } from 'common/components/Typography';

import { MONTHS, MONTHS_ENG } from 'config/constants/calendar';
import { getYearAndMonth } from 'common/utils/getCalendar';

import s from './styles.css';

const MONTH_NUMBER = 12;
const MONTH_INDEXES = Array.from(Array(MONTH_NUMBER).keys());

const MONTH_WIDTHS = [
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  368, 379, 351, 368, 344, 355, 354, 362, 384, 375, 368, 376,
];
const SHIFT_OFFSET = 5;
const MONTH_MARGIN = 20;

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) =>
      state.pages[state.runtime.currentPage][state.runtime.currentSection]
        .content.calendar,
  ],
  (isMobile, calendar) => ({
    isMobile,
    calendar,
  }),
);

interface IHairScroll {
  className?: string;
}

function HairScroll({ className }: IHairScroll) {
  const { top100Prefix } = useTop100Context();
  const { isMobile, calendar } = useSelector(selectData);
  const scrollNode = useRef<HTMLDivElement>(null);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const min = calendar && calendar.min ? calendar.min : `${currentYear}-01-01`;
  const max = calendar && calendar.max ? calendar.max : `${currentYear}-12-31`;
  const minMonth = new Date(min).getMonth();

  const monthIndexes = [
    ...MONTH_INDEXES.slice(minMonth),
    ...MONTH_INDEXES.slice(0, minMonth),
  ];
  const monthShifts = useMemo(() => {
    const monthWidths = [
      ...MONTH_WIDTHS.slice(minMonth),
      ...MONTH_WIDTHS.slice(0, minMonth),
    ];

    return monthWidths.reduce((prev: number[], curr, currInd) => {
      const prevValue = prev[prev.length - 1] || 0;
      const currValue = monthWidths[currInd - 1] || 0;

      prev.push(prevValue + currValue + (currInd !== 0 ? MONTH_MARGIN : 0));

      return prev;
    }, []);
  }, [minMonth]);
  const [currentShift, setCurrentShift] = useState(
    monthShifts[monthIndexes.indexOf(currentMonth)],
  );

  useEffect(() => {
    if (!scrollNode.current) return;

    scrollNode.current.scrollLeft = currentShift;
  }, [currentShift]);

  const getTop100 = (tail: string) =>
    getTop100Markup(isMobile, top100Prefix, `block::links::${tail}`);

  return (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      {!isMobile && (
        <button
          className={s.button}
          type="button"
          aria-label="Предыдущий месяц"
          {...getTop100('button::prev')}
          onClick={() => {
            const scrollLeft = scrollNode.current
              ? scrollNode.current.scrollLeft
              : 0;
            const newShift = monthShifts.find(
              (shift, index) =>
                shift < scrollLeft && monthShifts[index + 1] >= scrollLeft,
            );

            setCurrentShift(newShift || 0);
          }}
        >
          <Icon id="arrow-next" className={cn(s.icon, s.iconPrev)} />
        </button>
      )}
      <div className={s.links} ref={scrollNode}>
        {monthIndexes.map((monthIndex) => (
          <Typography
            variant="defaultSemiBold"
            key={MONTHS[monthIndex]}
            className={cn(s.link, monthIndex === currentMonth && s.linkActive)}
            element={
              <Link
                to={`/hair/${MONTHS_ENG[monthIndex]}/`}
                {...getTop100('link')}
              />
            }
          >
            Лунный календарь стрижек на {MONTHS[monthIndex]}{' '}
            <span className={s.year}>
              {getYearAndMonth(min, max, MONTHS_ENG[monthIndex]).year}
            </span>{' '}
            года
          </Typography>
        ))}
      </div>
      {!isMobile && (
        <button
          className={s.button}
          type="button"
          aria-label="Следующий месяц"
          {...getTop100('button::next')}
          onClick={() => {
            const scrollLeft = scrollNode.current
              ? scrollNode.current.scrollLeft
              : 0;
            const newShift = monthShifts.find(
              (shift) => shift > scrollLeft + SHIFT_OFFSET,
            );

            setCurrentShift(newShift || monthShifts[monthShifts.length - 1]);
          }}
        >
          <Icon id="arrow-next" className={s.icon} />
        </button>
      )}
    </div>
  );
}

HairScroll.defaultProps = {
  className: '',
};

export { HairScroll };
