export type ChineseCompatibilityDataType = Record<
  GENDER_CLASSIC,
  Record<SIGN_CHINESE, Record<SIGN_CHINESE, number>>
>;

export const chineseCompatibilityData: ChineseCompatibilityDataType = {
  man: {
    rat: {
      rat: 89,
      ox: 100,
      tiger: 65,
      rabbit: 89,
      dragon: 90,
      snake: 70,
      horse: 60,
      sheep: 78,
      monkey: 87,
      rooster: 87,
      dog: 85,
      pig: 100,
    },
    ox: {
      rat: 100,
      ox: 84,
      tiger: 90,
      rabbit: 85,
      dragon: 60,
      snake: 95,
      horse: 60,
      sheep: 55,
      monkey: 80,
      rooster: 90,
      dog: 75,
      pig: 80,
    },
    tiger: {
      rat: 65,
      ox: 90,
      tiger: 85,
      rabbit: 90,
      dragon: 95,
      snake: 70,
      horse: 93,
      sheep: 90,
      monkey: 79,
      rooster: 87,
      dog: 94,
      pig: 80,
    },
    rabbit: {
      rat: 80,
      ox: 85,
      tiger: 90,
      rabbit: 87,
      dragon: 75,
      snake: 95,
      horse: 60,
      sheep: 90,
      monkey: 65,
      rooster: 55,
      dog: 90,
      pig: 93,
    },
    dragon: {
      rat: 90,
      ox: 60,
      tiger: 95,
      rabbit: 83,
      dragon: 93,
      snake: 97,
      horse: 88,
      sheep: 83,
      monkey: 95,
      rooster: 83,
      dog: 80,
      pig: 83,
    },
    sheep: {
      rat: 78,
      ox: 55,
      tiger: 70,
      rabbit: 90,
      dragon: 83,
      snake: 87,
      horse: 95,
      sheep: 79,
      monkey: 84,
      rooster: 78,
      dog: 95,
      pig: 92,
    },
    monkey: {
      rat: 87,
      ox: 80,
      tiger: 69,
      rabbit: 65,
      dragon: 95,
      snake: 77,
      horse: 82,
      sheep: 84,
      monkey: 89,
      rooster: 83,
      dog: 80,
      pig: 84,
    },
    dog: {
      rat: 85,
      ox: 75,
      tiger: 94,
      rabbit: 80,
      dragon: 80,
      snake: 93,
      horse: 92,
      sheep: 95,
      monkey: 72,
      rooster: 75,
      dog: 81,
      pig: 89,
    },
    snake: {
      rat: 75,
      ox: 95,
      tiger: 87,
      rabbit: 89,
      dragon: 97,
      snake: 98,
      horse: 58,
      sheep: 87,
      monkey: 77,
      rooster: 85,
      dog: 98,
      pig: 55,
    },
    horse: {
      rat: 65,
      ox: 60,
      tiger: 93,
      rabbit: 79,
      dragon: 88,
      snake: 58,
      horse: 96,
      sheep: 95,
      monkey: 82,
      rooster: 79,
      dog: 92,
      pig: 96,
    },
    rooster: {
      rat: 90,
      ox: 90,
      tiger: 87,
      rabbit: 55,
      dragon: 83,
      snake: 90,
      horse: 79,
      sheep: 78,
      monkey: 74,
      rooster: 83,
      dog: 75,
      pig: 84,
    },
    pig: {
      rat: 100,
      ox: 80,
      tiger: 80,
      rabbit: 93,
      dragon: 83,
      snake: 55,
      horse: 96,
      sheep: 92,
      monkey: 84,
      rooster: 84,
      dog: 89,
      pig: 98,
    },
  },
  woman: {
    rat: {
      rat: 89,
      ox: 100,
      tiger: 65,
      rabbit: 80,
      dragon: 90,
      snake: 75,
      horse: 65,
      sheep: 78,
      monkey: 87,
      rooster: 90,
      dog: 85,
      pig: 100,
    },
    ox: {
      rat: 100,
      ox: 84,
      tiger: 90,
      rabbit: 85,
      dragon: 60,
      snake: 95,
      horse: 60,
      sheep: 55,
      monkey: 80,
      rooster: 90,
      dog: 75,
      pig: 80,
    },
    tiger: {
      rat: 65,
      ox: 90,
      tiger: 85,
      rabbit: 90,
      dragon: 95,
      snake: 87,
      horse: 93,
      sheep: 70,
      monkey: 69,
      rooster: 87,
      dog: 94,
      pig: 80,
    },
    rabbit: {
      rat: 89,
      ox: 85,
      tiger: 90,
      rabbit: 87,
      dragon: 83,
      snake: 89,
      horse: 79,
      sheep: 90,
      monkey: 65,
      rooster: 55,
      dog: 80,
      pig: 93,
    },
    dragon: {
      rat: 90,
      ox: 60,
      tiger: 95,
      rabbit: 75,
      dragon: 93,
      snake: 97,
      horse: 88,
      sheep: 83,
      monkey: 95,
      rooster: 83,
      dog: 80,
      pig: 83,
    },
    sheep: {
      rat: 78,
      ox: 55,
      tiger: 90,
      rabbit: 90,
      dragon: 83,
      snake: 87,
      horse: 95,
      sheep: 79,
      monkey: 84,
      rooster: 78,
      dog: 95,
      pig: 92,
    },
    monkey: {
      rat: 87,
      ox: 80,
      tiger: 79,
      rabbit: 65,
      dragon: 95,
      snake: 77,
      horse: 82,
      sheep: 84,
      monkey: 89,
      rooster: 74,
      dog: 72,
      pig: 84,
    },
    dog: {
      rat: 85,
      ox: 75,
      tiger: 94,
      rabbit: 90,
      dragon: 80,
      snake: 98,
      horse: 92,
      sheep: 95,
      monkey: 80,
      rooster: 75,
      dog: 81,
      pig: 89,
    },
    snake: {
      rat: 70,
      ox: 95,
      tiger: 70,
      rabbit: 95,
      dragon: 97,
      snake: 98,
      horse: 58,
      sheep: 87,
      monkey: 77,
      rooster: 90,
      dog: 93,
      pig: 55,
    },
    horse: {
      rat: 60,
      ox: 60,
      tiger: 93,
      rabbit: 60,
      dragon: 88,
      snake: 58,
      horse: 96,
      sheep: 95,
      monkey: 82,
      rooster: 79,
      dog: 92,
      pig: 96,
    },
    rooster: {
      rat: 87,
      ox: 90,
      tiger: 87,
      rabbit: 55,
      dragon: 83,
      snake: 85,
      horse: 79,
      sheep: 78,
      monkey: 83,
      rooster: 83,
      dog: 75,
      pig: 84,
    },
    pig: {
      rat: 100,
      ox: 80,
      tiger: 80,
      rabbit: 93,
      dragon: 83,
      snake: 55,
      horse: 96,
      sheep: 92,
      monkey: 84,
      rooster: 84,
      dog: 89,
      pig: 98,
    },
  },
};
