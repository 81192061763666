/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useSwipeable } from 'react-swipeable';
import cn from 'classnames';
import _chunk from 'lodash.chunk';

import format from 'date-fns/format';
import dateAdd from 'date-fns/add';
import dateSub from 'date-fns/sub';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isMonday from 'date-fns/isMonday';
import previousMonday from 'date-fns/previousMonday';
import differenceInDays from 'date-fns/differenceInDays';

import { Link } from 'react-router-dom';

import RamblerCalendar from '@rambler-components/calendar';
import { Tabs, Tab } from '@rambler-components/tabs';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import {
  getDateRange,
  getDateFromUrl,
  getInitDate,
} from 'common/utils/getCalendar';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import { getCalendarData } from 'common/redux/calendar';

import {
  CALENDAR_LEGENDS,
  MONTHS,
  WEEKDAYS_ABBR,
  DAYS_IN_WEEK,
  MIN_YEAR,
  MAX_YEAR,
  DATE_FORMAT,
  CALENDAR_TABS,
  MONTHS_ENG,
} from 'config/constants/calendar';

import { CalendarContext } from '../CalendarContext';

import s from './index.css';

const LINE_HEIGHT = 70;
const MIN_BODY_HEIGHT = 100;
const WEEKDAYS_HEIGHT = 30;

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentParams.url,
    (state: IAppState) => state.calendar,
  ],
  (url, calendarData) => ({
    url,
    calendarData,
  }),
);

interface ICalendarProps {
  isMobile: boolean;
  className?: string;
  calendar: ICalendar;
}

interface CalendarDataType {
  date: Date;
  name: string;
  slug: string;
  counts: any;
  isActive: boolean;
  isCurrent: boolean;
  isToday: boolean;
  disabled: boolean;
}

/**
 * Компонент календаря
 * @param isMobile - флаг мобильной версии
 * @param className - дополнительный класс
 * @param calendar - данные календаря из ручки
 * @returns отрендеренный компонент месяцев с выпадающим календарем
 */
function Calendar({ isMobile, className, calendar }: ICalendarProps) {
  const { top100Prefix } = useTop100Context();
  const dispatch = useDispatch();
  const { url, calendarData } = useSelector(selectData);
  const [calendarViewOpened, setCalendarViewOpened] =
    useContext(CalendarContext);
  const calendarType = calendar.type;
  const isHair = calendarType === 'hair';
  const isNames = calendarType === 'names';
  const calendarUrl = isNames
    ? `/${calendarType}/calendar/`
    : `/${calendarType}/`;

  const min = calendar.min || `${MIN_YEAR}-01-01`;
  const max = calendar.max || `${MAX_YEAR}-12-31`;
  const minDate = useRef(new Date(`${min} 00:00:00`));
  const maxDate = useRef(new Date(`${max} 00:00:00`));

  // сегодняшняя дата
  const todayDate = new Date();
  // дата из урла (/names/calendar/2020-01-01/ или /hair/february/1/)
  const currentDate = getDateFromUrl(min, max, url) || todayDate;
  // дата для инициализации календаря
  const initDate = useRef(
    getInitDate({
      min,
      max,
      today: todayDate,
      current: currentDate,
      month: url,
    }),
  );
  const [currentYear, setCurrentYear] = useState(
    initDate.current.getFullYear(),
  );
  const [currentMonth, setCurrentMonth] = useState(initDate.current.getMonth());
  const [currentTab, setCurrentTab] = useState(isHair ? 'haircut' : undefined);

  const [calendarOpened, setCalendarOpened] = useState(false);

  const buttonContainerNode = useRef<HTMLDivElement>(null);
  const bodyContainerNode = useRef<HTMLDivElement>(null);
  const scrollNode = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);

  useOnClickOutside(
    buttonContainerNode,
    () => setCalendarOpened(false),
    calendarOpened,
  );

  /**
   * Месяцы для календаря
   * @returns 3 месяца для десктопа либо 1 месяц для мобилы
   */
  const getMonths = (year: number, month: number) => {
    const firstDate = new Date(year, month, 1);
    const prevMonthDate = dateSub(firstDate, { months: 1 });
    const prevMonth = prevMonthDate.getMonth();
    const prevYear = prevMonthDate.getFullYear();
    const nextMonthDate = dateAdd(firstDate, { months: 1 });
    const nextMonth = nextMonthDate.getMonth();
    const nextYear = nextMonthDate.getFullYear();

    const months = [
      {
        name: MONTHS[month],
        index: month,
        year,
        disabled: false,
      },
    ];

    if (!isMobile) {
      months.unshift({
        name: MONTHS[prevMonth],
        index: prevMonth,
        year: prevYear,
        disabled: isBefore(new Date(prevYear, prevMonth, 1), minDate.current),
      });
      months.push({
        name: MONTHS[nextMonth],
        index: nextMonth,
        year: nextYear,
        disabled: isAfter(new Date(nextYear, nextMonth, 1), maxDate.current),
      });
    }

    return months;
  };

  /**
   * Ближайший понедельник к дате
   * @returns ближайший предыдущий понедельник или сегодняшний день, если он пн
   */
  const getMonday = (newDate: Date) =>
    !isMonday(newDate) ? previousMonday(newDate) : newDate;

  /**
   * Получение данных для отображения
   * @returns массив объектов с данными по дням
   */
  const getData = ({ start, end }: { start: Date; end: Date }) => {
    if (!calendarData) return [];

    const firstDate = new Date(currentYear, currentMonth, 1);
    const data: CalendarDataType[] = [];

    let date = start;

    while (!isSameDay(date, end)) {
      data.push({
        date,
        name: format(date, 'd'),
        slug: isNames
          ? format(date, DATE_FORMAT)
          : `${format(date, 'MMMM').toLowerCase()}/${format(date, 'd')}`,
        counts: calendarData[format(date, DATE_FORMAT)],
        isActive: isSameMonth(firstDate, date),
        isCurrent: isSameDay(currentDate || 0, date),
        isToday: isSameDay(todayDate, date),
        disabled:
          isBefore(date, minDate.current) || isAfter(date, maxDate.current),
      });
      date = dateAdd(date, { days: 1 });
    }

    return data;
  };

  const onDayClick = useCallback((date: Date) => {
    let newDate = format(date, DATE_FORMAT);

    if (isHair) {
      newDate = `${MONTHS_ENG[date.getMonth()]}/${date.getDate()}`;
    }

    window.location.href = `${calendarUrl}${newDate}/`;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Переход на определенную дату
   * @param date - дата
   */
  const goToDate = useCallback(
    (date: Date) => {
      if (isBefore(date, minDate.current) || isAfter(date, maxDate.current)) {
        return;
      }

      onDayClick(date);
    },
    [onDayClick],
  );

  const months = useMemo(
    () => getMonths(currentYear, currentMonth),
    [currentYear, currentMonth], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const dateRange = useMemo(
    () => getDateRange(currentYear, currentMonth),
    [currentYear, currentMonth],
  );
  const table = getData(dateRange);
  const scrollShift = useMemo(
    () => {
      const monday = getMonday(
        initDate.current.getMonth() === currentDate?.getMonth()
          ? currentDate
          : initDate.current,
      );

      return differenceInDays(dateRange.start, monday);
    },
    [currentDate, initDate.current], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!bodyContainerNode.current || !scrollNode.current) return;

    const scrollLeft =
      scrollShift * (bodyContainerNode.current.clientWidth / 7);

    scrollNode.current.scrollLeft = -scrollLeft;
  }, [scrollShift, calendarViewOpened]);

  /**
   * Анимация календаря
   * @param animations - массив объектов анимации
   */
  const animateBody = (animations: Keyframe[]) => {
    if (!bodyContainerNode.current) return;

    bodyContainerNode.current.animate(animations, {
      duration: 400,
      iterations: 1,
      fill: 'forwards',
      easing: 'cubic-bezier(0.4, 0, 0.3, 1)',
    });
  };

  /**
   * Анимация высоты календаря
   * @param isOpening - флаг открытия/закрытия подробного календаря для мобилы
   */
  const animateHeight = useCallback(
    (isOnClick: boolean, isOpening: boolean) => {
      const maxBodyHeight =
        (table.length / DAYS_IN_WEEK) * LINE_HEIGHT + WEEKDAYS_HEIGHT;
      const animations: Keyframe[] = [];

      if (isOnClick) {
        animations.push(
          { height: isOpening ? `${MIN_BODY_HEIGHT}px` : `${maxBodyHeight}px` },
          { height: isOpening ? `${maxBodyHeight}px` : `${MIN_BODY_HEIGHT}px` },
        );
      } else {
        animations.push({ height: `${maxBodyHeight}px` });
      }

      animateBody(animations);
    },
    [table],
  );

  /**
   * Получить день недели, начиная с понедельника
   * @param date - дата
   * @returns аббревиатура дня недели на русском языке
   */
  const getWeekday = (date: Date) => {
    const curretnWeekday = date.getDay();

    return WEEKDAYS_ABBR[curretnWeekday === 0 ? 6 : curretnWeekday - 1];
  };

  /**
   * Показать предыдущий месяц
   */
  const showPrev = () => {
    const firstDate = new Date(currentYear, currentMonth, 1);
    const prevDate = dateSub(firstDate, { months: 1 });
    const prevMonth = prevDate.getMonth();
    const prevYear = prevDate.getFullYear();

    if (isBefore(new Date(prevYear, prevMonth, 1), minDate.current)) return;

    setCurrentMonth(prevMonth);
    setCurrentYear(prevYear);
  };

  /**
   * Показать следующий месяц
   */
  const showNext = () => {
    const firstDate = new Date(currentYear, currentMonth, 1);
    const nextDate = dateAdd(firstDate, { months: 1 });
    const nextMonth = nextDate.getMonth();
    const nextYear = nextDate.getFullYear();

    if (isAfter(new Date(nextYear, nextMonth, 1), maxDate.current)) return;

    setCurrentMonth(nextMonth);
    setCurrentYear(nextYear);
  };

  /**
   * Обработка свайпа по открытому виду календаря (месяцы)
   */
  const swipeMonthHandlers = useSwipeable({
    onSwipedLeft: showNext,
    onSwipedRight: showPrev,
    trackMouse: true,
    trackTouch: true,
  });

  /**
   * Рендеринг количества именин
   * @param item - текущее событие
   * @param isVisible - видно ли оно всегда
   * @returns отренедеренный див со списком количества именин
   */
  const renderCellCounts = (item: CalendarDataType, isVisible = false) => (
    <div className={cn(s.cellCounts, isMobile && s.cellCountsMobile)}>
      {item.counts && item.counts[calendarType] > 0 ? (
        <Typography
          variant="smallMedium"
          key={calendarType}
          className={cn(
            s.cellCount,
            s[`cellCount${calendarType}`],
            isMobile && s.cellCountMobile,
            isMobile &&
              !isVisible &&
              calendarViewOpened &&
              s.cellCountMobileOpened,
            isMobile &&
              !isVisible &&
              !calendarViewOpened &&
              s.cellCountMobileClosed,
          )}
        >
          {item.counts[calendarType]}
        </Typography>
      ) : null}
    </div>
  );

  /**
   * Рендеринг статусов календаря стрижек
   * @param item - текущий статус
   * @param isVisible - видно ли оно всегда
   * @returns отренедеренный див со списком статусов календаря стрижек
   */
  const renderCellStatuses = (item: CalendarDataType, isVisible = false) => (
    <div className={cn(s.cellStatuses, isMobile && s.cellStatusesMobile)}>
      {item.counts && item.counts[calendarType] ? (
        <span
          key={calendarType}
          className={cn(
            s.cellStatus,
            s[`cellStatus${calendarType}`],
            currentTab &&
              s[
                `cellStatus-${currentTab}-${item.counts[calendarType][currentTab]}`
              ],
            item.isCurrent && s.cellStatusCurrent,
            isMobile && s.cellStatusMobile,
            isMobile &&
              !isVisible &&
              calendarViewOpened &&
              s.cellStatusMobileOpened,
            isMobile &&
              !isVisible &&
              !calendarViewOpened &&
              s.cellStatusMobileClosed,
          )}
        />
      ) : null}
    </div>
  );

  /**
   * Хук по изменению даты
   */
  useEffect(() => {
    const newDate = getDateFromUrl(min, max, url);

    initDate.current = getInitDate({
      min,
      max,
      today: todayDate,
      current: newDate,
      month: url,
    });
    isFirstRender.current = true;
    setCurrentYear(initDate.current.getFullYear());
    setCurrentMonth(initDate.current.getMonth());
    setCalendarViewOpened(false);
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Хук по изменению месяца/года
   */
  useEffect(() => {
    dispatch(getCalendarData(calendarType, dateRange.start, dateRange.end));

    if (!isFirstRender.current) {
      initDate.current = new Date(currentYear, currentMonth, 1);

      const animations: Keyframe[] = [{ opacity: 0 }, { opacity: 1 }];

      animateBody(animations);

      if (isMobile && calendarViewOpened) {
        animateHeight(false, false);
      }
    }

    isFirstRender.current = false;
  }, [currentMonth, currentYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const chunkedData = useMemo(
    () =>
      _chunk(table, DAYS_IN_WEEK).map(
        (week: CalendarDataType[], weekIndex: number) => {
          const weekKey = `week-${weekIndex}`;

          return (
            <div className={cn(s.week, isMobile && s.weekMobile)} key={weekKey}>
              {week.map((item: CalendarDataType) => {
                const Element: any = item.disabled ? 'div' : Link;

                return (
                  <Element
                    className={cn(
                      s.cell,
                      isMobile ? s.cellMobile : s.cellDesktop,
                      item.isActive && s.cellActive,
                      item.isToday && s.cellToday,
                      item.isCurrent && s.cellCurrent,
                      item.disabled && s.cellDisabled,
                      isHair &&
                        currentTab &&
                        item.counts &&
                        item.counts[calendarType] &&
                        s[`cell-${item.counts[calendarType][currentTab]}`],
                    )}
                    key={item.slug}
                    to={{
                      pathname: `${calendarUrl}${item.slug}/`,
                      state: { freeze: true, disableAdUpdate: true },
                    }}
                    {...getTop100Markup(
                      isMobile,
                      top100Prefix,
                      'calendar::date_choice',
                    )}
                  >
                    <Typography
                      variant="smallMedium"
                      component="div"
                      className={cn(
                        s.cellName,
                        isMobile && s.cellNameMobile,
                        isMobile &&
                          calendarViewOpened &&
                          s.cellNameMobileOpened,
                        isMobile &&
                          !calendarViewOpened &&
                          s.cellNameMobileClosed,
                      )}
                    >
                      <span className={s.day}>{item.name}</span>
                    </Typography>
                    {isNames && renderCellCounts(item)}
                    {isHair && renderCellStatuses(item)}
                  </Element>
                );
              })}
            </div>
          );
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, calendarUrl, calendarViewOpened, isMobile, isNames, isHair],
  );

  const monthsData = useMemo(
    () => (
      <div className={cn(s.months, isMobile && s.monthsMobile)}>
        {months.map((month, index) => (
          <Typography
            variant="defaultBold"
            key={month.index}
            className={cn(
              s.month,
              isMobile && s.monthMobile,
              currentMonth === month.index && s.monthActive,
              month.disabled && s.monthDisabled,
            )}
            element={
              <button
                type="button"
                onClick={() => {
                  if (month.disabled) return;

                  setCurrentMonth(month.index);
                  setCurrentYear(month.year);

                  if (isMobile) {
                    const newGeneralViewOpened = !calendarViewOpened;

                    setCalendarViewOpened(newGeneralViewOpened);
                    animateHeight(true, newGeneralViewOpened);
                  }
                }}
              />
            }
          >
            {!isMobile && index === 0 && (
              <Icon
                id="calendar-previous"
                className={s.icon}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'calendar::to_left',
                )}
              />
            )}
            {(isMobile || index === 1) && (
              <span
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'calendar::month_choice',
                )}
              >
                {month.name}
              </span>
            )}
            {isMobile && (
              <Icon
                id={calendarViewOpened ? 'calendar-up' : 'calendar-down'}
                className={cn(s.icon, s.iconMobile)}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'calendar::month_choice',
                )}
              />
            )}
            {!isMobile && index === months.length - 1 && (
              <Icon
                id="calendar-next"
                className={s.icon}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'calendar::to_right',
                )}
              />
            )}
          </Typography>
        ))}
      </div>
    ),
    [
      isMobile,
      months,
      currentMonth,
      top100Prefix,
      calendarViewOpened,
      setCalendarViewOpened,
      animateHeight,
    ],
  );

  const buttonData = useMemo(
    () => (
      <div ref={buttonContainerNode}>
        <button
          type="button"
          className={cn(s.buttonCalendar, isMobile && s.buttonCalendarMobile)}
          onClick={() => {
            setCalendarOpened(!calendarOpened);
          }}
        >
          <Typography
            variant="defaultBold"
            className={s.yearCalendar}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              'calendar::year_choice',
            )}
          >
            {currentYear}
          </Typography>
          <Icon
            id="calendar"
            className={s.iconCalendar}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              'calendar::calendar_icon',
            )}
          />
        </button>
        {calendarOpened && (
          <RamblerCalendar
            className={s.dropdownCalendar}
            minDate={minDate.current}
            maxDate={maxDate.current}
            initDate={initDate.current}
            showYear
            showYearSwitch
            isSelectable
            onChange={(val: Date) => goToDate(val)}
          />
        )}
      </div>
    ),
    [calendarOpened, currentYear, goToDate, isMobile, top100Prefix],
  );

  const tabsData = useMemo(() => {
    const calendarTabs = CALENDAR_TABS[calendarType];
    if (!calendarTabs) return null;

    return (
      <Tabs
        value={currentTab}
        onChange={(tab: string) => tab && setCurrentTab(tab)}
      >
        {Object.keys(calendarTabs).map((tab) => (
          <Tab key={tab} value={tab}>
            {calendarTabs[tab]}
          </Tab>
        ))}
      </Tabs>
    );
  }, [calendarType, currentTab]);

  const legendsData = useMemo(
    () => CALENDAR_LEGENDS[currentTab || calendarType],
    [calendarType, currentTab],
  );

  if (!calendar) return null;

  return (
    <div className={cn(s.root, isMobile && s.rootMobile, className)}>
      {calendar.title && (
        <Typography
          variant={isMobile ? 'h3' : 'h2'}
          component="h2"
          className={cn(s.title, isMobile && s.titleMobile)}
        >
          {calendar.title}
        </Typography>
      )}
      <div className={cn(s.calendar, isMobile && s.calendarMobile)}>
        <div className={s.top}>
          <div className={s.topLeft}>
            {isNames ? monthsData : null}
            {isHair && isMobile ? monthsData : null}
            {isHair && !isMobile ? tabsData : null}
          </div>
          <div className={s.topRight}>
            {isHair && !isMobile ? monthsData : null}
            {buttonData}
          </div>
        </div>
        {isHair && isMobile && <div className={s.topTabs}>{tabsData}</div>}
        <div
          className={cn(s.body, isMobile && s.bodyMobile)}
          ref={bodyContainerNode}
        >
          <div className={s.scrollContainer}>
            {isMobile && !calendarViewOpened && (
              <div className={s.scroll} ref={scrollNode}>
                <div className={cn(s.scrollWeekdays, s.scrollWeekdaysMobile)}>
                  {table.map((item) => (
                    <Typography
                      variant="smallMedium"
                      className={cn(s.scrollWeekday, s.scrollWeekdayMobile)}
                      key={item.slug}
                    >
                      {getWeekday(item.date)}
                    </Typography>
                  ))}
                </div>
                <div className={s.scrollDates}>
                  {table.map((item) => {
                    const Element: any = item.disabled ? 'div' : Link;

                    return (
                      <Typography
                        variant="defaultMedium"
                        key={item.slug}
                        className={cn(
                          s.scrollDate,
                          item.isActive && s.scrollDateActive,
                          item.isCurrent && s.scrollDateCurrent,
                          item.isToday && s.scrollDateToday,
                          item.disabled && s.scrollDateDisabled,
                          isHair &&
                            currentTab &&
                            item.counts &&
                            item.counts[calendarType] &&
                            s[
                              `swipeDate-${item.counts[calendarType][currentTab]}`
                            ],
                        )}
                        element={
                          <Element
                            to={{
                              pathname: `${calendarUrl}${item.slug}/`,
                              state: { freeze: true, disableAdUpdate: true },
                            }}
                            {...getTop100Markup(
                              isMobile,
                              top100Prefix,
                              'calendar::date_choice',
                            )}
                          />
                        }
                      >
                        <span className={s.scrollDateName}>{item.name}</span>
                        {isNames && renderCellCounts(item, true)}
                        {isHair && renderCellStatuses(item, true)}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div
            className={cn(
              s.table,
              isMobile && s.tableMobile,
              isMobile && calendarViewOpened && s.tableMobileOpened,
            )}
            {...swipeMonthHandlers}
          >
            {(!isMobile || calendarViewOpened) && (
              <div
                className={cn(
                  s.scrollWeekdays,
                  isMobile && s.scrollWeekdaysMobile,
                )}
              >
                {WEEKDAYS_ABBR.map((weekday) => (
                  <Typography
                    variant="smallMedium"
                    className={cn(
                      s.scrollWeekday,
                      isMobile && s.scrollWeekdayMobile,
                    )}
                    key={weekday}
                  >
                    {weekday}
                  </Typography>
                ))}
              </div>
            )}
            {chunkedData}
          </div>
        </div>
      </div>
      <div className={cn(s.bottom, isMobile && s.bottomMobile)}>
        {legendsData &&
          Object.keys(legendsData.statuses).map((status) => (
            <Typography variant="smallMedium" className={s.legend} key={status}>
              <span
                className={cn(s.legendCircle, s[`legendCircle-${status}`])}
              />
              {legendsData.statuses[status]}{' '}
              {!isMobile ? legendsData.label : ''}
            </Typography>
          ))}
      </div>
    </div>
  );
}

Calendar.defaultProps = {
  className: '',
};

export { Calendar };
