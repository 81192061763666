import React from 'react';
import cn from 'classnames';

import s from './styles.css';

const FORTUNES_WITH_LOADER = [
  'future-fortune',
  'love-fortune',
  'yes-no-fortune',
  'birth-date-fortune',
];

interface ILoaderProps {
  fortune: string;
}

/**
 * Компонент анимированного лоадера для гаданий таро.
 *
 * @see https://www.figma.com/file/6XVRX3Pg4xCQ6GHrNQuVF9/Rambler%2Fhoroscopes?type=design&node-id=3890-27812&mode=design&t=nlAXeoYUZPR3pt4w-0
 */
export const Loader = React.memo(({ fortune }: ILoaderProps) => {
  if (!FORTUNES_WITH_LOADER.includes(fortune)) {
    return null;
  }

  return (
    <div className={cn(s.root, s[`root-${fortune}`])}>
      <div className={cn(s.iconWrapper, s[`iconWrapper-${fortune}`])}>
        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        {Array.from(new Array(8)).map((_, index) => (
          <div
            key={index}
            className={cn(s.loaderIcon, s[`loaderIcon-${fortune}`])}
          />
        ))}
      </div>
    </div>
  );
});

Loader.displayName = 'Loader';
