import React from 'react';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { getLinkElemFromUrl } from 'utils/getLinkfromUrl';
import s from './styles.css';

interface ICardReadMoreProps {
  link: ICardProps['link'];
  isMobile: boolean;
  className?: string;
}

function ReadMore({ link, isMobile, className }: ICardReadMoreProps) {
  const { top100Prefix } = useTop100Context();
  if (!link || !link.link) return null;

  return (
    <div className={cn(s.linkContainer, className)}>
      <Typography
        variant="defaultRegular"
        className={s.readMore}
        element={getLinkElemFromUrl(link.link)}
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          `link::read_more_link::${link.link}`,
        )}
      >
        {link.title || 'Читать продолжение'}
        <Icon id="read-more" className={s.readMoreIcon} />
      </Typography>
    </div>
  );
}

ReadMore.defaultProps = {
  className: '',
};

export { ReadMore };
