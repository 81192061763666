import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';

const CATH_WINDOW_BEGUN_INTERVAL = 200;

/**
 * Хук для определения viewType баннера
 */
export const useViewType = (
  name: string,
  isFirstBillboardOrTopBanner: boolean,
) => {
  const dispatch = useDispatch();
  const [viewTypes, setViewTypes] = useState('');
  // eslint-disable-next-line react/hook-use-state
  const [Begun, setBegun] = useState<any>();

  useEffect(() => {
    let BegunTimerId: NodeJS.Timeout;

    if (isFirstBillboardOrTopBanner) {
      if (!Begun) {
        BegunTimerId = setInterval(() => {
          if (window && window.Begun) {
            clearInterval(BegunTimerId);

            setBegun(window.Begun);
          }
        }, CATH_WINDOW_BEGUN_INTERVAL);
      }

      if (Begun) {
        Begun.Autocontext.Callbacks.register({
          block: {
            drawComplete: (result: any) => {
              setViewTypes(result.viewTypes);
            },
          },
        });
      }
    }

    return () => {
      if (Begun) {
        Begun.Autocontext.Callbacks.unregister('block', 'drawComplete');
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Begun]);

  useEffect(() => {
    if (
      isFirstBillboardOrTopBanner &&
      (name === 'top_banner' || name === 'billboard') &&
      viewTypes?.includes('Branding')
    ) {
      dispatch({
        type: SET_RUNTIME_VARIABLE,
        name: 'isBranding',
        value: true,
      });
    }
  }, [dispatch, isFirstBillboardOrTopBanner, name, viewTypes]);
};
