import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { getDaysNoun } from 'common/utils/getCalendar';

import {
  CALENDAR_LEGENDS,
  CALENDAR_TABS,
  CALENDAR_DAYS,
  MONTHS_ENG,
  MONTHS_GEN,
} from 'config/constants/calendar';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.calendar,
  ],
  (isMobile, calendar) => ({
    isMobile,
    calendar,
  }),
);

interface IHairDays {
  innerBlocks: ICardProps['inner_blocks'];
}

type DaysDataType = Record<string, string[]>;

function HairDays({ innerBlocks }: IHairDays) {
  const { top100Prefix } = useTop100Context();
  const { isMobile, calendar } = useSelector(selectRuntimeData);
  const [currentTab, setCurrentTab] = useState('haircut');

  const daysData = useMemo(
    () =>
      innerBlocks?.reduce(
        (res: DaysDataType, innerBlock) => {
          if (!innerBlock.date) {
            return res;
          }

          const current = calendar[innerBlock.date.toString()];

          if (!current || !current.hair) {
            return res;
          }

          return {
            ...res,
            [current.hair[currentTab]]: [
              ...res[current.hair[currentTab]],
              innerBlock.date,
            ],
          };
        },
        {
          lucky: [],
          unlucky: [],
          neutral: [],
        },
      ),
    [calendar, innerBlocks, currentTab],
  );

  if (!daysData) return null;

  const luckyDays = daysData.lucky.length;

  return (
    <div className={cn(s.root, isMobile && s.rootMobile)}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component="h2"
        className={s.title}
      >
        Благоприятные дни в этом месяце
      </Typography>
      <div className={s.tabsContainer}>
        <div className={s.tabs}>
          {Object.keys(CALENDAR_TABS.hair).map((tab) => (
            <Typography
              variant="defaultMedium"
              className={cn(s.tab, tab === currentTab && s.tabActive)}
              key={tab}
              element={
                <button type="button" onClick={() => setCurrentTab(tab)} />
              }
            >
              {CALENDAR_TABS.hair[tab]}
            </Typography>
          ))}
        </div>
      </div>
      <div className={s.days}>
        {Object.keys(CALENDAR_DAYS).map((day) =>
          daysData[day].length ? (
            <Typography
              component="div"
              variant="defaultMedium"
              className={s.day}
              key={day}
            >
              <span className={s.dayTitle}>{CALENDAR_DAYS[day]}</span>
              {daysData[day].map((item, itemIndex) => {
                const itemDate = new Date(item);
                const itemMonth = itemDate.getMonth();
                const itemDay = itemDate.getDate();

                return (
                  <Link
                    key={item}
                    to={`/hair/${MONTHS_ENG[itemMonth]}/${itemDay}/`}
                    className={s.dayLink}
                    {...getTop100Markup(isMobile, top100Prefix, 'hair_link')}
                  >
                    {itemDay} {MONTHS_GEN[itemMonth]}
                    {itemIndex < daysData[day].length - 1 ? ', ' : ''}
                  </Link>
                );
              })}
            </Typography>
          ) : null,
        )}
        <Typography
          variant="defaultRegular"
          component="div"
          className={s.summary}
        >
          В этом месяце нас ждет {luckyDays}{' '}
          {getDaysNoun(luckyDays, [
            'день благоприятный',
            'дня благоприятных',
            'дней благоприятных',
          ])}{' '}
          {CALENDAR_LEGENDS[currentTab].label}
        </Typography>
      </div>
    </div>
  );
}

export { HairDays };
