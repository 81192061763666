/* eslint-disable sonarjs/no-selector-parameter */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import ImageComponent from '@rambler-components/image';

import _throttle from 'lodash.throttle';

import { differenceInCalendarDays, format, isSameDay, addDays } from 'date-fns';

import _find from 'lodash.find';

import { Link } from 'react-router-dom';

import { APP_VERSION } from 'config/constants';

import { fetchDashboardDateData } from 'common/redux/pages';

import { createBiorhythm } from 'common/utils/biorhythm';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { updateCounters } from 'utils/counters/updater';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.celebrityList,
    (state: IAppState) => state.runtime.currentParams,
    (state: IAppState) => state.runtime.isBot,
    (state: IAppState) => state.account.birthday,
  ],
  (
    celebrityList,
    currentParams,
    isBot,
    userDateOfBirth,
    // eslint-disable-next-line max-params
  ) => ({
    celebrityList,
    currentParams,
    isBot,
    userDateOfBirth,
  }),
);

interface ICardBiorhythmProps {
  celebritySlug: ICardProps['celebrity_slug'];
  date: ICardProps['date'];
  isMobile: boolean;
  isMainCard: boolean;
  title: string;
}

interface BiorhythmDayType {
  date: Date;
  dateString: string;
  physical: number;
  emotional: number;
  intellectual: number;
  isPredictionDay: boolean;
  order: number; // порядковый номер дня
  isDisabled: boolean;
  middleShift: number;
}

interface GetUrlTypeVars {
  url: string;
  fetchUrl: string;
  celebSlug?: string;
  predictionDate?: string;
  birthDate?: string;
}

const DAY_WIDTH = 110;
const SIDE_DAYS = 10;
const MAX_SHIFTS = (SIDE_DAYS - 2) * 2;

const Biorhythm: React.FC<ICardBiorhythmProps> = React.memo(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ celebritySlug = '', date, isMobile, isMainCard, title }) => {
    const { top100Prefix } = useTop100Context();
    const dispatch = useDispatch();
    const { celebrityList, currentParams, isBot, userDateOfBirth } =
      useSelector(selectData);

    const dateOfPrediction = date ? new Date(date) : new Date();

    let chartDateOfBirth =
      celebritySlug && celebrityList?.length
        ? new Date(
            _find(
              celebrityList,
              (celeb) => celeb.slug.toLowerCase() === celebritySlug,
            )!.birthday,
          )
        : new Date(userDateOfBirth!);

    if (currentParams.personalDateOfBirth) {
      chartDateOfBirth = new Date(currentParams.personalDateOfBirth);
    }

    const top100Postfix = useRef('dashboard_person_forecast');

    if (celebritySlug) {
      top100Postfix.current = 'dashboard_celebrity_forecast';
    } else if (
      userDateOfBirth &&
      isSameDay(chartDateOfBirth, new Date(userDateOfBirth))
    ) {
      top100Postfix.current = 'dashboard_personal_forecast';
    }

    const getTop100 = useCallback(
      (tail: string) =>
        getTop100Markup(
          isMobile,
          top100Prefix,
          `${top100Postfix.current}::${tail}`,
        ),
      [isMobile, top100Prefix],
    );

    const leftPosition = DAY_WIDTH * (isMobile ? SIDE_DAYS - 1 : SIDE_DAYS - 2);
    const [chartLeftPosition, setChartLeftPosition] = useState(-leftPosition);
    const chartParams = useRef({
      width: 0,
      scrollPosition: leftPosition,
      minLeftPosition: 0,
      maxLeftPosition: 0,
    });
    const [shiftsCount, setShiftsCount] = useState(isMobile ? 9 : 8);

    const disableChart =
      differenceInCalendarDays(dateOfPrediction, chartDateOfBirth) < 0;

    const startDate = useRef(addDays(dateOfPrediction, -SIDE_DAYS));
    const endDate = useRef(addDays(dateOfPrediction, SIDE_DAYS));
    const chartData: BiorhythmDayType[] = createBiorhythm({
      dateOfBirth: chartDateOfBirth,
      disableChart,
      startDate: startDate.current,
      endDate: endDate.current,
    });
    const dataPredictionDay = _find(chartData, (day) => day.isPredictionDay);
    const [activeDay, setActiveDay] = useState(dataPredictionDay!);

    const scrollRef = useRef<HTMLDivElement>(null);
    const celebsDivRef = useRef<HTMLDivElement | null>(null);
    const initialActiveSelebRef = useRef<HTMLAnchorElement>();

    const moveChart = useCallback(
      (left: number) => {
        if (
          isMobile &&
          scrollRef.current &&
          chartParams.current.scrollPosition !== left
        ) {
          chartParams.current.scrollPosition = left;

          scrollRef.current.scrollTo({
            left: chartParams.current.scrollPosition,
            behavior: 'smooth',
          });
        } else if (!isMobile && chartLeftPosition !== left) {
          setChartLeftPosition(left);
        }
      },
      [chartLeftPosition, isMobile],
    );

    const onResize = useMemo(
      () =>
        _throttle(() => {
          if (scrollRef.current) {
            const { width } = scrollRef.current.getBoundingClientRect();

            chartParams.current.width = width;
            chartParams.current.minLeftPosition = width / 2 - DAY_WIDTH / 2;
            chartParams.current.maxLeftPosition =
              chartData.length * DAY_WIDTH - width / 2 - DAY_WIDTH / 2;
          }
        }, 100),
      [chartData.length],
    );

    useEffect(() => {
      if (initialActiveSelebRef.current && celebsDivRef.current) {
        const { width: celebWidth, left: celebLeft } =
          initialActiveSelebRef.current.getBoundingClientRect();
        const celebEnd = celebWidth + celebLeft;

        const { width: wrapperWidth, left: wrapperLeft } =
          celebsDivRef.current.getBoundingClientRect();
        const wrapperEnd = wrapperWidth + wrapperLeft;

        if (wrapperEnd < celebEnd) {
          celebsDivRef.current.scrollLeft = celebEnd - wrapperEnd + 5; // чтобы не прилипал к краю
        }
      }

      if (isMobile && scrollRef.current) {
        setChartLeftPosition(0);

        const { width } = scrollRef.current.getBoundingClientRect();

        const left = SIDE_DAYS * DAY_WIDTH - width / 2 + DAY_WIDTH / 2;

        chartParams.current.scrollPosition = left;
        scrollRef.current.scrollTo({
          left,
        });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      window.addEventListener('resize', onResize);
      onResize();

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, [onResize]);

    const getUrl = useCallback(
      (dayString: string, celebSlug = '') => {
        const dopPart =
          dayString && !(celebSlug && !currentParams.personalDateOfPrediction)
            ? `${dayString}`
            : '';
        const celebPart =
          celebSlug || celebritySlug ? `${celebSlug || celebritySlug}` : '';
        const dobPart = currentParams.personalDateOfBirth
          ? `${currentParams.personalDateOfBirth}`
          : '';

        const celebPartForUrl = celebPart ? `${celebPart}/` : '';
        const dopPartForUrl = dopPart ? `${dopPart}/` : '';
        const dopAndNotCelebPartForUrl =
          dobPart && !celebPart ? `${dobPart}/` : '';

        const celebPartForFetchUrl = celebPart ? `/${celebPart}` : '';
        const dopPartForFetchUrl = dopPart ? `/${dopPart}` : '';
        const dopAndNotCelebPartForFetchUrl =
          dobPart && !celebPart ? `/birthdate/${dobPart}` : '';

        const url = `/personal/${celebPartForUrl}${dopPartForUrl}${dopAndNotCelebPartForUrl}`;
        const fetchUrl = `${dopPartForFetchUrl}${celebPartForFetchUrl}${dopAndNotCelebPartForFetchUrl}`;

        const data: GetUrlTypeVars = {
          url,
          fetchUrl: `${fetchUrl}/`,
        };

        if (celebPart) {
          data.celebSlug = celebPart;
        }

        if (dopPart) {
          data.predictionDate = dopPart;
        }

        if (dobPart) {
          data.birthDate = dobPart;
        }

        return data;
      },
      [
        celebritySlug,
        currentParams.personalDateOfBirth,
        currentParams.personalDateOfPrediction,
      ],
    );

    const getPositionAndShiftCount = useCallback(
      (shiftSize: number) => {
        let newPosition = isMobile
          ? chartParams.current.scrollPosition
          : chartLeftPosition;
        let newShiftCount = shiftSize;

        if (isMobile) {
          newPosition =
            DAY_WIDTH * (shiftSize - 1) -
            chartParams.current.width / 2 +
            DAY_WIDTH / 2;

          if (newPosition > chartParams.current.maxLeftPosition) {
            newPosition =
              chartData.length * DAY_WIDTH - chartParams.current.width;
          } else if (newPosition < 0) {
            newPosition = 0;
          }
        } else if (newShiftCount >= MAX_SHIFTS) {
          newShiftCount = MAX_SHIFTS;
          newPosition = MAX_SHIFTS * DAY_WIDTH * -1;
        } else if (newShiftCount <= 0) {
          newShiftCount = 0;
          newPosition = 0;
        } else {
          newPosition = -(newShiftCount * DAY_WIDTH);
        }

        return {
          newPosition,
          newShiftCount,
        };
      },
      [chartData.length, chartLeftPosition, isMobile],
    );

    const onDayClick = useCallback(
      ({
        e,
        day,
        params,
        historyStateChangeType,
        newCelebSlug = celebritySlug,
      }: {
        e: React.MouseEvent;
        day: BiorhythmDayType;
        params: GetUrlTypeVars;
        historyStateChangeType: 'replace' | 'push';
        newCelebSlug?: string;
        // eslint-disable-next-line sonarjs/cognitive-complexity
      }) => {
        e.preventDefault();

        const { url, fetchUrl, celebSlug, predictionDate, birthDate } = params;

        if (
          activeDay?.dateString !== day.dateString ||
          celebritySlug !== newCelebSlug
        ) {
          const shift = isMobile ? day.order : day.order - 3;
          const { newPosition, newShiftCount } =
            getPositionAndShiftCount(shift);

          const fetchObj: RuntimeType['currentParams'] = {
            url: fetchUrl,
          };

          if (celebSlug) {
            fetchObj.personalCelebritySlug = celebSlug;
          }

          if (predictionDate) {
            fetchObj.personalDateOfPrediction = predictionDate;
          }

          if (birthDate) {
            fetchObj.personalDateOfBirth = birthDate;
          }

          if (isMobile && scrollRef.current) {
            // мобила
            dispatch(fetchDashboardDateData(fetchObj));

            updateCounters(APP_VERSION.MOBILE);
          } else if (!newCelebSlug) {
            // десктоп
            scrollRef.current?.addEventListener(
              'transitionend',
              async () => {
                await dispatch(fetchDashboardDateData(fetchObj));

                updateCounters(APP_VERSION.DESKTOP);
              },
              {
                once: true, // If true, the listener would be automatically removed when invoked.
              },
            );
          } else {
            // клик по селебу
            dispatch(fetchDashboardDateData(fetchObj));

            updateCounters(APP_VERSION.DESKTOP);
          }

          moveChart(newPosition);

          if (day.dateString !== activeDay.dateString) {
            setActiveDay(day);
          }

          if (shiftsCount !== newShiftCount) {
            setShiftsCount(newShiftCount);
          }

          if (historyStateChangeType === 'replace') {
            window.history.replaceState({ url }, 'title', url);
          } else {
            window.history.pushState({ url }, 'title', url);
          }

          if (!isMainCard) {
            window.scrollTo(0, 0);
          }
        }
      },
      [activeDay, shiftsCount], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onClickArrow = (e: React.MouseEvent, direction: 'left' | 'right') => {
      if (isMobile) {
        const newDate = addDays(
          new Date(activeDay?.date),
          direction === 'left' ? -1 : 1,
        );
        const formatedNewDay = format(newDate, 'yyyy-MM-dd');
        const newDay = _find(
          chartData,
          (day) => day.dateString === formatedNewDay,
        )!;
        const params = getUrl(formatedNewDay);

        onDayClick({
          e,
          day: newDay,
          params,
          historyStateChangeType: 'replace',
        });
      } else {
        const shiftSize = shiftsCount + (direction === 'left' ? -4 : 4);

        const { newPosition, newShiftCount } =
          getPositionAndShiftCount(shiftSize);

        setShiftsCount(newShiftCount);
        moveChart(newPosition);
      }
    };

    const showTitleBeforeCelebs = !isMainCard && celebritySlug;
    const h2 = (
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component={isMainCard ? 'h2' : 'span'}
        className={cn(s.title, isMobile ? s.titleMobile : s.titleDesktop)}
      >
        {title}
      </Typography>
    );

    const chart = useMemo(
      () => (
        <div
          className={cn(
            s.chartWrapper,
            isMobile ? s.chartWrapperMobile : s.chartWrapperDesktop,
          )}
        >
          <div
            className={cn(s.chartDays, isMobile && s.chartDaysScroll)}
            style={{
              // для того чтобы "сегодня" оказалось в центре
              transform: `translateX(${chartLeftPosition}px)`,
            }}
            ref={scrollRef}
          >
            {chartData.map((day) => {
              const formatedDayDate = format(day.date, 'yyyy-MM-dd');
              const text = isSameDay(new Date(), day.date)
                ? 'Сегодня'
                : format(day.date, 'dd.MM');
              const params = getUrl(formatedDayDate);

              const inner = (
                <>
                  <div className={s.chartBars}>
                    <div className={s.chartBar}>
                      {!day.isDisabled && (
                        <>
                          <span
                            className={cn(
                              s.chartBarText,
                              s['color-intellectual'],
                            )}
                          >
                            {day.intellectual}
                          </span>
                          <div
                            className={cn(
                              s.chartBarValue,
                              s['color-intellectual'],
                            )}
                            style={{ height: `${day.intellectual}%` }}
                          />
                        </>
                      )}
                    </div>
                    <div className={s.chartBar}>
                      {!day.isDisabled && (
                        <>
                          <span
                            className={cn(s.chartBarText, s['color-physical'])}
                          >
                            {day.physical}
                          </span>
                          <div
                            className={cn(s.chartBarValue, s['color-physical'])}
                            style={{ height: `${day.physical}%` }}
                          />
                        </>
                      )}
                    </div>
                    <div className={s.chartBar}>
                      {!day.isDisabled && (
                        <>
                          <span
                            className={cn(
                              s.chartBarText,
                              s.colorEmotional,
                              s['color-emotional'],
                            )}
                          >
                            {day.emotional}
                          </span>
                          <div
                            className={cn(
                              s.chartBarValue,
                              s['color-emotional'],
                            )}
                            style={{ height: `${day.emotional}%` }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <span
                    className={cn(
                      s.chartText,
                      day.isDisabled && s.chartTextDisabled,
                    )}
                  >
                    {text}
                  </span>
                </>
              );

              if (day.isDisabled) {
                return (
                  <div
                    key={params.url}
                    className={cn(s.chartDay, s.chartDayHoverDisabled)}
                  >
                    {inner}
                  </div>
                );
              }

              return (
                <Link
                  key={params.url}
                  to={params.url}
                  className={cn(
                    s.chartDay,
                    activeDay?.dateString === day.dateString &&
                      s.chartDayActive,
                  )}
                  onClick={(e) =>
                    onDayClick({
                      e,
                      day,
                      params,
                      historyStateChangeType: 'replace',
                    })
                  }
                  {...getTop100(
                    `diagram::${day.isPredictionDay ? 'middle' : day.middleShift}`,
                  )}
                >
                  {inner}
                </Link>
              );
            })}
          </div>
        </div>
      ),
      [
        activeDay?.dateString,
        chartData,
        chartLeftPosition,
        getTop100,
        getUrl,
        isMobile,
        onDayClick,
      ],
    );

    return (
      <div
        className={cn(
          s.biorhythm,
          isMobile ? s.biorhythmMobile : s.biorhythmDesktop,
          isMainCard && s.biorhythmDetail,
        )}
      >
        {showTitleBeforeCelebs && h2}
        {celebritySlug && (
          <div className={s.celebrity} ref={celebsDivRef}>
            {celebrityList.map((celeb) => {
              const key = `Biorhythm-celebrity-${celeb.id}-${celeb.slug}`; // заменить на slug

              const params = getUrl(activeDay.dateString, celeb.slug);

              return (
                <Link
                  to={params.url}
                  className={cn(
                    s.celebrityLink,
                    celeb.slug === celebritySlug && s.celebrityLinkActive,
                  )}
                  key={key}
                  onClick={(e) =>
                    onDayClick({
                      e,
                      day: activeDay,
                      params,
                      historyStateChangeType: 'push',
                      newCelebSlug: celeb.slug,
                    })
                  }
                  {...getTop100(`celebrity_button::${celeb.slug}`)}
                  ref={(node) => {
                    if (
                      !initialActiveSelebRef.current &&
                      node &&
                      celeb.slug === celebritySlug
                    ) {
                      initialActiveSelebRef.current = node;
                    }
                  }}
                >
                  <ImageComponent
                    className={s.celebrityImage}
                    src={celeb.image}
                    isImg={isBot}
                  />
                </Link>
              );
            })}
          </div>
        )}
        {!showTitleBeforeCelebs && h2}
        <div className={cn(s.chart, isMobile ? s.chartMobile : s.chartDesktop)}>
          <button
            type="button"
            className={cn(
              s.button,
              s.prev,
              shiftsCount === 0 && s.buttonHidden,
              isMobile ? s.buttonMobile : s.buttonDesktop,
              disableChart && s.buttonDisabled,
            )}
            onClick={(e) => {
              if (!disableChart) {
                onClickArrow(e, 'left');
              }
            }}
            {...getTop100('diagram::to_left')}
          >
            <Icon
              id="arrow-next"
              className={cn(s.icon, s.iconPrev, disableChart && s.iconDisabled)}
            />
          </button>
          {chart}
          <button
            type="button"
            className={cn(
              s.button,
              s.next,
              shiftsCount === MAX_SHIFTS && s.buttonHidden,
              isMobile ? s.buttonMobile : s.buttonDesktop,
              disableChart && s.buttonDisabled,
            )}
            onClick={(e) => {
              if (!disableChart) {
                onClickArrow(e, 'right');
              }
            }}
            {...getTop100('diagram::to_right')}
          >
            <Icon
              id="arrow-next"
              className={cn(s.icon, s.iconNext, disableChart && s.iconDisabled)}
            />
          </button>
        </div>
      </div>
    );
  },
);

Biorhythm.displayName = 'Biorhythm';

export { Biorhythm };
