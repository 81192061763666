import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  SyntheticEvent,
} from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';

import format from 'date-fns/format';

import RamblerCalendar from '@rambler-components/calendar';

import { DATE_FORMAT } from 'config/constants/calendar';

import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import { Icon } from 'common/components/Icon';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardBubblesCalendarProps {
  isMobile: boolean;
  currentParamsUrl: string;
  calendarType: IBubbles['calendar'];
}

function BubblesCalendar({
  isMobile,
  currentParamsUrl,
  calendarType,
}: ICardBubblesCalendarProps) {
  const history = useHistory();
  const { top100Prefix } = useTop100Context();
  const [calendarOpened, setCalendarOpened] = useState(false);
  const buttonContainerNode = useRef<HTMLDivElement>(null);
  const isIPhone = useRef(false);
  const isCurrentParamsUrlsDate = /\d{4}-\d{2}-\d{2}/.test(currentParamsUrl);
  const [currentDate, setCurrentDate] = useState(
    format(new Date(), DATE_FORMAT),
  );

  useEffect(() => {
    // eslint-disable-next-line sonarjs/slow-regex
    isIPhone.current = /iPhone.+Version\/[\d.]+.*Safari/i.test(
      window.navigator.userAgent,
    );
  }, []);

  useEffect(() => {
    if (isCurrentParamsUrlsDate) {
      setCurrentDate(currentParamsUrl);
    }
  }, [isCurrentParamsUrlsDate, currentParamsUrl]);

  useOnClickOutside(
    buttonContainerNode,
    () => setCalendarOpened(false),
    calendarOpened,
  );

  const onDayClick = useCallback((date: string) => {
    if (calendarType === 'primety') {
      history.push(`/primety/calendar/${date}/`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Hint: On iOS, in the system calendar, when you click on a date,
   * you immediately work out onChange, and on Android,
   * after confirming the date in its system calendar.
   * However, if you hang it on onBlur - it will work correctly in iOS,
   * but in android you will need to click somewhere on the screen.
   */
  const onChangeNativeCalendarDate = (
    e: SyntheticEvent,
    type: 'blur' | 'change',
  ) => {
    const target = e.target as HTMLInputElement;

    if (target) {
      const { value } = target;

      setCurrentDate(value);

      if (
        ((type === 'change' && !isIPhone.current) ||
          (type === 'blur' && isIPhone.current)) &&
        value
        // && value !== currentParamsUrl
      ) {
        onDayClick(value);
      }
    }
  };

  return (
    <div
      className={cn(
        s.calendar,
        isMobile ? s.calendarMobile : s.calendarDesktop,
      )}
      ref={buttonContainerNode}
    >
      <button
        className={cn(
          s.calendarButton,
          isMobile ? s.calendarButtonMobile : s.calendarButtonDesktop,
        )}
        type="button"
        onClick={() => {
          if (!isMobile) {
            setCalendarOpened(!calendarOpened);
          }
        }}
      >
        <Icon
          id="calendar"
          className={s.calendarIcon}
          {...getTop100Markup(
            isMobile,
            top100Prefix,
            'calendar::calendar_icon',
          )}
        />
      </button>
      {isMobile && (
        <input
          type="date"
          value={currentDate}
          className={s.hiddenDateInput}
          onChange={(e) => onChangeNativeCalendarDate(e, 'change')}
          onBlur={(e) => onChangeNativeCalendarDate(e, 'blur')}
        />
      )}
      {calendarOpened && (
        <RamblerCalendar
          className={s.calendarDropdown}
          minDate={new Date('1000-01-01')}
          maxDate={new Date('9999-12-31')}
          value={
            isCurrentParamsUrlsDate ? new Date(currentParamsUrl) : undefined
          }
          showYear
          showYearSwitch
          isSelectable
          onChange={(val: Date) => onDayClick(format(val, DATE_FORMAT))}
        />
      )}
    </div>
  );
}

export default BubblesCalendar;
