import React from 'react';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface IHeaderProps {
  content: string;
  type: string;
  isMobile: boolean;
}

function Header({ content, type, isMobile }: IHeaderProps) {
  if (!content) return null;

  if (type === 'header-3') {
    return (
      <Typography variant="h3" className={cn(s.h3, isMobile && s.h3Mobile)}>
        {content}
      </Typography>
    );
  }

  return (
    <Typography variant="h2" className={cn(s.h2, isMobile && s.h2Mobile)}>
      {content}
    </Typography>
  );
}

export { Header };
