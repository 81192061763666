import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { AlertBase } from 'common/components/Alerts/AlertBase';

import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isUpdateFeedVisible,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (isUpdateFeedVisible, isMobile) => ({
    isUpdateFeedVisible,
    isMobile,
  }),
);

interface IUpdateFeedProps {
  isHomePage?: boolean;
}

function UpdateFeed({ isHomePage }: IUpdateFeedProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isUpdateFeedVisible, isMobile } = useSelector(selectRuntimeData);
  const [opened, setOpened] = useState(isUpdateFeedVisible);

  useEffect(() => {
    if (opened !== isUpdateFeedVisible) {
      setOpened(isUpdateFeedVisible);
    }
  }, [isUpdateFeedVisible, opened]);

  const getBlockText = () => {
    if (!isHomePage) {
      return 'Лента на главной странице изменилась';
    }

    return isMobile
      ? 'Обновить ленту?'
      : 'Обновить ленту на основе введенных данных?';
  };

  const goToHomePage = () => {
    history.push('/');
  };

  const close = () => {
    dispatch({
      type: SET_RUNTIME_VARIABLE,
      name: 'isUpdateFeedVisible',
      value: false,
    });
  };

  if (!opened) {
    return null;
  }

  return (
    <AlertBase
      className={cn(
        s.updateFeed,
        isMobile && s.updateFeedMobile,
        !isHomePage && s.updateFeedOthers,
      )}
      mainText={getBlockText()}
      submitText={isHomePage ? 'Да' : 'Перейти'}
      abortProps={{ type: 'white' }}
      onSubmit={goToHomePage}
      onAbort={close}
      top100ExtPrefix="user_profile::refresh_bar"
      isMobile={isMobile}
    />
  );
}

UpdateFeed.defaultProps = {
  isHomePage: false,
};

export { UpdateFeed };
