import React from 'react';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { SOCIALS_SVG } from 'config/constants/socials';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './index.css';

export interface ICardSocialLinksProps {
  links?: ICardProps['social_links'];
  className?: string;
  isMobile: boolean;
  top100Postfix?: string;
}

function SocialLinks({
  links,
  className,
  isMobile,
  top100Postfix,
}: ICardSocialLinksProps) {
  const { top100Prefix } = useTop100Context();
  const keys = links ? Object.keys(links) : [];
  const top100PostfixinMarkup = top100Postfix ? `::${top100Postfix}` : '';

  if (!keys.length || !links) return null;

  return (
    <div className={cn(s.root, className)}>
      {keys.map((key: SocialType) => (
        <a
          key={key}
          href={links[key]}
          target="_blank"
          rel="noreferrer noopener"
          className={s.socialLink}
          {...getTop100Markup(
            isMobile,
            top100Prefix,
            `social_media::${key}${top100PostfixinMarkup}`,
          )}
        >
          <Icon id={SOCIALS_SVG[key]} className={s.socialIcon} />
        </a>
      ))}
    </div>
  );
}

SocialLinks.defaultProps = {
  links: {},
  className: '',
  top100Postfix: undefined,
};

export { SocialLinks };
