import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { Icon } from 'common/components/Icon';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './styles.css';

export enum CarouselDirection {
  next = 'next',
  prev = 'prev',
}

interface IControl {
  activeIndex: number;
  type: CarouselDirection;
  disabled: boolean;
  onClick: () => void;
}

export function Control({ type, disabled, activeIndex, onClick }: IControl) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const { top100Prefix } = useTop100Context();

  const isNext = type === CarouselDirection.next;
  const ariaLabel = isNext ? 'Следующее фото' : 'Предыдущее фото';

  return (
    <div className={cn(s.root, s[type])}>
      <Button
        aria-label={ariaLabel}
        className={s.button}
        disabled={disabled}
        onClick={onClick}
        {...getTop100Markup(
          isMobile,
          top100Prefix,
          `photo_carousel::${isNext ? activeIndex + 1 : activeIndex - 1}::button_${type}`,
        )}
      >
        <Icon
          id="arrow-prev-small"
          className={cn(s.icon, isNext && s.iconNext)}
        />
      </Button>
    </div>
  );
}
