/* eslint-disable sonarjs/no-commented-code */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { Fragment, useState, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useParams, useLocation } from 'react-router';
import cn from 'classnames';

import { Ad, AdProps } from 'common/components/Ad';
import { DraftText } from 'common/components/DraftText';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';
import { EmoWidget } from 'common/components/EmoWidget';
import { VkVideoPromo } from 'common/components/VkVideoPromo';
import { selectIsTarotPage } from 'config/constants/tarot';

import { getOrderedAds } from 'config/constants/content';
import { Title } from './Title';
import { Tags } from './Tags';
import { SwitchLinks } from './SwitchLinks';
import { Text } from './Text';
import { ReadMore } from './ReadMore';
import { Form } from './Form';
import { Image } from './Image';
import { Video } from './Video';
import { Bubbles } from './Bubbles';
import { Calendar } from './Calendar';
import { SwitchBubbles } from './SwitchBubbles';
import { ListBubbles } from './ListBubbles';

// import { Likes } from './Likes';
import { Footer } from './Footer';
import { Stories } from './Stories';
import { Mini } from './Mini';
import { Tile } from './Tile';
import { CardHeader } from './Header';
import { AboutBlock } from './AboutBlock';
import { Highlights } from './Highlights';
import { Special } from './Special';
import { PersonalCard } from './@Personal';

import { ZodiacSignSummary } from './@Zodiac/SignSummary';

import { LongreadCluster } from './@Longread/Cluster';
import { LongreadPremiumCluster } from './@Longread/Cluster/Premium';
import { LongreadListItem } from './@Longread/ListItem';
import { LongreadTopicsList } from './@Longread/ListItem/components/TopicsList';

import { FortuneCoffeeDesktop } from './@Fortune/Coffee/Desktop';
import { FortuneCoffeeMobile } from './@Fortune/Coffee/Mobile';
import { PromoCard } from './PromoCard';

import { NamesPersons } from './@Names/Persons';
import { NamesByParams } from './@Names/NamesByParams';
import { NameDays } from './@Names/NameDays';
import { NamesFatherNames } from './@Names/NamesFatherNames';

import { HairLink } from './@Hair/Link';
import { HairDays } from './@Hair/Days';
import { HairHighlight } from './@Hair/Highlight';

import OmensList from './@Omens/List';
import OmensLifeHacks from './@Omens/LifeHacks';

import { CalendarContext } from './CalendarContext';

import ChineseCompatibilityTable from './@Chinese/Compatibility/CompatibilityTable';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.isBubblesMoved,
    (state: IAppState) => selectIsTarotPage(state),
  ],
  (isMobile, isBubblesMoved, isTarotPage) => ({
    isMobile,
    isBubblesMoved,
    isTarotPage,
  }),
);

interface CardComponentItem {
  name: string;
  component: React.ReactNode;
}

const Card: React.FC<ICardProps> = React.memo(
  ({
    id,
    type = 'widget',
    isMainCard = false,
    isHairCard = false,
    isHairDate = false,
    isSubCard = false,
    isH1Title = false,
    isHairSubCardHighlight = false,
    className = '',
    title = '',
    subtitle = '',
    anchor,
    tags,
    switch_links: switchLinks,
    // annotation,
    text,
    link,
    form,
    calendar,
    bubbles,
    switch_bubbles: switchBubbles,
    alphabet_index: alphabetIndex,
    list_bubbles: listBubbles,
    likes,
    image,
    illustration,
    isLazy = false,
    video,
    stories,
    article,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    icon_type: iconType,
    isFirstStory = false,
    summary,
    isNeedLongreadTopicList = false,
    isAnimated = false,
    longreadTopicList,
    coffee_game: coffeeGame = [],
    special,
    topic = {
      id: 0,
      alias: '',
      details: {
        level_button_name: '',
        level_name: '',
        level_pos: 0,
        level_status: 0,
      },
      name: '',
      project_id: 0,
      status: 0,
    },
    cards = [],
    social_links: socialLinks = {},
    about_block: aboutBlock = [],
    isPremium = false,
    highlighted,
    celebrities,
    tags_with_names: tagsWithNames,
    name_days: nameDays,
    tags_with_fathernames: nameFathernames,
    draftParsed = '',
    inner_blocks: innerBlocks = [],
    dashboard_day_number: dashboardDayNumber,
    dashboard_tarot_teaser: dashboardTarotTeaser,
    dashboard_biorhythms: dashboardBiorhythms,
    celebrity_slug: celebritySlug,
    date,
    omens_list: omensList,
    omens_by_tags: omensLifeHacks,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    const { isMobile, isBubblesMoved, isTarotPage } = useSelector(selectData);
    const params = useParams<Record<string, string>>();
    const { pathname } = useLocation();

    const cardRef = useRef<HTMLDivElement>(null);

    const [calendarViewOpened, setCalendarViewOpened] = useState(!isMobile);

    const isLongreadListCard = type === 'longread';
    const isFullImageCard = type === 'longread' || type === 'fortune_preview';
    const isBuiltInCard = type === 'horoscopes_special';
    const isStoriesCard = type === 'stories';
    const isTwoColumnsCard = type === 'two_column_group';
    const isHandingTitle = type === 'handing_title';
    const isHumanDesignExperimental = type === 'humandesign_experimental';
    const isCalendar = typeof calendar !== 'undefined';
    const noBackgroundSub = isSubCard && type !== 'two_column_group_sub';
    const isFortuneFirst = pathname.includes('taro/data-rozhdeniya');
    const FortuneCoffeeGame = isMobile
      ? FortuneCoffeeMobile
      : FortuneCoffeeDesktop;
    const isCardBubblesMoved = isMainCard && isBubblesMoved;
    // https://jira.rambler-co.ru/browse/HORO-5948
    const isNewBiorhytmCard = type === 'dashboard' && celebritySlug;

    const cardTitle: CardComponentItem = {
      name: 'title',
      component: (
        <Title
          title={title}
          isMobile={isMobile}
          link={link}
          type={type}
          anchor={anchor}
          isLink={!isMainCard && /^(sign|widget|bubbles)/.test(type)}
          isH2Title={isH2Title}
          isH3Title={isH3Title}
          isMainCard={isMainCard}
          isH1Title={isH1Title}
          isHanding={isHandingTitle}
          wrapperClassName={cn(
            s.title,
            isMobile ? s.titleMobile : s.titleDesktop,
          )}
          isSubCard={isSubCard}
        />
      ),
    };
    const cardTags: CardComponentItem = {
      name: 'tags',
      component: (
        <Tags
          wrapperClassName={cn(
            s.tags,
            isTarotPage && s.tagsTarot,
            isMobile ? s.tagsMobile : s.tagsDesktop,
          )}
          tags={tags}
          isMobile={isMobile}
        />
      ),
    };
    const cardSwitchLinks: CardComponentItem = {
      name: 'switchLinks',
      component: (
        <SwitchLinks
          className={cn(
            s.switchLinks,
            isMobile ? s.switchLinksMobile : s.switchLinksDesktop,
          )}
          switchLinks={switchLinks}
          isMobile={isMobile}
        />
      ),
    };

    const cardText: CardComponentItem = {
      name: 'text',
      component: draftParsed.length ? (
        <DraftText
          data={draftParsed}
          title={title}
          className={cn(
            s.draftText,
            isMobile ? s.draftTextMobile : s.draftTextDesktop,
            isFortuneFirst && s.draftTextAfterSpecial,
          )}
          isMainCard={isMainCard}
        />
      ) : (
        <Text
          wrapperClassName={cn(s.text, isMobile ? s.textMobile : s.textDesktop)}
          text={text}
          isMainCard={isMainCard}
        />
      ),
    };
    const cardReadMore: CardComponentItem = {
      name: 'readMore',
      component: (
        <ReadMore
          className={cn(
            s.readMore,
            isMobile ? s.readMoreMobile : s.readMoreDesktop,
          )}
          link={link}
          isMobile={isMobile}
        />
      ),
    };
    const cardForm: CardComponentItem = {
      name: 'form',
      component: (
        <Form
          form={form}
          isMobile={isMobile}
          link={link}
          className={cn(s.form, isMobile ? s.formMobile : s.formDesktop)}
        />
      ),
    };
    // const cardTabs: CardComponentItem = {
    //   name:      'tabs',
    //   component: (
    //     <div
    //       className={cn(
    //         s.tabs,
    //         isMobile ? s.tabsMobile : s.tabsDesktop,
    //       )}
    //       top100Prefix={top100Prefix}
    //     >
    //       Табы
    //     </div>
    //   ),
    // };
    const cardBubbles: CardComponentItem = {
      name: 'bubbles',
      component: (
        <Bubbles
          className={cn(
            s.bubbles,
            isMobile ? s.bubblesMobile : s.bubblesDesktop,
            isCardBubblesMoved && s.bubblesMenu,
          )}
          bubbles={bubbles}
          isCardBubblesMoved={isCardBubblesMoved}
        />
      ),
    };

    const cardSwitchBubbles: CardComponentItem = {
      name: 'switchBubbles',
      component: (
        <SwitchBubbles
          className={cn(
            s.bubbles,
            isMobile ? s.bubblesMobile : s.bubblesDesktop,
          )}
          bubbles={switchBubbles}
        />
      ),
    };

    const cardListBubbles: CardComponentItem = {
      name: 'listBubbles',
      component: listBubbles && (
        <ListBubbles
          listBubbles={listBubbles}
          isMobile={isMobile}
          type={type}
        />
      ),
    };

    const calendarContextMemo = useMemo(
      () => [calendarViewOpened, setCalendarViewOpened],
      [calendarViewOpened],
    );

    const cardCalendar: CardComponentItem = {
      name: 'calendar',
      component: calendar ? (
        <CalendarContext.Provider value={calendarContextMemo}>
          <Calendar
            className={cn(
              s.calendar,
              isMobile ? s.calendarMobile : s.calendarDesktop,
            )}
            calendar={calendar}
            isMobile={isMobile}
          />
        </CalendarContext.Provider>
      ) : null,
    };

    const cardHairLink: CardComponentItem = {
      name: 'hairLink',
      component: <HairLink />,
    };

    const cardHairDays: CardComponentItem = {
      name: 'hairDays',
      component: <HairDays innerBlocks={innerBlocks} />,
    };

    const cardHairHighlight: CardComponentItem = {
      name: 'hairHighlight',
      component: (
        <HairHighlight
          date={date}
          link={link}
          className={cn(
            s.highlightsHair,
            isMobile ? s.highlightsHairMobile : s.highlightsHairDesktop,
          )}
        />
      ),
    };

    const defaultSubCardsAdFunc = useCallback(
      (subCard: Partial<ICardProps>, index: number) => {
        const neededIndexes = isMobile ? [1, 4, 7, 10, 13, 16] : [1, 4];

        if (neededIndexes.includes(index)) {
          return {
            name: getOrderedAds(isMobile)[neededIndexes.indexOf(index)],
            onRenderWrapperStyle: {
              marginTop: isMobile ? 20 : 30,
              marginBottom: isMobile ? 0 : 10,
            },
          };
        }

        return undefined;
      },
      [isMobile],
    );

    const cardSubCards = useCallback(
      ({
        isWithHairCalendar,
        subCards,
        subType = 'widgets_with_inner_blocks_sub',
        adFunc = defaultSubCardsAdFunc,
      }: {
        isWithHairCalendar: boolean;
        subCards: Partial<ICardProps>[] | undefined;
        subType?: ICardProps['type'];
        adFunc?: (
          subCard: Partial<ICardProps>,
          subCardIndex: number,
        ) => undefined | AdProps;
      }): CardComponentItem => ({
        name: 'subCards',
        component: () => {
          if (!subCards) return null;

          return subCards.map((subCard, subCardIndex) => {
            const adProps = adFunc && adFunc(subCard, subCardIndex);
            const isFirstHairCard = subCardIndex === 0 && isHairCard;
            const key = `${id}-${subCardIndex}`;

            return (
              <Fragment key={key}>
                <Card
                  id={`${key}-card`}
                  type={subType}
                  isMainCard={false}
                  isSubCard
                  isHairSubCardHighlight={isFirstHairCard}
                  title=""
                  {...subCard}
                />
                {isWithHairCalendar &&
                  isFirstHairCard &&
                  !isHairDate &&
                  cardHairDays.component}
                {adProps && <Ad {...adProps} wrapperClassName={s.ad} />}
              </Fragment>
            );
          });
        },
      }),
      [
        cardHairDays.component,
        defaultSubCardsAdFunc,
        id,
        isHairCard,
        isHairDate,
      ],
    );
    // const cardLikes: CardComponentItem = {
    //   name:      'likes',
    //   component: (
    //     <Likes
    //       likes={likes}
    //       isMobile={isMobile}
    //       isFullImageCard={isFullImageCard}
    //       top100Prefix={top100Prefix}
    //     />
    //   ),
    // };
    const cardImage: CardComponentItem = {
      name: 'image',
      component: (
        <Image
          wrapperClassName={cn(
            s.image,
            isMobile ? s.imageMobile : s.imageDesktop,
          )}
          image={
            illustration
              ? {
                  url: isMobile
                    ? illustration.mobile_image
                    : illustration.desktop_image,
                  s3: true,
                  source: {
                    title: illustration.source_title,
                    url: illustration.source_url,
                  },
                }
              : image
          }
          title={title}
          isLazy={isLazy}
          isIllustration={!!illustration}
          isMobile={isMobile}
        />
      ),
    };
    const cardVideo: CardComponentItem = {
      name: 'video',
      component: video && (
        <Video
          className={cn(s.video, isMobile ? s.videoMobile : s.videoDesktop)}
          isMobile={isMobile}
          video={video}
        />
      ),
    };
    // Если не нужен полноценный футер, то в обычных карточках нужен отступ от конца блока
    const cardPseudoFooter: CardComponentItem = {
      name: 'pseudoFooter',
      component: (
        <div
          className={cn(
            s.pseudoFooter,
            isMobile ? s.pseudoFooterMobile : s.pseudoFooterDesktop,
          )}
        />
      ),
    };
    const cardFooter: CardComponentItem = {
      name: 'footer',
      component: (
        <Footer
          className={cn(
            s.footer,
            isMobile ? s.footerMobile : s.footerDesktop,
            isHumanDesignExperimental && s.footerHumanDesignExperimental,
          )}
          likesClassName={cn(
            s.likes,
            isMobile ? s.likesMobile : s.likesDesktop,
          )}
          title={title}
          link={link}
          likes={likes || undefined}
          isMobile={isMobile}
          key={likes ? likes.codename : ''}
        />
      ),
    };

    const cardStories: CardComponentItem = {
      name: 'stories',
      component: (
        <Stories
          wrapperClassName={cn(
            s.stories,
            isMobile ? s.storiesMobile : s.storiesDesktop,
          )}
          stories={stories}
          isLazy={isLazy}
          isFirstStory={isFirstStory}
        />
      ),
    };

    const cardMini: CardComponentItem = {
      name: 'mini',
      component: (
        <Mini
          title={title}
          link={link}
          isMobile={isMobile}
          isH2Title={isH2Title}
          iconType={iconType}
        />
      ),
    };

    const cardZodiacSignSummary: CardComponentItem = {
      name: 'zodiacSummary',
      component: (
        <ZodiacSignSummary
          summary={summary}
          isMobile={isMobile}
          highlight={highlighted?.list?.[0]}
        />
      ),
    };

    const cardLongreadCluster: CardComponentItem = {
      name: 'longreadCluster',
      component: isPremium ? (
        <LongreadPremiumCluster article={article} tags={tags} isLazy={isLazy} />
      ) : (
        <LongreadCluster
          isMobile={isMobile}
          article={article}
          tags={tags}
          likes={likes}
          isLazy={isLazy}
        />
      ),
    };

    const cardLongreadListItem: CardComponentItem = {
      name: 'longreadListItem',
      component: (
        <LongreadListItem
          isMobile={isMobile}
          article={article}
          likes={likes}
          isLazy={isLazy}
        />
      ),
    };

    const cardFortuneCoffee: CardComponentItem = {
      name: 'fortuneCoffee',
      component: <FortuneCoffeeGame coffeeGame={coffeeGame} />,
    };

    const cardPromo: CardComponentItem = {
      name: 'promoCard',
      component: (
        <PromoCard
          isMobile={isMobile}
          isAnimated={isAnimated}
          id={id}
          title={title}
          likes={likes}
          topic={topic}
          link={link}
        />
      ),
    };

    const cardSpecial: CardComponentItem = {
      name: 'special',
      component: <Special special={special} />,
    };

    const cardTile: CardComponentItem = {
      name: 'tiles',
      component: (
        <Tile
          tile={{
            id,
            subtitle,
            title,
            image,
            link,
          }}
          isMobile={isMobile}
        />
      ),
    };

    const cardAboutBlock: CardComponentItem = {
      name: 'aboutBlock',
      component: (
        <AboutBlock
          data={aboutBlock}
          className={cn(
            s.aboutBlock,
            isMobile ? s.aboutBlockMobile : s.aboutBlockDesktop,
          )}
          isMobile={isMobile}
        />
      ),
    };

    const cardHeader: CardComponentItem = {
      name: 'header',
      component: (
        <CardHeader
          className={cn(
            s.cardHeader,
            isMobile ? s.cardHeaderMobile : s.cardHeaderDesktop,
            tags?.length && s.cardHeaderWithTags,
          )}
          isMobile={isMobile}
          titleProps={{
            title,
            isMobile,
            link,
            type,
            isLink: !isMainCard && /^(sign|widget|bubbles)/.test(type),
            isH2Title,
            isH3Title,
            isMainCard,
            isH1Title,
            wrapperClassName: cn(
              s.title,
              isMobile ? s.titleMobile : s.titleDesktop,
            ),
            isHanding: isHandingTitle,
            isSubCard,
          }}
          socialProps={{
            links: socialLinks,
            isMobile,
            top100Postfix: params.author,
          }}
        />
      ),
    };

    const cardHighlights: CardComponentItem = {
      name: 'highlights',
      component: (
        <Highlights
          data={highlighted}
          isMobile={isMobile}
          className={cn(
            s.highlights,
            !isHairSubCardHighlight && s.highlightsWithoutHair,
            !isHairSubCardHighlight &&
              isMobile &&
              s.highlightsWithoutHairMobile,
            !isHairSubCardHighlight &&
              !isMobile &&
              s.highlightsWithoutHairDesktop,
          )}
        />
      ),
    };

    const cardDashboardPersonalCard: CardComponentItem = {
      name: 'dashboardPersonalCard',
      component: (
        <PersonalCard
          className={s.dashboardPersonalCard}
          dayNumber={dashboardDayNumber}
          tarotTeaser={dashboardTarotTeaser}
          biorhythms={dashboardBiorhythms}
          celebritySlug={celebritySlug}
          date={date}
          isMobile={isMobile}
          isMainCard={isMainCard}
        />
      ),
    };

    const cardNamesPersons: CardComponentItem = {
      name: 'namesPersons',
      component: celebrities && (
        <NamesPersons className={s.namesPersons} celebrities={celebrities} />
      ),
    };

    const cardNamesByParams: CardComponentItem = {
      name: 'namesByParams',
      component: tagsWithNames && (
        <NamesByParams
          className={s.namesByParams}
          namesByParams={tagsWithNames}
          isMobile={isMobile}
        />
      ),
    };

    const cardNamesFatherNames: CardComponentItem = {
      name: 'namesFatherNames',
      component: nameFathernames && (
        <NamesFatherNames
          className={s.namesFatherNames}
          fatherNames={nameFathernames}
          isMobile={isMobile}
        />
      ),
    };

    const cardNameDays: CardComponentItem = {
      name: 'nameDays',
      component: (
        <NameDays
          className={s.nameDays}
          isMobile={isMobile}
          nameDays={nameDays}
        />
      ),
    };

    const cardOmensList: CardComponentItem = {
      name: 'omensList',
      component: omensList && (
        <OmensList omensList={omensList} isMobile={isMobile} />
      ),
    };

    const cardOmensLifeHacks: CardComponentItem = {
      name: 'omensLifeHacks',
      component: omensLifeHacks && (
        <OmensLifeHacks lifeHacks={omensLifeHacks} isMobile={isMobile} />
      ),
    };

    const cardChineseCompatibilityTable: CardComponentItem = {
      name: 'chineseCompatibilityTable',
      component: (
        <ChineseCompatibilityTable
          className={cn(
            s.compatibilityTable,
            isMobile ? s.compatibilityTableMobile : s.compatibilityTableDesktop,
          )}
        />
      ),
    };

    const cardHumanDesignForm: CardComponentItem = {
      name: 'humanDesignForm',
      component: (
        <HumanDesignForm
          isMobile={isMobile}
          isWithTitle={isHumanDesignExperimental && !isMobile}
          isExperimental={isHumanDesignExperimental}
          className={
            isHumanDesignExperimental
              ? s.humanDesignFormExperimental
              : s.humanDesignForm
          }
        />
      ),
    };

    const emoWidget: CardComponentItem = {
      name: 'emoWidget',
      component: <EmoWidget className={s.emojiWidget} />,
    };

    const vkVideoPromo: CardComponentItem = {
      name: 'vkVideoPromo',
      component: <VkVideoPromo />,
    };

    const cardsByType: Record<
      ICardProps['type'],
      (CardComponentItem | null)[]
    > = {
      dashboard: [
        isNewBiorhytmCard ? null : cardHeader,
        isNewBiorhytmCard ? null : cardForm,
        cardDashboardPersonalCard,
        isNewBiorhytmCard ? null : cardText,
        isNewBiorhytmCard ? null : cardTags,
        cardFooter,
      ],
      static: [
        cardHeader,
        isCardBubblesMoved ? cardBubbles : null,
        cardSwitchLinks,
        cardImage,
        cardForm,
        cardHighlights,
        cardText,
        cardReadMore,
        cardTags,
        cardFooter,
      ],
      widget: [
        cardHeader,
        isCardBubblesMoved ? cardBubbles : null,
        cardSwitchLinks,
        cardImage,
        cardForm,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : cardChineseCompatibilityTable,
        cardTags,
        cardFooter,
      ],
      widget_bubbles: [
        cardHeader,
        isCardBubblesMoved ? cardBubbles : null,
        cardSwitchLinks,
        cardImage,
        cardForm,
        cardHighlights,
        cardText,
        vkVideoPromo,
        isMainCard ? emoWidget : null,
        !isMainCard ? cardReadMore : cardChineseCompatibilityTable,
        !isCardBubblesMoved ? cardBubbles : null,
        cardTags,
        cardFooter,
      ],
      widget_bubbles_names: [
        cardHeader,
        cardSwitchLinks,
        cardHighlights,
        cardAboutBlock,
        cardNameDays,
        cardText,
        isMainCard ? cardChineseCompatibilityTable : null,
        celebrities ? cardNamesPersons : null,
        cardBubbles,
        cardTags,
        cardFooter,
      ],
      widget_with_inner_blocks: [
        cardHeader,
        cardSwitchLinks,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : cardChineseCompatibilityTable,
        cardSubCards({
          isWithHairCalendar: false,
          subCards: innerBlocks,
        }),
        cardFooter,
      ],
      widget_bubbles_with_inner_blocks: [
        cardHeader,
        isCardBubblesMoved ? cardBubbles : null,
        cardSwitchLinks,
        cardForm,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : cardChineseCompatibilityTable,
        cardSubCards({
          isWithHairCalendar: false,
          subCards: innerBlocks,
        }),
        !isCardBubblesMoved ? cardBubbles : null,
        cardTags,
        cardFooter,
      ],
      widget_bubbles_with_inner_blocks_calendar: [
        cardHeader,
        isHairCard ? cardHairLink : null,
        isCardBubblesMoved ? cardBubbles : null,
        cardSwitchLinks,
        !isHairCard || !isHairDate ? cardCalendar : null,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : cardChineseCompatibilityTable,
        cardSubCards({
          isWithHairCalendar: true,
          subCards: innerBlocks,
        }),
        !isCardBubblesMoved ? cardBubbles : null,
        isHairDate ? cardCalendar : null,
        cardTags,
        cardFooter,
      ],
      // eslint-disable-next-line sonarjs/cognitive-complexity
      widgets_with_inner_blocks_sub: (() => {
        if (isMobile && calendarViewOpened && calendar) {
          return [
            cardCalendar,
            cardTitle,
            switchBubbles ? cardSwitchBubbles : null,
            cardSwitchLinks,
            isHairSubCardHighlight ? cardHairHighlight : null,
            cardHighlights,
            omensList ? cardOmensList : null,
            omensLifeHacks ? cardOmensLifeHacks : null,
            nameFathernames ? cardNamesFatherNames : null,
            listBubbles ? cardListBubbles : null,
            cardText,
            cardForm,
            celebrities ? cardNamesPersons : null,
            tagsWithNames ? cardNamesByParams : null,
            cardBubbles,
            !isMainCard ? cardReadMore : null,
          ];
        }

        return [
          isMobile ? cardCalendar : null,
          cardTitle,
          switchBubbles ? cardSwitchBubbles : null,
          cardSwitchLinks,
          isHairSubCardHighlight ? cardHairHighlight : null,
          cardHighlights,
          omensList ? cardOmensList : null,
          omensLifeHacks ? cardOmensLifeHacks : null,
          nameFathernames ? cardNamesFatherNames : null,
          listBubbles ? cardListBubbles : null,
          cardText,
          cardForm,
          celebrities ? cardNamesPersons : null,
          tagsWithNames ? cardNamesByParams : null,
          cardBubbles,
          !isMobile ? cardCalendar : null,
          !isMainCard ? cardReadMore : null,
        ];
      })(),
      sign_widget: [
        cardHeader,
        cardHighlights,
        cardZodiacSignSummary,
        cardText,
        !isMainCard ? cardReadMore : null,
        cardFooter,
      ],
      sign_widget_bubbles: [
        cardHeader,
        cardHighlights,
        cardZodiacSignSummary,
        cardText,
        !isMainCard ? cardReadMore : null,
        cardBubbles,
        cardFooter,
      ],
      sign_widget_with_inner_blocks: [
        cardHeader,
        cardHighlights,
        cardAboutBlock,
        cardSubCards({
          isWithHairCalendar: false,
          subCards: innerBlocks,
          subType: 'sign_with_inner_blocks_sub',
        }),
        cardFooter,
      ],
      sign_widget_bubbles_with_inner_blocks: [
        cardHeader,
        isCardBubblesMoved ? cardBubbles : null,
        cardHighlights,
        cardAboutBlock,
        cardSubCards({
          isWithHairCalendar: false,
          subCards: innerBlocks,
          subType: 'sign_widget_bubbles_with_inner_blocks_sub',
        }),
        !isCardBubblesMoved ? cardBubbles : null,
        cardTags,
        cardFooter,
      ],
      sign_with_inner_blocks_sub: [
        cardTitle,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : null,
      ],
      sign_widget_bubbles_with_inner_blocks_sub: [
        cardTitle,
        cardHighlights,
        cardText,
        !isMainCard ? cardReadMore : null,
      ],
      dreams_alphabet_index: [
        cardHeader,
        cardText,
        cardBubbles,
        cardSubCards({
          isWithHairCalendar: false,
          subCards: alphabetIndex,
          subType: 'dreams_alphabet_index_sub',
          adFunc: (subCard, index) =>
            (index + 1) % 5 === 0
              ? {
                  name: getOrderedAds(isMobile)[(index - 4) / 5],
                  onRenderStyle: { marginTop: 20 },
                }
              : undefined,
        }),
        cardFooter,
      ],
      dreams_alphabet_index_sub: [
        cardBubbles,
        !isMainCard ? cardReadMore : null,
      ],
      form: [cardTitle, cardForm, !isMobile ? cardPseudoFooter : null],
      video: [cardTitle, cardVideo, !isMobile ? cardPseudoFooter : null],
      bubbles: [cardTitle, cardText, cardBubbles, cardPseudoFooter],
      stories: [cardStories],
      mini: [cardMini],
      longread: [cardLongreadListItem],
      longread_detail: [cardLongreadCluster],
      fortune_preview: [cardPromo],
      fortune_coffee: [cardHeader, cardText, cardFortuneCoffee, cardFooter],
      horoscopes_special: isFortuneFirst
        ? [cardHeader, cardSpecial, cardText, cardTags, cardFooter]
        : [cardHeader, cardText, cardSpecial, cardFooter],
      horoscopes_special_bubbles: [
        cardHeader,
        cardBubbles,
        cardText,
        cardSpecial,
        cardTags,
        cardFooter,
      ],
      about: [cardHeader, cardAboutBlock, cardText, cardFooter],
      two_column_group: [
        cardSubCards({
          isWithHairCalendar: false,
          subCards: cards,
          subType: 'two_column_group_sub',
          adFunc: (subCard, index) =>
            isMobile && (index === 1 || index === 4)
              ? {
                  name: getOrderedAds(isMobile)[index === 1 ? 0 : 1],
                  onRenderStyle: { marginTop: 10 },
                }
              : undefined,
        }),
      ],
      two_column_group_sub: [cardTile],
      handing_title: [cardTitle],
      list_view_alphabet: [
        cardHeader,
        cardSwitchBubbles,
        listBubbles ? cardListBubbles : null,
        cardAboutBlock,
        cardBubbles,
        cardTags,
        cardFooter,
      ],
      humandesign: [
        cardTitle,
        cardText,
        cardImage,
        cardHumanDesignForm,
        cardFooter,
      ],
      humandesign_experimental: [
        cardTitle,
        cardText,
        cardImage,
        cardHumanDesignForm,
        cardFooter,
      ],
    };

    if (!type || !cardsByType[type]) return null;

    const noBackground =
      isStoriesCard || isTwoColumnsCard || noBackgroundSub || isHandingTitle;

    return (
      <Fragment key={`${id}-${type}`}>
        {isLongreadListCard && isNeedLongreadTopicList && (
          <LongreadTopicsList data={longreadTopicList} />
        )}
        <div
          className={cn(
            s.root,
            isMobile ? s.rootMobile : s.rootDesktop,
            isStoriesCard && s.rootStories,
            noBackground && s.noBackground,
            isSubCard && s.rootSub,
            isTwoColumnsCard && s.rootTwoColumns,
            isFullImageCard && s.rootFullImageCard,
            isBuiltInCard && s.rootBuiltInCard,
            isCalendar && s.rootCalendar,
            isHumanDesignExperimental && s.rootHumanDesignExperimental,
            className,
          )}
          ref={cardRef}
        >
          {!isPremium && isMainCard && (
            <Ad
              name={isMobile ? 'sponsored_m' : 'sponsored'}
              wrapperClassName={s.sponsored}
            />
          )}
          {cardsByType[type].map((item, index) => {
            if (!item) return null;

            const { name, component } = item;
            const key = `Card-${type}-${index}-${name}`;

            return (
              <Fragment key={key}>
                {typeof component === 'function' ? component() : component}
              </Fragment>
            );
          })}
        </div>
      </Fragment>
    );
  },
);

Card.displayName = 'Card';

export default Card;
