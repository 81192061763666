import React from 'react';
import cn from 'classnames';

import s from './index.css';

interface Props {
  data: ICardProps['about_block'];
  isMobile?: boolean;
  className?: string;
}

function AboutBlock({ data, isMobile, className }: Props) {
  if (!data || !data.length) return null;

  return (
    <div
      className={cn(s.root, isMobile ? s.rootMobile : s.rootDesktop, className)}
    >
      {data.map(({ key, value }, index) => (
        <span className={s.aboutItem} key={`about-item-${index}`}>
          <span className={s.aboutItemKey}>{key}</span>
          <span
            className={s.aboutItemValue}
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />
        </span>
      ))}
    </div>
  );
}

AboutBlock.defaultProps = {
  isMobile: false,
  className: '',
};

export { AboutBlock };
