import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import ImageComponent from '@rambler-components/image';

import { ClusterImageObject } from 'common/components/schemaOrg/ImageObject/ClusterImageObject';
import { Typography } from 'common/components/Typography';

import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';
import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { initShareButtons } from 'utils/share/initShareButtons';
import Legal from 'desktop/components/Legal';
import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.fullUrl,
    (state: IAppState) => state.runtime.isBot,
  ],
  (isMobile, fullUrl, isBot) => ({
    isMobile,
    fullUrl,
    isBot,
  }),
);

interface PropsType {
  cluster: ATCluster;
  isLazy: ICardProps['isLazy'];
  withPhoto?: boolean;
  noImage?: boolean;
  isPremium?: boolean;
}

/**
 * Главное фото кластера и ссылка на его источник
 *
 * @param cluster - Общая информация для приложения
 * @param cluster - объект данных кластера
 * @param isLazy - Lazy load
 * @param withPhoto - нужна ли подпись "Фото"
 * @param noImage - не отображать картинку. Для кейса на dir1
 */
export const ClusterImage: React.FC<PropsType> = React.memo(
  ({
    cluster,
    isLazy,
    withPhoto = true,
    noImage = false,
    isPremium = false,
  }) => {
    const { top100Prefix } = useTop100Context();
    const { isMobile, fullUrl, isBot } = useSelector(selectData);
    const shareRef = useRef<HTMLDivElement>(null);

    const {
      image,
      title: clusterTitle,
      long_title: longClusterTitle = '',
    } = cluster;

    useEffect(() => {
      if (isPremium && shareRef.current) {
        initShareButtons({
          node: shareRef.current,
          cardTitle: longClusterTitle,
          isMobile: false,
          cardUrl: fullUrl,
          buttonHeight: 35,
          iconSize: 15,
        });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const needRenderImage = useRef(!noImage);

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imageLoadedCallback = useCallback(() => setIsImageLoaded(true), []);

    if (!image) return null;

    const { source: imageSource, url, description } = image;

    const imageSourceSignUrl = safeGet(() => imageSource.url, '');
    const isNotRamblerImageSource = !imageSourceSignUrl.includes('rambler.ru');

    const schemaOrg = <ClusterImageObject cluster={cluster} />;

    return needRenderImage.current ? (
      <div
        className={cn(
          s.mediaSectionImage,
          isMobile ? s.mediaSectionImageMobile : s.mediaSectionImageDesktop,
          isPremium && s.mediaSectionImagePremium,
        )}
      >
        {schemaOrg}
        <div
          className={cn(s.imageWrapper, isImageLoaded && s.imageWrapperLoaded)}
        >
          <ImageComponent
            className={cn(s.img, isLazy && s.imgLazy)}
            isImg={isBot || !isLazy}
            src={url}
            alt={description || clusterTitle}
            width={640}
            height={360}
            isS3
            onLoad={imageLoadedCallback}
            // onError={() => {
            //   needRenderImage.current = false;
            // }}
          />
        </div>
        {imageSource && (imageSource.title || imageSource.url) && (
          <Typography
            variant="defaultMedium"
            component="div"
            className={cn(
              s.imageDescription,
              isPremium && s.imageDescriptionPremium,
            )}
          >
            {withPhoto && imageSource.title && <span>Фото:&nbsp;</span>}
            {imageSource.url && safeGet(() => new URL(imageSource.url)) ? (
              <a
                href={
                  !isNotRamblerImageSource
                    ? imageSourceSignUrl
                    : getUrlWithUtmParams(imageSourceSignUrl, {
                        medium: 'source',
                        source: 'rhoroscopes',
                      })
                }
                target="_blank"
                rel="noopener noreferrer nofollow"
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'cluster_image::source',
                )}
              >
                {imageSource.title || imageSource.url}
              </a>
            ) : (
              imageSource.title
            )}
            {isPremium && (
              <>
                <div
                  ref={shareRef}
                  className={cn('rambler-share', s.imageDescriptionButtons)}
                />
                <Legal top100Prefix={top100Prefix} />
              </>
            )}
          </Typography>
        )}
      </div>
    ) : (
      schemaOrg
    );
  },
);

ClusterImage.displayName = 'ClusterImage';
