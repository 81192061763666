import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMatch } from 'date-fns';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { Typography } from 'common/components/Typography';
import InputDate from 'common/components/InputDate';

import { sendAccountData } from 'common/redux/account';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { signNames } from 'config/constants/signNames';

import s from './styles.css';

const MIN_YEAR = 1910;

interface IAccountForm {
  sign: SIGN;
  onClose: () => void;
}

export const AccountForm: React.FC<IAccountForm> = React.memo(
  ({ sign, onClose }) => {
    const dispatch = useDispatch();

    const currentAccount = useSelector((state: IAppState) => state.account);
    const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

    const [birthdayValue, setBirthdayValue] = useState<string>('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (currentAccount.birthday) {
        setBirthdayValue(currentAccount.birthday);
      }
    }, [currentAccount]);

    const { top100Prefix } = useTop100Context();

    const handleClose = () => {
      onClose();
    };

    const handleSave = async () => {
      if (birthdayValue && isMatch(birthdayValue, 'yyyy-MM-dd')) {
        setLoading(true);
        await dispatch(
          sendAccountData(
            { birthday: birthdayValue } as AccountType,
            true,
            true,
          ),
        );
        setLoading(false);
        onClose();
      }
    };

    const minDate = `${MIN_YEAR}-01-01`;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const maxDate = `${new Date().getFullYear() - 5}-01-01`;

    return (
      <div className={cn(s.root, isMobile && s.mobile)}>
        <Typography className={s.title} variant="h2">
          {`Вы не ${signNames.zodiac[sign]}?`}
        </Typography>
        <Typography variant="defaultMedium" className={s.infoText}>
          Мы выбрали ваш знак по дате рождения в&nbsp;профиле. Чтобы выбрать
          другой знак, измените дату рождения
        </Typography>
        <div className={s.field}>
          <Typography variant="defaultBold" className={s.label}>
            Дата рождения
          </Typography>
          <InputDate
            isMobile={isMobile}
            type="fill"
            name="date"
            placeholder="Дата рождения"
            value={birthdayValue}
            min={minDate}
            max={maxDate}
            onChange={(value: string) => setBirthdayValue(value)}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              `bubbles_account::${sign}::birthday`,
            )}
          />
        </div>
        <div>
          <Button
            className={s.saveButton}
            onClick={handleSave}
            isLoading={loading}
            {...getTop100Markup(
              isMobile,
              top100Prefix,
              `bubbles_account::${sign}::save_button`,
            )}
          >
            Сохранить
          </Button>
          {!isMobile && (
            <Button
              type="secondary"
              onClick={handleClose}
              {...getTop100Markup(
                false,
                top100Prefix,
                `bubbles_account::${sign}::close_button`,
              )}
            >
              Закрыть
            </Button>
          )}
        </div>
      </div>
    );
  },
);

AccountForm.displayName = 'AccountForm';
