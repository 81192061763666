import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';

import { YANDEX_METRICS } from 'config/constants/counters';

import { sendAccountData } from 'common/redux/account';

import { YandexEvent } from 'utils/counters/YandexEvent';

import { useDateByDeviceContext } from '../contexts/dateByDeviceContext';

const selectData = createSelector(
  [(state: IAppState) => state.account],
  (account) => ({
    account,
  }),
);

export const useDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { account } = useSelector(selectData);

  const dateSended = useRef(false);
  const { isButtonClicked, setIsButtonClicked } = useDateByDeviceContext();

  // Снимаем блокировку изменения данных
  useEffect(() => {
    if (isButtonClicked) {
      setIsButtonClicked(false);
    }

    if (dateSended.current && account && account.birthday) {
      history.push('/personal/');

      dateSended.current = false;
    }
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    checkDashboardDateAction: (form: ICardProps['form'], date: string) => {
      if (date && form === 'dashboard_personal') {
        dispatch(
          sendAccountData({
            ...account,
            birthday: date,
          }),
        );

        dateSended.current = true;

        // Отправляем только если ранее не было даты рождения
        if (!account.birthday) {
          new YandexEvent(YANDEX_METRICS.COMMON).send({
            type: 'reachGoal',
            data: 'lenta_birthday_input',
          });
        }
      }
    },
  };
};
