import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Button from '@rambler-components/button';

import Select from '@rambler-components/select';
import type { ChineseSignsType } from 'config/constants/routes';

import { API } from 'config/constants/api';
import fetch from 'common/utils/fetch';

import { GENDER } from 'config/constants/gender';
import type { SignType, SignTypeChinese } from 'config/constants/sign';

import { Icon } from 'common/components/Icon';
import InputDate from 'common/components/InputDate';

import { signNames } from 'config/constants/signNames';
import { signNameOptions } from 'common/utils/signNameValues';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100, Size } from './utils';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.account,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (account, isMobile) => ({
    account,
    isMobile,
  }),
);

interface ICardFormProps {
  form: Extract<ICardProps['form'], 'compatibility' | 'compatibility_chinese'>;
}

type GenderType = Exclude<GENDER, GENDER.unknown>;

function CompatibilityForm({ form }: ICardFormProps) {
  const { getState } = useStore<IAppState>();
  const { top100Prefix } = useTop100Context();
  const { account, isMobile } = useSelector(selectData);
  const history = useHistory();

  const [dateSignValue, setDateSignValue] = useState<
    Record<
      GenderType,
      {
        date: string;
        sign: ChineseSignsType | undefined;
      }
    >
  >({
    male: {
      date: '',
      sign: undefined,
    },
    female: {
      date: '',
      sign: undefined,
    },
  });

  const isChinese = form === 'compatibility_chinese';

  const getSignValue = (gender: GenderType) => {
    // TODO(HORO-0): беру 0-го партнера, надо продумать ситуацию, когда их будет несколько
    const partner = account.partners ? account.partners[0] : null;
    let signValue: {
      value: SignType | SignTypeChinese;
      name: string;
    } | null = null;

    if (!isChinese) {
      if (account.gender === gender && account.sign) {
        signValue = {
          value: account.sign,
          name: signNames.zodiac[account.sign],
        };
      } else if (partner && partner.gender === gender && partner.sign) {
        signValue = {
          value: partner.sign,
          name: signNames.zodiac[partner.sign],
        };
      }
    }

    return signValue;
  };

  const womanSignValue = getSignValue(GENDER.female);
  const manSignValue = getSignValue(GENDER.male);
  const [womanSelect, setWomanSelect] = useState(
    womanSignValue ? womanSignValue.value : null,
  );
  const [manSelect, setManSelect] = useState(
    manSignValue ? manSignValue.value : null,
  );

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsButtonClicked(false);
  }, [dateSignValue]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const compatibilityLink = useMemo(() => {
    if (
      (womanSelect && !manSelect) ||
      (dateSignValue.female.sign && !dateSignValue.male.sign)
    ) {
      const woman = isChinese ? dateSignValue.female.sign : womanSelect;

      return isChinese
        ? `/chinese/compatibility/${woman}/`
        : `/${woman}/sovmestimost-znakov-zodiaka/`;
    }

    if (
      (!womanSelect && manSelect) ||
      (dateSignValue.male.sign && !dateSignValue.female.sign)
    ) {
      const man = isChinese ? dateSignValue.male.sign : manSelect;

      return isChinese
        ? `/chinese/compatibility/${man}/`
        : `/${man}/sovmestimost-znakov-zodiaka/`;
    }

    if (
      (womanSelect && manSelect) ||
      (dateSignValue.male.sign && dateSignValue.female.sign)
    ) {
      const woman = isChinese ? dateSignValue.female.sign : womanSelect;
      const man = isChinese ? dateSignValue.male.sign : manSelect;

      return isChinese
        ? `/chinese/compatibility/muzhchina-${signNames.chineseTranslit[man as SignTypeChinese]}-zhenshchina-${signNames.chineseTranslit[woman as SignTypeChinese]}/`
        : `/sovmestimost-znakov-zodiaka/zhenshhina-${signNames.zodiacTranslit[woman as SignType]}-muzhchina-${signNames.zodiacTranslit[man as SignType]}/`;
    }

    return isChinese
      ? '/chinese/compatibility/all/'
      : '/sovmestimost-znakov-zodiaka/';
  }, [
    dateSignValue.female.sign,
    dateSignValue.male.sign,
    isChinese,
    manSelect,
    womanSelect,
  ]);

  const formSignSelect = useCallback(
    ({
      type,
      values,
      placeholder,
      size = 'normal',
      value,
      icon,
      onChange,
    }: {
      type: string;
      values: {
        label: string;
        value: string;
      }[];
      placeholder: string;
      size: Size;
      value?: any;
      icon?: any;
      onChange?: any;
    }) => (
      <div
        className={cn(s.selectContainer, isMobile && s.selectContainerMobile)}
      >
        <Select
          className={cn(s.select, getSizeStyle(isMobile, size))}
          value={value}
          placeholder={placeholder}
          type="border"
          options={values}
          onChange={onChange || null}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          zIndex={type === 'woman' ? 150 : 100}
          {...getTop100({
            isMobile,
            top100Prefix,
            form,
            tail: 'sign',
          })}
        />
        <span className={cn(s.selectIcon, s[`selectIcon-${type}`])}>
          {icon}
        </span>
      </div>
    ),
    [form, isMobile, top100Prefix],
  );

  const formInput = useCallback(
    (id: GenderType, top100Tail: string, icon?: JSX.Element) => (
      <InputDate
        className={cn(
          s.input,
          getSizeStyle(isMobile, 'normal'),
          isMobile && s.inputMobile,
        )}
        placeholder="Дата рождения"
        isMobile={isMobile}
        type="border"
        display="bright"
        icon={icon}
        value={dateSignValue[id].date}
        status={
          isButtonClicked && !dateSignValue[id].date ? 'error' : undefined
        }
        onChange={async (val: string) => {
          if (val) {
            const { sign } = await fetch({
              path: `${API.chinese.date}${val}/`,
              state: getState(),
            });

            setDateSignValue({
              ...dateSignValue,
              [id]: {
                date: val,
                sign,
              },
            });
          } else {
            setDateSignValue({
              ...dateSignValue,
              [id]: {
                date: '',
                sign: undefined,
              },
            });
          }
        }}
        min="1900-01-31"
        max="2039-01-23"
        {...getTop100({
          isMobile,
          top100Prefix,
          form,
          tail: top100Tail,
        })}
      />
    ),
    [dateSignValue, form, getState, isMobile, top100Prefix, isButtonClicked],
  );

  const compatibility = useMemo(() => {
    const womanSignSelect = formSignSelect({
      type: 'woman',
      values: signNameOptions(isChinese ? 'chinese' : 'zodiac'),
      placeholder: 'Знак женщины',
      size: 'normal',
      value: womanSelect,
      icon: (
        <span
          className={cn(s.iconContainer, womanSelect && s.iconContainerDone)}
        >
          <Icon id="woman" className={s.iconSign} />
        </span>
      ),
      onChange: (val: any) => {
        setWomanSelect(val);
      },
    });

    const manSignSelect = formSignSelect({
      type: 'man',
      values: signNameOptions(isChinese ? 'chinese' : 'zodiac'),
      placeholder: 'Знак мужчины',
      size: 'normal',
      value: manSelect,
      icon: (
        <span className={cn(s.iconContainer, manSelect && s.iconContainerDone)}>
          <Icon id="man" className={s.iconSign} />
        </span>
      ),
      onChange: (val: any) => {
        setManSelect(val);
      },
    });

    const chineseManSign = formInput(
      GENDER.male,
      'man_birth_date',
      <Icon id="man" className={s.iconSign} />,
    );

    const chineseWomanSign = formInput(
      GENDER.female,
      'woman_birth_date',
      <Icon id="woman" className={s.iconSign} />,
    );

    const zodiacButton = (
      <Link
        className={cn(
          s.link,
          isMobile && s.linkMobile,
          isChinese &&
            ((!dateSignValue.female.sign && dateSignValue.male.sign) ||
              (dateSignValue.female.sign && !dateSignValue.male.sign)) &&
            s.linkDisabled,
        )}
        to={compatibilityLink}
        {...getTop100({
          isMobile,
          top100Prefix,
          form,
          tail: `result_button::${compatibilityLink}`,
        })}
      >
        Показать
      </Link>
    );

    const chineseButton = (
      <Button
        className={cn(s.button, isMobile && s.buttonMobile)}
        onClick={() => {
          if (dateSignValue.female.sign && dateSignValue.male.sign) {
            history.push(compatibilityLink);
          }

          setIsButtonClicked(true);
        }}
        {...getTop100({
          isMobile,
          top100Prefix,
          form,
          tail: 'result_button',
        })}
      >
        Показать
      </Button>
    );

    return isChinese
      ? [chineseManSign, chineseWomanSign, chineseButton]
      : [womanSignSelect, manSignSelect, zodiacButton];
  }, [
    formSignSelect,
    isChinese,
    womanSelect,
    manSelect,
    formInput,
    isMobile,
    history,
    dateSignValue.female.sign,
    dateSignValue.male.sign,
    compatibilityLink,
    top100Prefix,
    form,
  ]);

  return (
    <>
      {compatibility.map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return <Fragment key={key}>{item}</Fragment>;
      })}
    </>
  );
}

export { CompatibilityForm };
