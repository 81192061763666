import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { setContentLikesLink } from 'common/redux/pages';
import { sentryReactSend } from 'utils/sentry/client';
import SubscribePopup from 'common/components/NewsLetterSubscribe/SubscribePopup';
import { isSubscribePopupShouldBeShown } from 'common/components/NewsLetterSubscribe/utils';

import { APP_VERSION } from 'config/constants';
import { SPECIAL_URLS } from 'config/constants/fortune';
import {
  SubscribeTypes,
  SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
  SUBSCRIBE_POPUP_GAME_NAMES,
  SUBSCRIBE_POPUP_TIME_BEFORE_SHOW,
  SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY,
} from 'config/constants/news-letter';
import { updateCounters } from 'utils/counters/updater';

import s from './styles.css';
import { Loader } from './Loader';
import { ShakespeareLoader } from './ShakespeareLoader';

const selectSpecialRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.currentParams.specialKey,
    (state: IAppState) => state.runtime.currentParams.specialCurrent,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.adminData.settings.shakespeare_enabled,
  ],
  // eslint-disable-next-line max-params
  (specialKey, specialCurrent, isMobile, shakespeareEnabled) => ({
    specialKey,
    specialCurrent,
    isMobile,
    shakespeareEnabled,
  }),
);

const selectBirthdate = createSelector(
  [(state: IAppState) => state.account.birthday],
  (birthdate) => ({
    birthdate,
  }),
);

interface IFortuneProps {
  link: string;
  name: string;
  className?: string;
}

function FortuneGame({ link, name, className }: IFortuneProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { specialKey, specialCurrent, isMobile, shakespeareEnabled } =
    useSelector(selectSpecialRuntimeData);
  const { birthdate } = useSelector(selectBirthdate);

  const [isResult, setIsResult] = useState(Boolean(specialKey));

  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  // для гадания по дате рождения пробрасываем дату для инпута
  const birthdateParam =
    name === 'birth-date-fortune' && birthdate
      ? `&birthdate=${birthdate.split('-').reverse().join('-')}`
      : '';
  const device = `device=${isMobile ? 'mobile' : 'tablet'}`;
  const specialUrls = SPECIAL_URLS[name];
  const specialParams = specialKey
    ? `&key=${specialKey}&${specialUrls.elem}=${specialCurrent || 1}&builtin=true`
    : `&builtin=true${birthdateParam}`;
  const hasShakespeareBranding =
    shakespeareEnabled && name === 'yes-no-fortune';
  const brandingParam = hasShakespeareBranding ? '&brand=shakespeare' : '';
  const src = `${link}?${device}${specialParams}${brandingParam}`;

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const handleMessage = (e: any) => {
      const { data } = e;
      if (!data) return;

      if (data.type === 'click' && data.isExternal) {
        window.location.href = data.url;
      } else if (data.type === 'click') {
        history.push(data.url);
      } else if (
        data.type === 'result' &&
        data.elem === 1 &&
        SUBSCRIBE_POPUP_GAME_NAMES.includes(name) &&
        isSubscribePopupShouldBeShown(SubscribeTypes.WEEKLY_TAROT)
      ) {
        timerId = setTimeout(() => {
          // eslint-disable-next-line sonarjs/no-nested-functions
          setIsSubscribePopupOpen(() => true);
          window.localStorage.setItem(
            SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
            `${Number(new Date())}`,
          );
        }, SUBSCRIBE_POPUP_TIME_BEFORE_SHOW);
      } else if (data.type === 'result') {
        let url = specialUrls.ts;
        const updated = window.location.search.indexOf('updated') !== -1;
        const special = data.key ? 'special=true' : '';
        const updatedFirstInParams = updated ? '?updated' : '';
        const updatedNotFirstInParams = updated ? '&updated' : '';
        const params = special
          ? `?${special}${updatedNotFirstInParams}`
          : `${updatedFirstInParams}`;

        if (data.key) {
          url = `${specialUrls.ts}${data.key}/${specialUrls.elem}-${data.elem}/`;
        }

        const urlWithParams = `${url}${params}`;

        window.history.replaceState(
          { url: urlWithParams },
          'title',
          urlWithParams,
        );
        dispatch(setContentLikesLink(url));
        updateCounters(isMobile ? APP_VERSION.MOBILE : APP_VERSION.DESKTOP);
        setIsResult(Boolean(data.key));
      } else if (data.type === 'error') {
        sentryReactSend(data.error);
        console.error(`${new Date()} ${data.error}`);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);

      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubscribePopupClose = () => {
    setIsSubscribePopupOpen(false);

    try {
      window.localStorage.setItem(
        `rh_${SubscribeTypes.WEEKLY_TAROT}${SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY}`,
        `${Number(new Date())}`,
      );
    } catch (error) {
      sentryReactSend(error);

      console.error(`${new Date()} ${error}`);
    }
  };

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        isResult && isMobile && s.rootMobileResult,
        s[`root-${name}`],
        className,
        hasShakespeareBranding && s.shakespeare,
      )}
    >
      {hasShakespeareBranding ? (
        <ShakespeareLoader isMobile={isMobile} />
      ) : (
        <Loader fortune={name} />
      )}
      <iframe
        className={cn(s.iframe, s.loader)}
        src={src}
        width="100%"
        height="100%"
        title="game"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      />
      {SUBSCRIBE_POPUP_GAME_NAMES.includes(name) && (
        <SubscribePopup
          isOpen={isSubscribePopupOpen}
          onClose={handleSubscribePopupClose}
          type={SubscribeTypes.WEEKLY_TAROT}
        />
      )}
    </div>
  );
}

FortuneGame.defaultProps = {
  className: '',
};

export { FortuneGame };
