import { getTop100Markup } from 'common/utils/getTop100Markup';
import s from './styles.css';

export type Size = 'normal' | 'small' | 'big' | 'full';

export const getSizeStyle = (isMobile: boolean, size: Size) => {
  if (isMobile) {
    return '';
  }

  switch (size) {
    case 'small':
      return s.sizeSmall;
    case 'normal':
      return s.sizeNormal;
    case 'big':
      return s.sizeBig;
    case 'full':
      return s.sizeFull;
    default:
      return s.sizeNormal;
  }
};

export const getTop100 = ({
  isMobile,
  top100Prefix,
  form,
  tail,
}: {
  isMobile: boolean;
  top100Prefix: string;
  form: ICardProps['form'];
  tail: string;
}) => getTop100Markup(isMobile, top100Prefix, `form::${form}::${tail}`);
