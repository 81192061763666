import { useState, useEffect } from 'react';

export const STORAGE_SIGN_WIDGET_HIDE_KEY = 'rh_bubble_widget_hide';

export const useHideWidget = (
  signChecked: boolean,
  selectSign: IBubble['sign'],
) => {
  const [hideWidget, setHideWidget] = useState(true);

  const onHide = () => {
    if (hideWidget) return;

    try {
      window.localStorage.setItem(STORAGE_SIGN_WIDGET_HIDE_KEY, '1');
      setHideWidget(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    // была проверка выбранного знака
    if (signChecked) {
      try {
        // проверка скрытия виджета выбора знака пользователем
        const savedHideStatus =
          window.localStorage.getItem(STORAGE_SIGN_WIDGET_HIDE_KEY) || '';

        if (savedHideStatus) {
          setHideWidget(true);
        } else {
          setHideWidget(!!selectSign);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, [signChecked, selectSign]);

  return { hideWidget, onHide };
};
