/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { signNames } from 'config/constants/signNames';
import { declOfNum } from 'utils/declOfNum';

import s from './styles.css';

const selectData = () =>
  createSelector(
    [
      (state: IAppState, codename: string) => state.likes[codename],
      (state: IAppState) => state.account,
      (state: IAppState) => state.runtime.isMobile,
    ],
    (likesData, account, isMobile) => ({
      likesData,
      account,
      isMobile,
    }),
  );

interface ICardLikesProps {
  likes?: ICardProps['likes'];
  isFullImageCard?: boolean;
  className?: string;
  isFooter?: boolean;
  visibleSignsCount?: number;
}

function Likes({
  likes,
  isFullImageCard,
  isFooter,
  className,
  visibleSignsCount,
}: ICardLikesProps) {
  const codename = likes ? likes.codename : '';
  const likesOffset = isFullImageCard ? 5 : 4;
  const iconSize = isFullImageCard ? 25 : 20;

  /**
   * https://react-redux.js.org/api/hooks => However, when the selector is used
   * in multiple component instances and depends on the component's props,
   * you need to ensure that each component instance gets its own selector instance
   */
  const memoSelectData = useMemo(selectData, []);
  const { account, likesData, isMobile } = useSelector((state: IAppState) =>
    memoSelectData(state, codename),
  );

  const userSign = account.sign;

  if (!likesData || likesData.total === 0 || !likesData.bysign) return null;

  const data =
    likesData.total > 0
      ? Object.keys(likesData.bysign).filter(
          (key: keyof typeof likesData.bysign) => likesData.bysign[key] > 0,
        )
      : [];

  let text = `Оценили ${likesData.total}`;

  if (userSign && likesData.bysign[userSign] > 0) {
    const userSignLikes = likesData.bysign[userSign];
    const otherLikes =
      userSignLikes < likesData.total ? likesData.total - userSignLikes : 0;
    const signName = declOfNum(userSignLikes, [
      signNames.zodiac[userSign],
      signNames.zodialGenitive[userSign],
      signNames.zodiacAccusative[userSign],
    ]);

    const otherlikesTex = otherLikes > 0 ? ` и ещё ${otherLikes}` : '';

    text = `Оценили ${userSignLikes} ${signName.toLowerCase()}${otherlikesTex}`;
  }

  let likesVisibleData = data;

  if (visibleSignsCount) {
    likesVisibleData = likesVisibleData.slice(0, visibleSignsCount);
  }

  if (isFooter) {
    likesVisibleData = likesVisibleData.slice(0, 3);
  }

  const dataLength = likesVisibleData.length;

  return (
    <div
      className={cn(
        s.likes,
        isMobile ? s.likesMobile : s.likesDesktop,
        isFullImageCard && s.likesFullImageCard,
        isFooter && s.likesCluster,
        className,
      )}
    >
      <div
        className={s.icons}
        style={{
          // iconSize - ширина иконки
          // "-1" т.к. смещать 1ю не нужно
          // likesOffset - на сколько смещаем влево
          width: dataLength * iconSize - (dataLength - 1) * likesOffset,
        }}
      >
        {likesVisibleData.map((key: string, index) => {
          const signLikes = likesData.bysign[key];

          if (signLikes === 0) return null;

          return (
            <Icon
              id={key}
              key={`${codename}-${key}`}
              className={cn(
                s.icon,
                s[key],
                isFullImageCard && s.iconFullImageCard,
              )}
              style={{
                zIndex: dataLength + index,
                left: -likesOffset * index,
              }}
            />
          );
        })}
      </div>
      <Typography
        variant="defaultMedium"
        className={cn(
          s.text,
          isFullImageCard && s.textWhite,
          isMobile && s.textMobile,
        )}
      >
        {text}
      </Typography>
    </div>
  );
}

Likes.defaultProps = {
  likes: {
    codename: '',
    hash: '',
  },
  isFullImageCard: false,
  className: '',
  isFooter: false,
  visibleSignsCount: 0,
};

export { Likes };
