import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { initShareButtons } from 'utils/share/initShareButtons';
import { sentryReactSend } from 'utils/sentry/client';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import {
  SYMBOLS_COUNT,
  STEPS,
  LOADING_TIME,
  COFFEE_SHARE_TEXT,
  ACTIVATING_TIME,
} from 'config/constants/fortune';
import {
  SubscribeTypes,
  SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
  SUBSCRIBE_POPUP_TIME_BEFORE_SHOW,
  SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY,
} from 'config/constants/news-letter';

import {
  getCoffeGameSnippet,
  setCoffeeGameSnippet,
} from 'common/redux/fortune';

import { CoffeeLogic } from 'common/utils/coffeeLogic';
import { Icon } from 'common/components/Icon';
import SubscribePopup from 'common/components/NewsLetterSubscribe/SubscribePopup';
import { isSubscribePopupShouldBeShown } from 'common/components/NewsLetterSubscribe/utils';

import { CoffeeSteam } from '../CoffeeSteam';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.origin,
    (state: IAppState) => state.fortune.coffeeGameSnippet.snippet,
    (state: IAppState) => state.fortune.coffeeGameSnippet.isSnippetReady,
    (state: IAppState) => state.fortune.coffeeGameSnippet.isSnippetFailed,
  ],
  (
    origin,
    snippet,
    isSnippetReady,
    isSnippetFailed,
    // eslint-disable-next-line max-params
  ) => ({
    origin,
    snippet,
    isSnippetReady,
    isSnippetFailed,
  }),
);

interface IFortuneCoffeeProps {
  coffeeGame: ICoffeeGame[];
  className?: string;
}

type TimeType = 'past' | 'present' | 'future';
type SubstrateType = 'circle' | 'side';
type GameStepsType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type CurrentSymbolsNodesType = Record<string, HTMLDivElement>;

function FortuneCoffeeMobile({ coffeeGame, className }: IFortuneCoffeeProps) {
  const { top100Prefix } = useTop100Context();
  const { origin, snippet, isSnippetReady, isSnippetFailed } =
    useSelector(selectData);
  const dispatch = useDispatch();

  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  const coffeeLogic = useRef(new CoffeeLogic('mobile', coffeeGame));

  const activeSymbol = useRef<number>();
  const currentSymbolsNodes = useRef<CurrentSymbolsNodesType>({});
  const timeoutId = useRef<NodeJS.Timeout>();
  const was8StepRendered = useRef(false);
  const prevStep = useRef(STEPS.MAIN);
  const gameMounted = useRef(false);
  const coffeeGameNode = useRef<HTMLDivElement>(null);
  const coffeeGameOffsetTop = useRef(0);

  const ramblerLikesRef = useRef(null);
  const [ramblerLikesInited, setRamblerLikesInited] = useState(false);

  const [step, setStep] = useState<GameStepsType>(STEPS.MAIN);

  const getTop100 = (tail: string) => getTop100Markup(true, top100Prefix, tail);

  const scrollToGame = () => {
    window.scrollTo({
      top: coffeeGameOffsetTop.current,
      behavior: 'smooth',
    });
  };

  const updateStep = () => {
    const newStep = coffeeLogic.current.getCurrentStep();

    prevStep.current = step;
    setStep(newStep);
    scrollToGame();
  };

  const nextGameStep = () => {
    coffeeLogic.current.nextStep();
    updateStep();
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (gameMounted.current) {
      if (prevStep.current === STEPS.MAIN) {
        setTimeout(nextGameStep, LOADING_TIME);
      } else if (prevStep.current === STEPS.FUTURE_DESCRIPTION) {
        const result = coffeeLogic.current.getResult();

        dispatch(
          getCoffeGameSnippet(result.past, result.present, result.future),
        );

        if (isSubscribePopupShouldBeShown(SubscribeTypes.WEEKLY_TAROT)) {
          timerId = setTimeout(() => {
            setIsSubscribePopupOpen(() => true);
            window.localStorage.setItem(
              SUBSCRIBE_POPUP_ANY_TIME_STORAGE_KEY,
              `${Number(new Date())}`,
            );
          }, SUBSCRIBE_POPUP_TIME_BEFORE_SHOW);
        }
      }
    }

    if (
      !gameMounted.current &&
      coffeeGameNode.current &&
      coffeeGameNode.current.parentElement
    ) {
      coffeeGameOffsetTop.current =
        coffeeGameNode.current.parentElement.offsetTop +
        coffeeGameNode.current.offsetTop;
      gameMounted.current = true;
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    dispatch(setCoffeeGameSnippet());
    coffeeLogic.current.init('mobile', coffeeGame);
    updateStep();
    was8StepRendered.current = false;
  };

  const setGameStep = (gameStep: GameStepsType) => {
    coffeeLogic.current.setStep(gameStep);
    updateStep();
  };

  const getPrevStep = (time: TimeType) => coffeeLogic.current.getPrevStep(time);
  const getNextStep = (time: TimeType) => coffeeLogic.current.getNextStep(time);

  const selectSymbol = (time: TimeType, symbol: number) => {
    activeSymbol.current = symbol;

    Object.keys(currentSymbolsNodes.current).forEach((key) => {
      const el = currentSymbolsNodes.current[key];

      if (Number(key) === activeSymbol.current) {
        if (el) {
          el.classList.add(s.stepSymbolActive);
        }
      } else if (el) {
        el.classList.remove(s.stepSymbolActive);
      }
    });

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      activeSymbol.current = undefined;
      currentSymbolsNodes.current = {};
      coffeeLogic.current.selectSymbol(time, symbol);
      nextGameStep();
    }, ACTIVATING_TIME);
  };

  const renderRandomSubstrate = (
    time: TimeType,
    type: SubstrateType,
    randomSubstrateClassName: string,
  ) => {
    const { randomSubstrate } = coffeeLogic.current.getRandomSubstrate(
      time,
      type,
    );

    return (
      <Icon
        id={`coffee-${type}-${randomSubstrate}`}
        key={`${time}-${type}-${randomSubstrate}`}
        className={cn(
          s[`image${type}${randomSubstrate}`],
          randomSubstrateClassName,
        )}
      />
    );
  };

  const renderRandomSymbol = ({
    time,
    type,
    index,
    randomSymbolClassName,
  }: {
    time: TimeType;
    type: 'symbols';
    index: number;
    randomSymbolClassName: string;
  }) => {
    const { randomSymbol, style } = coffeeLogic.current.getRandomSymbol(
      time,
      type,
      index,
    );
    const symbolCode = coffeeLogic.current.getCoffeeSymbol(randomSymbol);

    return (
      /* eslint-disable-next-line */
      <div
        dangerouslySetInnerHTML={{ __html: symbolCode }}
        key={`${time}-${type}-${randomSymbol}`}
        className={randomSymbolClassName}
        style={{ ...style }}
        onClick={() => selectSymbol(time, randomSymbol)}
        ref={(node) => {
          if (node) {
            currentSymbolsNodes.current[randomSymbol] = node;
          }
        }}
      />
    );
  };

  const renderRandomSymbols = (time: TimeType, randomSymbolClassName: string) =>
    [...Array(SYMBOLS_COUNT)].map((item, index) =>
      renderRandomSymbol({
        time,
        type: 'symbols',
        index,
        randomSymbolClassName,
      }),
    );

  const renderIcon = (time: TimeType, iconClassName: string) => {
    const iconCode = coffeeLogic.current.getIcon(time);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: iconCode }}
        className={iconClassName}
      />
    );
  };

  const renderNavigation = (time: TimeType) => (
    <div className={s.stepNavigation}>
      {time !== 'past' ? (
        <>
          {/* eslint-disable-next-line */}
            <div
            className={s.stepNavigationText}
            onClick={() => setGameStep(getPrevStep(time))}
            {...getTop100(
              `mini_game::screen_${time}::before_${coffeeLogic.current.getPrevTime(time)}`,
            )}
          >
            <Icon
              id="coffee-arrow"
              className={cn(s.imageArrow, s.stepArrowLeft)}
            />
            {time === 'present' ? 'Прошлое' : 'Настоящее'}
          </div>
        </>
      ) : (
        <div />
      )}
      {/* eslint-disable-next-line */}
      <div
        className={s.stepNavigationText}
        onClick={() => setGameStep(getNextStep(time))}
        {...getTop100(
          `mini_game::screen_${time}::next_${coffeeLogic.current.getNextTime(time)}`,
        )}
      >
        {time === 'past' && 'Настоящее'}
        {time === 'present' && 'Будущее'}
        {time === 'future' && 'Поделиться'}
        <Icon
          id="coffee-arrow"
          className={cn(s.imageArrow, s.stepArrowRight)}
        />
      </div>
    </div>
  );

  const renderStars = (gameStep: GameStepsType) => (
    <>
      {[...Array(coffeeLogic.current.getStars(gameStep, 'big'))].map(
        (item, index) => (
          <Icon
            id="coffee-star-big-mobile"
            className={cn(
              s.stepStar,
              s.imageStarBig,
              s[`step${gameStep}StarBig${index}`],
            )}
            key={`big-${gameStep}-${index}`}
          />
        ),
      )}
      {[...Array(coffeeLogic.current.getStars(gameStep, 'small'))].map(
        (item, index) => (
          <Icon
            id="coffee-star-small"
            className={cn(
              s.stepStar,
              s.imageStarSmall,
              s[`step${gameStep}StarSmall${index}`],
            )}
            key={`small-${gameStep}-${index}`}
          />
        ),
      )}
    </>
  );

  useEffect(() => {
    if (
      !ramblerLikesInited &&
      step === STEPS.RESULT &&
      snippet &&
      ramblerLikesRef.current
    ) {
      initShareButtons({
        node: ramblerLikesRef.current,
        cardTitle: COFFEE_SHARE_TEXT,
        cardDescription: COFFEE_SHARE_TEXT,
        cardImage: snippet || `${origin}/ts-special/coffee/sharing.png`,
        isMobile: true,
        extraButtons: ['telegram'],
      });

      setRamblerLikesInited(true);
    }
  }, [step, snippet]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSharing = () => {
    if (!isSnippetFailed && !isSnippetReady) {
      return (
        <Icon
          id="coffee-loading-mobile"
          className={cn(s.imageLoading, s.step8Loading)}
        />
      );
    }

    return (
      <div className={s.step8Sharing}>
        <div className={cn('rambler-share', s.share)} ref={ramblerLikesRef} />
      </div>
    );
  };

  const renderText = (time: TimeType) => (
    <div className={s.stepTextContainer}>
      <div className={s.stepTitle}>{coffeeLogic.current.getTitle(time)}</div>
      <div className={s.stepSubtitle}>
        {coffeeLogic.current.getSubtitle(time)}
      </div>
      {coffeeLogic.current.getText(time)}
    </div>
  );

  const renderResult = (gameStep: GameStepsType, time: TimeType) => (
    <>
      <div className={s[`step${gameStep}InfoTitle`]}>
        {coffeeLogic.current.getTitle(time)}
      </div>
      <div className={s[`step${gameStep}InfoSubtitle`]}>
        {coffeeLogic.current.getSubtitle(time)}
      </div>
    </>
  );

  const renderStep0 = () => (
    <div className={cn(s.step, s.step0)}>
      <Icon
        id="coffee-header-mobile"
        className={cn(s.imageHeader, s.step0Header)}
      />
      <div className={s.step0Text}>
        Гадание на кофейной гуще — один
        <br />
        из самых доступных и вкусных
        <br />
        способов узнать свою судьбу.
        <br />
        Мы приготовили эту чашку для вас.
        <br />
        Переверните её скорее!
      </div>
      {/* eslint-disable-next-line */}
      <div
        className={s.step0Button}
        onClick={nextGameStep}
        {...getTop100('mini_game::screen_start::start')}
      />
      <CoffeeSteam className={s.step0Steam} width={360} height={258} />
      <div className={s.step0Cup} />
      {renderStars(STEPS.MAIN)}
    </div>
  );

  const renderStep1 = () => (
    <div className={cn(s.step, s.step1)}>
      <Icon
        id="coffee-loading-mobile"
        className={cn(s.imageLoading, s.step1Loading)}
      />
      <div className={s.step1Text}>Ваша судьба определяется</div>
      <div className={s.step1Cup} />
      {renderStars(STEPS.LOADING)}
    </div>
  );

  const renderStep2 = () => (
    <div className={cn(s.step, s.step2)}>
      <div className={s.stepText}>
        Сначала разберем ваше прошлое. Найдите символ в гуще
      </div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step2Plate}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('past', 'circle', s.step2Circle)}
          {renderRandomSymbols('past', s.step2Symbol)}
        </div>
      </div>
      {renderStars(STEPS.PAST)}
    </div>
  );

  const renderStep3 = () => (
    <div className={cn(s.step, s.step3)}>
      {renderIcon('past', s.stepIcon)}
      {renderText('past')}
      {renderNavigation('past')}
      {renderStars(STEPS.PAST_DESCRIPTION)}
    </div>
  );

  const renderStep4 = () => (
    <div className={cn(s.step, s.step4)}>
      <div className={s.stepText}>Теперь посмотрим на настоящее</div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step4Cup}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('present', 'circle', s.step4Circle)}
          {renderRandomSymbols('present', s.step4Symbol)}
        </div>
      </div>
      {renderStars(STEPS.PRESENT)}
    </div>
  );

  const renderStep5 = () => (
    <div className={cn(s.step, s.step5)}>
      {renderIcon('present', s.stepIcon)}
      {renderText('present')}
      {renderNavigation('present')}
      {renderStars(STEPS.PRESENT_DESCRIPTION)}
    </div>
  );

  const renderStep6 = () => (
    <div className={cn(s.step, s.step6)}>
      <div className={s.stepText}>
        Время заглянуть
        <br />в будущее
      </div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step6Cup}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('future', 'side', s.step6Side)}
          {renderRandomSymbols('future', s.step6Symbol)}
        </div>
      </div>
      {renderStars(STEPS.FUTURE)}
    </div>
  );

  const renderStep7 = () => (
    <div className={cn(s.step, s.step7)}>
      {renderIcon('future', s.stepIcon)}
      {renderText('future')}
      {renderNavigation('future')}
      {renderStars(STEPS.FUTURE_DESCRIPTION)}
    </div>
  );

  const renderStep8 = () => (
    <div className={cn(s.step, s.step8)}>
      <div className={s.step8Title}>
        Поделитесь магией кофе
        <br />с друзьями!
      </div>
      {renderSharing()}
      <div className={s.step8Info}>
        {['past', 'present', 'future'].map((time: TimeType) => (
          <div className={s.step8InfoPart} key={time}>
            {renderIcon(time, s.step8Icon)}
            {renderResult(STEPS.RESULT, time)}
          </div>
        ))}
      </div>
      {/* eslint-disable-next-line */}
      <div
        className={s.step8Button}
        onClick={init}
        {...getTop100('mini_game::screen_results::to_start')}
      />
      {renderStars(STEPS.RESULT)}
    </div>
  );

  const renderGameStep = {
    0: renderStep0,
    1: renderStep1,
    2: renderStep2,
    3: renderStep3,
    4: renderStep4,
    5: renderStep5,
    6: renderStep6,
    7: renderStep7,
    8: renderStep8,
  };

  if (step === STEPS.RESULT && !was8StepRendered.current) {
    was8StepRendered.current = true;
  }

  const handleSubscribePopupClose = () => {
    setIsSubscribePopupOpen(false);

    try {
      window.localStorage.setItem(
        `rh_${SubscribeTypes.WEEKLY_TAROT}${SUBSCRIBE_POPUP_TYPE_TIME_STORAGE_KEY}`,
        `${Number(new Date())}`,
      );
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

  return (
    <div className={cn(s.root, className)} key={step} ref={coffeeGameNode}>
      {renderGameStep[step]()}
      <SubscribePopup
        isOpen={isSubscribePopupOpen}
        onClose={handleSubscribePopupClose}
        type={SubscribeTypes.WEEKLY_TAROT}
      />
    </div>
  );
}

FortuneCoffeeMobile.defaultProps = {
  className: '',
};

export { FortuneCoffeeMobile };
